<template>
<!-- Sidebar -->
		<div class="sidebar" id="sidebar">
			<div class="sidebar-logo">
				<router-link to="/admin/index">
					<img src="../../assets/admin_img/logo-icon.png" class="img-fluid" alt="">
				</router-link>
			</div>
			<div class="sidebar-inner">
			<perfect-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle"> 
				<div id="sidebar-menu" class="sidebar-menu">
					<ul>
						<li>
							<router-link to="/admin/index"><i class="fas fa-columns"></i> <span>Dashboard</span></router-link>
						</li>
						<li>
							<router-link to="/admin/categories" :class="{'active': currentPath === 'add-category' || currentPath === 'edit-category' }"><i class="fas fa-layer-group"></i> <span>Categories</span></router-link>
						</li>
						<li>
							<router-link to="/admin/subcategories" :class="{'active': currentPath === 'add-subcategory' || currentPath === 'edit-subcategory' }"><i class="fab fa-buffer"></i> <span>Sub Categories</span></router-link>
						</li>
						<li>
							<router-link to="/admin/service-list" :class="{'active': currentPath === 'admin-service-details' }"><i class="fas fa-bullhorn"></i> <span> Services</span></router-link>
						</li>
						<li>
							<router-link to="/admin/total-report" :class="{'active': currentPath === 'inprogress-report' || currentPath === 'complete-report' || currentPath === 'pending-report' || currentPath === 'reject-report' || currentPath === 'cancel-report' }"><i class="far fa-calendar-check"></i> <span> Booking List</span></router-link>
						</li>
						<li>
							<router-link to="/admin/payment_list"><i class="fas fa-hashtag"></i> <span>Payments</span></router-link>
						</li>
						<li>
							<router-link to="/admin/ratingstype" :class="{'active': currentPath === 'add-ratingstype' || currentPath === 'edit-ratingstype' }"><i class="fas fa-star-half-alt"></i> <span>Rating Type</span></router-link>
						</li>
						<li>
							<router-link to="/admin/review-reports"><i class="fas fa-star"></i> <span>Ratings</span></router-link>
						</li>
						<li>
							<router-link to="/admin/subscriptions" :class="{'active': currentPath === 'add-subscription' || currentPath === 'edit-subscription' }"><i class="far fa-calendar-alt"></i> <span>Subscriptions</span></router-link>
						</li>
						<li>
							<router-link to="/admin/wallet" :class="{'active': currentPath === 'wallet-history' }"><i class="fas fa-wallet"></i> <span> Wallet</span></router-link>
						</li>
						<li>
							<router-link to="/admin/service-providers"><i class="fas fa-user-tie"></i> <span> Service Providers</span></router-link>
						</li>
						<li>
							<router-link to="/admin/users"><i class="fas fa-user"></i> <span>Users</span></router-link>
						</li>
						<li class="submenu">
							<a href="javascript:void(0);"><i class="fas fa-cog"></i> <span> Settings</span>
								<span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
							</a>
							<ul>
								<li>
									<router-link to="/admin/settings"> <span> General Settings</span></router-link>
								</li> 
								<li>
									<router-link to="/admin/system-settings"> <span>System Settings</span></router-link>
								</li>
								<li>
									<router-link to="/admin/localization"> <span>Localization </span></router-link>
								</li>	
								<li>
									<router-link to="/admin/social-settings"> <span> Social Settings</span></router-link>
								</li>
								<li>
									<router-link to="/admin/email-settings"> <span> Email Settings</span></router-link>
								</li>
								<li>
									<router-link to="/admin/payment-settings" :class="{'active': currentPath === 'cod' || currentPath === 'paypal'  || currentPath === 'paystack'  || currentPath === 'razorpay'}"> <span>Payment Settings</span></router-link>
								</li>
								<li>
									<router-link to="/admin/seo-settings"> <span>SEO Settings</span></router-link>
								</li>
								<li>
									<router-link to="/admin/others-settings"> <span> Others</span></router-link>
								</li>
								<li>
									<router-link to="/admin/theme-settings"> <span> Theme Settings</span></router-link>
								</li>
								<li>
									<router-link to="/admin/sms-setting" :class="{'active': currentPath === 'factor' || currentPath === 'sms-settings'  || currentPath === 'twilio' }"> <span> SMS Gateway</span></router-link>
								</li>
								<li>
									<router-link to="/admin/language-settings" :class="{'active': currentPath === 'add-language' || currentPath === 'edit-language' }"> <span> Language</span></router-link>
								</li>
							</ul>
						</li>
						<li class="submenu">
							<a href="javascript:void(0);"><i class="fas fa-cog"></i> <span> Frontend Settings</span>
								<span class="menu-arrow"><i class="fas fa-angle-right"></i></span>
							</a>
							<ul>
								<li>
									<router-link to="/admin/front-settings"> <span> Header Settings</span></router-link>
								</li> 
								<li>
									<router-link to="/admin/footer-settings"> <span>Footer Settings</span></router-link>
								</li>
								<li>
							  <router-link to="/admin/pages" :class="{'active': currentPath === 'add-faq' || currentPath === 'home-page'  || currentPath === 'privacy-policy' }"> <span>Pages</span></router-link>
						    </li>
							</ul>
						</li>
					</ul>
				</div>
			</perfect-scrollbar>
			</div>
		</div>
		<!-- /Sidebar -->
</template>
<script>
import {PerfectScrollbar}  from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
export default {
	computed: {
        currentPath() {
            return this.$route.name;
        }
    },
components: {
PerfectScrollbar ,
},
mounted() {
	
  },
data() {
	return {
	  settings: {
suppressScrollX: true,
},
	  activeClass: 'active',
	};
			//  isactive : true
  },
  methods: {
  scrollHanle(evt) {
	console.log(evt)
  }
},
}
</script>
<style>
  .scroll-area {
	position: relative;
	margin: auto;
	height: calc(100vh - 60px);
	background-color: transparent !important;
}
.sidebar-menu ul li a.active {
	background-color: rgb(0, 175, 245);
    color: #fff;
}
</style>