<template>
	<div>
		<div class="main-wrapper">
		<layout-headerbookings></layout-headerbookings>
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4">
                        <div class="mb-4">
                            <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                                <img alt="profile image" src="../../assets/img/customer/user-01.jpg" class="avatar-lg rounded-circle">
                                <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                                    <h6 class="mb-0">Jeffrey Akridge</h6>
                                    <p class="text-muted mb-0">Member Since Apr 2020</p>
                                </div>
                            </div>
                        </div>
                        <div class="widget settings-menu">
                            <ul role="tablist" class="nav nav-tabs">
                                <li class="nav-item current">
                                    <router-link to="/user-dashboard" class="nav-link">
                                        <i class="fas fa-chart-line"></i> <span>Dashboard</span>
                                    </router-link>
                                </li>
                                <li class="nav-item current">
                                    <router-link to="/favourites" class="nav-link active">
                                        <i class="fas fa-heart"></i> <span>Favourites</span>
                                    </router-link>
                                </li>
                                <li class="nav-item current">
                                    <router-link to="/user-bookings" class="nav-link">
                                        <i class="far fa-calendar-check"></i> <span>My Bookings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-settings" class="nav-link">
                                        <i class="far fa-user"></i> <span>Profile Settings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-wallet" class="nav-link">
                                        <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-reviews" class="nav-link">
                                        <i class="far fa-star"></i> <span>Reviews</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-payment" class="nav-link">
                                        <i class="fas fa-hashtag"></i> <span>Payment</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <h4 class="widget-title">Favourites</h4>
                        <div class="row">
                        
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-01.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                                <span class="service-price ms-1">$25</span>
                                            </div>
                                            <div class="cate-list">
                                                <router-link class="bg-yellow" to="/service-details">Glass</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Toughened Glass Fitting Services</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <span class="d-inline-block average-rating">(4.3)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-03.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                                <span class="service-price ms-1">$45</span>
                                            </div>
                                            <div class="cate-list">
                                                <router-link class="bg-yellow" to="/service-details">Electrical</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Electric Panel Repairing Service</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4.5)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-02.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>    
                                                <span class="service-price ms-1">$50</span>
                                            </div>
                                            <div class="cate-list"> <router-link class="bg-yellow" to="/service-details">Automobile</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Car Repair Services</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <span class="d-inline-block average-rating">(5)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-04.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                                <span class="service-price ms-1">$14</span>
                                            </div>
                                            <div class="cate-list">
                                                <router-link class="bg-yellow" to="/service-details">Car Wash</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Steam Car Wash</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(0)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-05.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                                <span class="service-price ms-1">$100</span>
                                            </div>
                                            <div class="cate-list">
                                                <router-link class="bg-yellow" to="/service-details">Cleaning</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">House Cleaning Services</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(0)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-06.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>    
                                                <span class="service-price ms-1">$80</span>
                                            </div>
                                            <div class="cate-list"> <router-link class="bg-yellow" to="/service-details">Computer</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Computer & Server AMC Service</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(0)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-07.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                                <span class="service-price ms-1">$5</span>
                                            </div>
                                            <div class="cate-list">
                                                <router-link class="bg-yellow" to="/service-details">Interior</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Interior Designing</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-08.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                                <span class="service-price ms-1">$75</span>
                                            </div>
                                            <div class="cate-list">
                                                <router-link class="bg-yellow" to="/service-details">Construction</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Building Construction Services</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex">
                                <div class="service-widget flex-fill">
                                    <div class="service-img">
                                        <router-link to="/service-details">
                                            <img class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-09.jpg">
                                        </router-link>
                                        <div class="fav-btn">
                                            <a href="javascript:void(0)" class="fav-icon">
                                                <i class="fas fa-heart"></i>
                                            </a>
                                        </div>
                                        <div class="item-info">
                                            <div class="service-user">
                                                <a href="javascript:void(0);">
                                                    <img src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>    <span class="service-price ms-1">$500</span>
                                            </div>
                                            <div class="cate-list"> <router-link class="bg-yellow" to="/service-details">Painting</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="service-content">
                                        <h3 class="title">
                                            <router-link to="/service-details">Commercial Painting Services</router-link>
                                        </h3>
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(3)</span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            
                            <!-- Pagination Links --> 
                            <div class="pagination">
                                <ul>
                                    <li class="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">4</a>
                                    </li>
                                    <li class="arrow">
                                        <a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
		<layout-footer></layout-footer>
		</diV>
	</div>
</template>

<script>
	export default {
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });
    $('.fav-btn .fav-icon').on('click', function () {
        $(this).toggleClass('favourite');
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>