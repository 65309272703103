<template>
	<div>
		<div class="main-wrapper">
            <div class="content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="section-header text-center">
                                <h2>Editer un colis</h2>
                            </div>
                            <form 
                                @submit.prevent=""
                                novalidate="true"
                            >
                                <div class="wrapper-stepper">
                                    <div class="stepper">
                                        <div class="stepper-progress">
                                            <div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
                                        </div>

                                        <div class="stepper-item" v-for="item in steps" :key="item.id" :class="{ 'current': step == item.id, 'success': step > item.id }">
                                            <div class="stepper-item-counter">
                                                <img class="icon-success" src="./../../assets/img/helpers/icons8-ok-144.svg" alt="">
                                                <span class="number">
                                                    {{ item.id }}
                                                </span>
                                            </div>
                                            <span class="stepper-item-title">
                                                {{ item.text }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="stepper-content"> 
                                        <div class="stepper-pane" v-if="step == 1">
                                            <h3 class="text-white">Trajet d'expédition</h3>
                                            <div class="row">
                                                <div class="search-input2" style="width: 50%">
                                                    <span class="line" style="color:red" v-if="formControl.depart">requis</span>
                                                    <i class="fas fa-location-arrow bficon" :style="[!formControl.depart ? 'margin-top: -5px' : '']"></i>
                                                    <div class="form-group mb-3">
                                                        <input ref="origin" type="text" :value="colisForm.depart" class="form-control" placeholder="Départ" @change="formControl.depart = false" id="depart" name="depart">
                                                    </div>
                                                </div>
                                                <div class="search-input2" style="width: 50%">
                                                    <span class="line" style="color:red" v-if="formControl.arrivee">requis</span>
                                                    <i class="fas fa-map-marker bficon" :style="[!formControl.arrivee ? 'margin-top: -5px' : '']"></i>
                                                    <div class="form-group mb-3">
                                                        <input ref="destination" type="text" :value="colisForm.arrivee" class="form-control" placeholder="Arrivée" @change="formControl.arrivee = false" id="arrivee" name="arrivee"> 
                                                        <a class="current-loc-icon current_location" :style="[!formControl.arrivee ? 'margin-top: -5px' : '']" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="stepper-content"> 
                                        <div class="stepper-pane" v-if="step == 2">
                                            <h3 class="text-white">Taille du colis à expédier</h3>
                                            <div class="leaderboard">
                                                <main class="leaderboard__profiles">
                                                    <article class="leaderboard__profile" @click="colisForm.taille = taille.titre" v-for="taille in tailles" :key="taille.id" :value="taille.titre" :class="{ 'sel': colisForm.taille == taille.titre}">
                                                        <img v-if="taille.id == 1" src="./../../assets/img/helpers/icons8-shoes-55.png" alt="Taille S" class="leaderboard__picture">
                                                        <img v-if="taille.id == 2" src="./../../assets/img/helpers/icons8-bag-100.png" alt="Taille M" class="leaderboard__picture">
                                                        <img v-if="taille.id == 3" src="./../../assets/img/helpers/icons8-guitar-66.png" alt="Taille S" class="leaderboard__picture">
                                                        <img v-if="taille.id == 4" src="./../../assets/img/helpers/icons8-cupboard-64.png" alt="Taille S" class="leaderboard__picture">
                                                        <img v-if="taille.id == 5" src="./../../assets/img/helpers/icons8-couch-64.png" alt="Taille S" class="leaderboard__picture">
                                                        <span class="leaderboard__name">{{ taille.text }}</span>
                                                        <span class="leaderboard__value">{{ taille.titre }}</span>
                                                    </article>
                                                </main>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="stepper-content"> 
                                        <div class="stepper-pane" v-if="step == 3">
                                            <div class="service-fields mb-3">
                                                <h3 class="text-white">Informations sur le colis</h3>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <label class="text-white">Qu'expédiez vous?<span class="text-danger">*</span> <span style="color:red" v-if="formControl.nom">(requis)</span></label> 
                                                            <input class="form-control"  type="text" id="name" @change="formControl.nom = false" v-model="colisForm.nom" name="name">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Poids (En KG) <span class="text-danger">*</span> <span style="color:red" v-if="formControl.poids">(requis)</span></label>
                                                            <input class="form-control" type="number" min="0.1" step="0.1" id="weight" @change="formControl.poids = false" v-model="colisForm.poids" name="weight">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Prix en euro (€)<span class="text-danger">*</span> <span style="color:red" v-if="formControl.prix">(requis)</span></label>
                                                            <input class="form-control" min="0.1" step="0.1" type="number" id="price" @change="formControl.prix = false" v-model="colisForm.prix" name="price">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <label class="text-white">Details sur l'expédition<span class="text-danger">*</span> <span style="color:red" v-if="formControl.details">(requis)</span></label>
                                                            <textarea class="form-control service-desc" rows="5" id="more" @change="formControl.details = false" v-model="colisForm.details" name="more"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="stepper-content"> 
                                        <div class="stepper-pane" v-if="step == 4">
                                            <div class="service-fields mb-3">
                                                <h3 class="text-white">Image du produit (au moins une image) </h3>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div
                                                            v-if="colisForm.photo"
                                                            class="showImage"
                                                        >
                                                            <img
                                                                :src="colisForm.photo"
                                                                alt=""
                                                                width="250"
                                                                height="250"
                                                            >
                                                            <button @click="colisForm.photo = ''" href="javascript:void(0);">Retirer</button>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="!file">
                                                                <div
                                                                    :class="['dropZone service-upload', dragging ? 'dropZone-over service-upload' : '']"
                                                                    @dragenter="dragging = true"
                                                                    @dragleave="dragging = false"
                                                                >
                                                                    <div
                                                                        class="dropZone-info"
                                                                        @drag="onChange"
                                                                    >
                                                                        <i class="fas fa-cloud-upload-alt"></i> <span>Chargez l'image de votre colis *</span>
                                                                        <div class="dropZone-upload-limit-info">
                                                                            <div>Taille max: 5 MB</div>
                                                                        </div>
                                                                    </div>
                                                                        <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        size="5000000"
                                                                        @change="onChange"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="dropZone-uploaded service-upload"
                                                            >
                                                                <div class="dropZone-uploaded-info">
                                                                    <span class="dropZone-title">Nom: {{ file.name }}</span>
                                                                    <span class="dropZone-title">Taille: {{ file.size }} octets</span>
                                                                    <button
                                                                    type="button"
                                                                    class="btn btn-primary removeFile"
                                                                    @click="removeFile"
                                                                    >
                                                                    Retirer
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-6">
                                                        <div
                                                            v-if="colisForm.photo2"
                                                            class="showImage"
                                                        >
                                                            <img
                                                                :src="colisForm.photo2"
                                                                alt=""
                                                                width="250"
                                                                height="250"
                                                            >
                                                            <button @click="colisForm.photo2 = ''" href="javascript:void(0);">Retirer</button>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="!file2">
                                                                <div
                                                                    :class="['dropZone service-upload', dragging2 ? 'dropZone-over service-upload' : '']"
                                                                    @dragenter="dragging2 = true"
                                                                    @dragleave="dragging2 = false"
                                                                >
                                                                    <div
                                                                        class="dropZone-info"
                                                                        @drag="onChange2"
                                                                    >
                                                                        <i class="fas fa-cloud-upload-alt"></i> <span>Chargez l'image de votre colis *</span>
                                                                        <div class="dropZone-upload-limit-info">
                                                                            <div>Taille max: 5 MB</div>
                                                                        </div>
                                                                    </div>
                                                                        <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        size="5000000"
                                                                        @change="onChange2"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="dropZone-uploaded service-upload"
                                                            >
                                                                <div class="dropZone-uploaded-info">
                                                                    <span class="dropZone-title">Nom: {{ file2.name }}</span>
                                                                    <span class="dropZone-title">Taille: {{ file2.size }} octets</span>
                                                                    <button
                                                                    type="button"
                                                                    class="btn btn-primary removeFile"
                                                                    @click="removeFile2"
                                                                    >
                                                                    Retirer
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-lg-6">
                                                        <div
                                                            v-if="colisForm.photo3"
                                                            class="showImage"
                                                        >
                                                            <img
                                                                :src="colisForm.photo3"
                                                                alt=""
                                                                width="250"
                                                                height="250"
                                                            >
                                                            <button @click="colisForm.photo3 = ''" href="javascript:void(0);">Retirer</button>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="!file3">
                                                                <div
                                                                    :class="['dropZone service-upload', dragging3 ? 'dropZone-over service-upload' : '']"
                                                                    @dragenter="dragging3 = true"
                                                                    @dragleave="dragging3 = false"
                                                                >
                                                                    <div
                                                                        class="dropZone-info"
                                                                        @drag="onChange3"
                                                                    >
                                                                        <i class="fas fa-cloud-upload-alt"></i> <span>Chargez l'image de votre colis *</span>
                                                                        <div class="dropZone-upload-limit-info">
                                                                            <div>Taille max: 5 MB</div>
                                                                        </div>
                                                                    </div>
                                                                        <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        size="5000000"
                                                                        @change="onChange3"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="dropZone-uploaded service-upload"
                                                            >
                                                                <div class="dropZone-uploaded-info">
                                                                    <span class="dropZone-title">Nom: {{ file3.name }}</span>
                                                                    <span class="dropZone-title">Taille: {{ file3.size }} octets</span>
                                                                    <button
                                                                    type="button"
                                                                    class="btn btn-primary removeFile"
                                                                    @click="removeFile3"
                                                                    >
                                                                    Retirer
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div
                                                            v-if="colisForm.photo4"
                                                            class="showImage"
                                                        >
                                                            <img
                                                                :src="colisForm.photo4"
                                                                alt=""
                                                                width="250"
                                                                height="250"
                                                            >
                                                            <button @click="colisForm.photo4 = ''" href="javascript:void(0);">Retirer</button>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="!file4">
                                                                <div
                                                                    :class="['dropZone service-upload', dragging4 ? 'dropZone-over service-upload' : '']"
                                                                    @dragenter="dragging4 = true"
                                                                    @dragleave="dragging4 = false"
                                                                >
                                                                    <div
                                                                        class="dropZone-info"
                                                                        @drag="onChange4"
                                                                    >
                                                                        <i class="fas fa-cloud-upload-alt"></i> <span>Chargez l'image de votre colis *</span>
                                                                        <div class="dropZone-upload-limit-info">
                                                                            <div>Taille max: 5 MB</div>
                                                                        </div>
                                                                    </div>
                                                                        <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        size="5000000"
                                                                        @change="onChange4"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="dropZone-uploaded service-upload"
                                                            >
                                                                <div class="dropZone-uploaded-info">
                                                                    <span class="dropZone-title">Nom: {{ file4.name }}</span>
                                                                    <span class="dropZone-title">Taille: {{ file4.size }} octets</span>
                                                                    <button
                                                                    type="button"
                                                                    class="btn btn-primary removeFile"
                                                                    @click="removeFile4"
                                                                    >
                                                                    Retirer
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <span style="color:red" v-if="this.errors.length">{{ errors }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="controls">
                                        <button class="btn2" @click="step--, scrollToTop()" :disabled="step == 1">
                                            Précédent
                                        </button>
                                        <div v-if="colisChargement" class="half-circle-spinner" style="margin-left: auto;">
                                            <div class="circle circle-1"></div>
                                            <div class="circle circle-2"></div>
                                        </div>
                                        <button class="btn2 btn-primary" style="margin-left: auto;" @click="stepperControl()" v-if="step < 4">
                                            Suivant
                                        </button>
                                        <button :disabled="colisChargement" class="btn2 btn-primary" style="margin-left: auto;" @click="saveColis" v-if="step >= 4">
                                            Envoyer
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="submit-section">
                                    <button :disabled="chargement" class="btn btn-primary submit-btn" @click="saveColis">Publier ! </button>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>﻿
		</diV>
        <div>
            <Modal v-if="showModal" @close="showModal = false" :text="text">
            </Modal>

            <ModalError v-if="showModalError" @close="showModalError = false" :text="text">
            </ModalError>
        </div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { apiKey } from '../googleAPIKey'
	import googlePlaces from '../googlePlaces'
	import 'regenerator-runtime/runtime'
    import Modal from '../provider/modal.vue'
    import ModalError from '../provider/modalError.vue'
	export default {
    components: { Modal, ModalError },
    data() {
        return {
            step: 1,
            tail: "",
            text: '',
            showModal: false,
            showModalError: false,
            errors: [],
            steps: [
                {
                    id: 1,
                    text: "Etape 1"
                },
                {
                    id: 2,
                    text: "Etape 2"
                },
                {
                    id: 3,
                    text: "Etape 3"
                },
                {
                    id: 4,
                    text: "Etape 4"
                },
            ],
            colisChargement: false,
            formControl: {
                depart: false,
                arrivee: false,
                nom: false,
                poids: false,
                taille: false,
                details: false,
                categorie: false,
                prix: false
            },
            errors: [],
            successMsg: [],
            categories: [],
            chargement: false,
            colisForm: {
                depart: this.$route.params.depart,
                arrivee: this.$route.params.arrivee,
                nom: null,
                poids: null,
                taille: null,
                details: null,
                categorie: null,
                prix: null
            },
            file: null,
            file2: null,
            file3: null,
            file4: null,
            dragging: false,
            dragging2: false,
            dragging3: false,
            dragging4: false,
            model: 1,
            tailles: [
                {
                    id: 1,
                    titre: "Taille S",
                    text: "Tient dans une boite à chaussures (téléphone, clés, doudou...)",
                },
                {
                    id: 2,
                    titre: "Taille M",
                    text: "Tient dans une valise cabine (ordinateur, caisse de vin, platine vinyle...)",
                },
                {
                    id: 3,
                    titre: "Taille L",
                    text: "C'est l'équivalent de 4 petites valises cabine Tient dans le coffre d'une voiture (tableau, télévision, lit parapluie...)",
                },
                {
                    id: 4,
                    titre: "Taille XL",
                    text: "Tient dans un break ou un monospace (commode, fauteuil, table basse...)",
                },
                {
                    id: 5,
                    titre: "Taille XXL",
                    text: "Nécessite un petit utilitaire (scooter, armoire, canapé, lit...)",
                },
            ],
        };
    },
    computed: {
        stepperProgress() {
            return (100 / 3) * (this.step - 1) + "%";
        },
    },
    created() {
        if (localStorage.getItem("coliturageToken") === "") {
            this.$router.push("/");
        }
        this.scrollToTop();
        this.getColis();
    },
    mounted() {
        // Membership Add More
        // Membership Add More
        const link = apiKey;
        let googleScript = document.querySelector(`script[src="${link}"]`);
        if (googleScript) {
            console.log("exist");
            const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
            const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
            autocompleteorigin.addListener("place_changed", () => {
                this.colisForm.depart = autocompleteorigin.getPlace().formatted_address;
                // console.log(this.colisForm.depart)
            });
            autocompletedestination.addListener("place_changed", () => {
                this.colisForm.arrivee = autocompletedestination.getPlace().formatted_address;
                // console.log(this.colisForm.arrivee)
            });
        }
        googlePlaces(link).then((result) => {
            console.log("success");
            const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
            const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
            autocompleteorigin.addListener("place_changed", () => {
                this.colisForm.depart = autocompleteorigin.getPlace().formatted_address;
                // console.log(this.colisForm.depart)
            });
            autocompletedestination.addListener("place_changed", () => {
                this.colisForm.arrivee = autocompletedestination.getPlace().formatted_address;
                // console.log(this.colisForm.arrivee)
            });
        });

        this.scrollToTop()
        
        $(".membership-info").on("click", ".trash", function () {
            $(this).closest(".membership-cont").remove();
            return false;
        });
        $(".add-membership").on("click", function () {
            var membershipcontent = "<div class=\"row form-row membership-cont\">" +
                "<div class=\"col-12 col-md-10 col-lg-6\">" +
                "<div class=\"form-group\">" +
                "<input type=\"text\" class=\"form-control\" name=\"service_offered[]\" id=\"field1\">" +
                "</div>" +
                "</div>" +
                "<div class=\"col-12 col-md-2 col-lg-2\">" +
                "<a href=\"#\" class=\"btn btn-danger trash\"><i class=\"far fa-times-circle\"></i></a>" +
                "</div>" +
                "</div>";
            $(".membership-info").append(membershipcontent);
            return false;
        });
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 400);
        },
        getColis: function () {
            axios.get(`colis/${this.$route.params.id}`)
                .then((response) => {
                this.colisForm = response.data;
            })
                .catch((error) => {
                console.log(error);
            });
        },
        onChange: function (e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.createFile(files[0]);
        },
        createFile: function (file) {
            if (!file.type.match("image.*")) {
                this.messageSnackbar = "Veuillez sélectionner un fichier image";
                this.snackbar = true;
                this.dragging = false;
                return;
            }
            if (file.size > 10000000) {
                this.messageSnackbar = "Veuillez sélectionner un fichier de moins de 5 MB.";
                this.snackbar = true;
                this.dragging = false;
                return;
            }
            this.file = file;
            this.dragging = false;
        },
        removeFile: function () {
            this.file = null;
        },
        onChange2: function (e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging2 = false;
                return;
            }
            this.createFile2(files[0]);
        },
        createFile2: function (file) {
            if (!file.type.match("image.*")) {
                this.dragging2 = false;
                return;
            }
            if (file.size > 10000000) {
                /* this.messageSnackbar = 'Veuillez sélectionner un fichier de moins de 5 MB.'
                this.snackbar = true */
                this.dragging2 = false;
                return;
            }
            this.file2 = file;
            this.dragging2 = false;
        },
        removeFile2: function () {
            this.file2 = null;
        },
        onChange3: function (e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging3 = false;
                return;
            }
            this.createFile3(files[0]);
        },
        createFile3: function (file) {
            if (!file.type.match("image.*")) {
                this.dragging3 = false;
                return;
            }
            if (file.size > 10000000) {
                this.dragging3 = false;
                return;
            }
            this.file3 = file;
            this.dragging3 = false;
        },
        removeFile3: function () {
            this.file3 = null;
        },
        onChange4: function (e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging4 = false;
                return;
            }
            this.createFile4(files[0]);
        },
        createFile4: function (file) {
            if (!file.type.match("image.*")) {
                this.dragging4 = false;
                return;
            }
            if (file.size > 10000000) {
                this.dragging4 = false;
                return;
            }
            this.file4 = file;
            this.dragging4 = false;
        },
        removeFile4: function () {
            this.file4 = null;
        },
        stepperControl: function () {
            this.stepperControlReset;
            let loop = false;
            if (this.step === 1) {
                if (!this.colisForm.depart || this.colisForm.depart === "Départ") {
                    this.formControl.depart = true;
                    loop = true;
                }
                if (!this.colisForm.arrivee || this.colisForm.arrivee === "Arrivée") {
                    this.formControl.arrivee = true;
                    loop = true;
                }
            }
            if (this.step === 2) {
                if (!this.colisForm.taille) {
                    this.formControl.taille = true;
                    loop = true;
                }
            }
            if (this.step === 3) {
                if (!this.colisForm.nom) {
                    this.formControl.nom = true;
                    loop = true;
                }
                if (!this.colisForm.poids) {
                    this.formControl.poids = true;
                    loop = true;
                }
                if (!this.colisForm.prix) {
                    this.formControl.prix = true;
                    loop = true;
                }
                if (!this.colisForm.details) {
                    this.formControl.details = true;
                    loop = true;
                }
            }
            if (!loop) {
                this.step++;
                this.scrollToTop();
            }
        },
        stepperControlReset: function () {
            this.formControl.depart = false;
            this.formControl.arrivee = false;
            this.formControl.nom = false;
            this.formControl.poids = false;
            this.formControl.taille = false;
            this.formControl.details = false;
            this.formControl.prix = false;
        },
        saveColis: function () {
            this.errors = [];
            this.registerSuccessMsg = [];
            if (!this.colisForm.photo && !this.colisForm.photo2 && !this.colisForm.photo3 && !this.colisForm.photo4) {
                if (!this.file && !this.file2 && !this.file3 && !this.file4) {
                    this.errors.push("Veuillez renseigner au moins une image de votre colis");
                }
            }
            if (!this.errors.length) {
                this.colisChargement = true;
                const fileData = new FormData();
                fileData.append("nom", this.colisForm.nom);
                fileData.append("poids", this.colisForm.poids);
                fileData.append("taille", this.colisForm.taille);
                fileData.append("prix", this.colisForm.prix);
                fileData.append("details", this.colisForm.details);
                fileData.append("depart", this.colisForm.depart);
                fileData.append("id", this.colisForm.id);
                fileData.append("arrivee", this.colisForm.arrivee);
                fileData.append("photo", this.colisForm.photo);
                fileData.append("photo2", this.colisForm.photo2);
                fileData.append("photo3", this.colisForm.photo3);
                fileData.append("photo4", this.colisForm.photo4);
                fileData.append("file", this.file);
                fileData.append("file2", this.file2);
                fileData.append("file3", this.file3);
                fileData.append("file4", this.file4);
                axios.post(`coliturage/${this.colisForm.id}`, fileData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.text = 'Colis modifié avec success!'
                    this.showModal = true
                    window.history.back();
                })
                .catch((error) => {
                    this.text = error.response.data.message
                    this.showModalError = true
                    this.colisChargement = false;
                });
            }
        },
        completeStep(payload) {
            this.demoSteps.forEach((step) => {
                if (step.name === payload.name) {
                    step.completed = true;
                }
            });
        },
        // Executed when @active-step event is triggered
        isStepActive(payload) {
            this.demoSteps.forEach((step) => {
                if (step.name === payload.name) {
                    if (step.completed === true) {
                        step.completed = false;
                    }
                }
            });
        },
        // Executed when @stepper-finished event is triggered
        alert(payload) {
            alert("end");
        }
    },
}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>

<style>
.edit-product-upload {
  border: 1px dashed rgb(218, 225, 231);
  border-radius: 8px;
}

.showImage {
  width: 100%;
  height: 250px;
  position: relative;
  border: 2px dashed #eee;
  border-radius: 8px;
}

.dropZone {
  width: 100%;
  height: 100px;
  position: relative;
  border: 2px dashed #eee;
  border-radius: 8px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975A0;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 100px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 100px;
  color: red;
}
</style>
