<template>
	<div class="main-wrapper">
		<layout-headerbookings></layout-headerbookings>
      <div class="content">
			<div class="container">
				<div class="row">
					<div class="col-xl-3 col-md-4">
						<div class="mb-4">
							<div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
								<img alt="profile image" src="../../assets/img/customer/user-01.jpg" class="avatar-lg rounded-circle">
								<div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
									<h6 class="mb-0">Jeffrey Akridge</h6>
									<p class="text-muted mb-0">Member Since Apr 2020</p>
								</div>
							</div>
						</div>
						<div class="widget settings-menu">
							<ul role="tablist" class="nav nav-tabs">
								<li class="nav-item current">
									<router-link to="/user-dashboard" class="nav-link">
										<i class="fas fa-chart-line"></i> <span>Dashboard</span>
									</router-link>
								</li>
								<li class="nav-item current">
									<router-link to="/favourites" class="nav-link">
										<i class="fas fa-heart"></i> <span>Favourites</span>
									</router-link>
								</li>
								<li class="nav-item current">
									<router-link to="/user-bookings" class="nav-link active">
										<i class="far fa-calendar-check"></i> <span>My Bookings</span>
									</router-link>
								</li>
								<li class="nav-item">
									<router-link to="/user-settings" class="nav-link">
										<i class="far fa-user"></i> <span>Profile Settings</span>
									</router-link>
								</li>
								<li class="nav-item">
									<router-link to="/user-wallet" class="nav-link">
										<i class="far fa-money-bill-alt"></i> <span>Wallet</span>
									</router-link>
								</li>
								<li class="nav-item">
									<router-link to="/user-reviews" class="nav-link">
										<i class="far fa-star"></i> <span>Reviews</span>
									</router-link>
								</li>
								<li class="nav-item">
									<router-link to="/user-payment" class="nav-link">
										<i class="fas fa-hashtag"></i> <span>Payment</span>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-xl-9 col-md-8">
						<div class="row align-items-center mb-4">
							<div class="col">
								<h4 class="widget-title mb-0">My Bookings</h4>
							</div>
							<div class="col-auto">
								<div class="sort-by">
									<select class="form-control-sm custom-select">
										<option>All</option>
										<option>Pending</option>
										<option>Inprogress</option>
										<option>Complete Request</option>
										<option>Rejected</option>
										<option>Cancelled</option>
										<option>Completed</option>
									</select>
								</div>
							</div>
						</div>
						
						<div class="bookings">
							<div class="booking-list">
								<div class="booking-widget">
									<router-link to="/service-details" class="booking-img">
										<img src="../../assets/img/services/service-08.jpg" alt="User Image">
									</router-link>
									<div class="booking-det-info">
										<h3>
											<router-link to="/service-details">Building Construction Services</router-link>
										</h3>
										<ul class="booking-details">
											<li>
												<span>Booking Date</span> 23 Jul 2020 <span class="badge badge-pill badge-prof bg-primary">Inprogress</span>
											</li>
											<li><span>Booking time</span> 12:00:00 - 13:00:00</li>
											<li><span>Amount</span> $100</li>
											<li><span>Location</span> Kuala Lumpur, Malaysia</li>
											<li><span>Phone</span> 412-355-7471</li>
											<li>
												<span>Provider</span>
												<div class="avatar avatar-xs me-1">
													<img class="avatar-img rounded-circle" alt="User Image" src="../../assets/img/provider/provider-01.jpg">
												</div>
												Thomas Herzberg
											</li>
										</ul>
									</div>
								</div>
								<div class="booking-action">
									<a href="javascript:void(0);" class="btn btn-sm bg-info-light"><i class="far fa-eye"></i> Chat</a>
									<a href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Cancel the Service</a>
								</div>
							</div>
						</div>
						
						<div class="bookings">
							<div class="booking-list">
								<div class="booking-widget">
									<router-link to="/service-details" class="booking-img">
										<img src="../../assets/img/services/service-09.jpg" alt="User Image">
									</router-link>
									<div class="booking-det-info">
										<h3>
											<router-link to="/service-details">Commercial Painting Services</router-link>
										</h3>
										<ul class="booking-details">
											<li>
												<span>Booking Date</span> 22 Jul 2020 <span class="badge badge-pill badge-prof bg-primary">Inprogress</span>
											</li>
											<li><span>Booking time</span> 11:00:00 - 12:00:00</li>
											<li><span>Amount</span> $25</li>
											<li><span>Location</span> IFL Building A, Phnom Penh, Cambodia</li>
											<li><span>Phone</span> 412-355-7471</li>
											<li>
												<span>Provider</span>
												<div class="avatar avatar-xs me-1">
													<img class="avatar-img rounded-circle" alt="User Image" src="../../assets/img/provider/provider-02.jpg">
												</div>
												Matthew Garcia
											</li>
										</ul>
									</div>
								</div>
								<div class="booking-action">
									<router-link to="/user-chat" class="btn btn-sm bg-info-light"><i class="far fa-eye"></i> Chat</router-link>
									<a href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Cancel the Service</a>
								</div>
							</div>
						</div>
						<div class="bookings">
							<div class="booking-list">
								<div class="booking-widget">
									<router-link to="/service-details" class="booking-img">
										<img src="../../assets/img/services/service-10.jpg" alt="User Image">
									</router-link>
									<div class="booking-det-info">
										<h3>
											<router-link to="/service-details">Plumbing Services</router-link>
										</h3>
										<ul class="booking-details">
											<li>
												<span>Booking Date</span> 23 Jul 2020 <span class="badge badge-pill badge-prof bg-warning">Pending</span>
											</li>
											<li><span>Booking time</span> 09:00:00 - 10:00:00</li>
											<li><span>Amount</span> $50</li>
											<li><span>Location</span> K-JJ, Quezon City, National Capital Region, Filipijnen</li>
											<li><span>Phone</span></li>
											<li>
												<span>Provider</span>
												<div class="avatar avatar-xs me-1">
													<img class="avatar-img rounded-circle" alt="User Image" src="../../assets/img/provider/provider-03.jpg">
												</div>
												Yolanda Potter
											</li>
										</ul>
									</div>
								</div>
								<div class="booking-action">
									<a href="javascript:;" class="btn btn-sm bg-danger-light"> <i class="fas fa-times"></i> Cancel the Service</a>
								</div>
							</div>
						</div>
						<div class="bookings">
							<div class="booking-list">
								<div class="booking-widget">
									<router-link to="/service-details" class="booking-img">
										<img src="../../assets/img/services/service-11.jpg" alt="User Image">
									</router-link>
									<div class="booking-det-info">
										<h3>
											<router-link to="/service-details">Wooden Carpentry Work</router-link>
										</h3>
										<ul class="booking-details">
											<li>
												<span>Booking Date</span> 23 Jul 2020 <span class="badge badge-pill badge-prof bg-warning">Pending</span>
											</li>
											<li><span>Booking time</span> 12:00:00 - 13:00:00</li>
											<li><span>Amount</span> $14</li>
											<li><span>Location</span> 223 Jehovah Drive Roanoke</li>
											<li><span>Phone</span> 412-355-7471</li>
											<li>
												<span>Provider</span>
												<div class="avatar avatar-xs me-1">
													<img class="avatar-img rounded-circle" alt="User Image" src="../../assets/img/provider/provider-04.jpg">
												</div>
												Ricardo Flemings
											</li>
										</ul>
									</div>
								</div>
								<div class="booking-action">
									<a href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Cancel the Service</a>
								</div>
							</div>
						</div>
						<div class="pagination">
							<ul>
								<li class="active">
									<a href="javascript:void(0);">1</a>
								</li>
								<li>
									<a href="javascript:void(0);">2</a>
								</li>
								<li>
									<a href="javascript:void(0);">3</a>
								</li>
								<li>
									<a href="javascript:void(0);">4</a>
								</li>
								<li class="arrow">
									<a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
								</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
		</div> 
		<layout-footer></layout-footer>
	</diV>
</template>

<script>
	export default {
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>