<template>
    <ul class="nav nav-tabs menu-tabs">
        <li :class="{'active nav-item': actif == 'tab1', 'nav-item': actif != 'tab1'}">
            <router-link class="nav-link" to="/provider-payment">Colis publiés</router-link>
        </li>
        <li :class="{'active nav-item': actif == 'tab2', 'nav-item': actif != 'tab2'}">
            <router-link class="nav-link" to="/provider-payment2">Colis soumis</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
      actif: String,
    },
}
</script>

<style>

</style>