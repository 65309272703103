<template>
	<div>
		<div class="main-wrapper">
			<layout-headerbookings></layout-headerbookings>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-md-4">
                            <div class="mb-4">
                            <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                                <img alt="profile image" src="../../assets/img/customer/user-01.jpg" class="avatar-lg rounded-circle">
                                <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                                    <h6 class="mb-0">Jeffrey Akridge</h6>
                                    <p class="text-muted mb-0">Member Since Apr 2020</p>
                                </div>
                            </div>
                        </div>
                            <div class="widget settings-menu">
                                <ul role="tablist" class="nav nav-tabs">
                                    <li class="nav-item current"> 
                                        <router-link to="/user-dashboard" class="nav-link">
                                            <i class="fas fa-chart-line"></i> <span>Dashboard</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item current">
                                    <router-link to="/favourites" class="nav-link">
                                        <i class="fas fa-heart"></i> <span>Favourites</span>
                                    </router-link>
                                    </li>
                                    <li class="nav-item current">
                                        <router-link to="/user-bookings" class="nav-link">
                                            <i class="far fa-calendar-check"></i> <span>My Bookings</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-settings" class="nav-link">
                                            <i class="far fa-user"></i> <span>Profile Settings</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-wallet" class="nav-link">
                                            <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-reviews" class="nav-link">
                                            <i class="far fa-star"></i> <span>Reviews</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-payment" class="nav-link">
                                            <i class="fas fa-hashtag"></i> <span>Payment</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-9 col-md-8">
                            <h4 class="widget-title">Reviews</h4>
                            <div class="card review-card mb-0">
                                <div class="card-body">
                                    <div class="review-list">
                                        <div class="review-img">
                                            <img class="rounded img-fluid" src="../../assets/img/services/service-12.jpg" alt="">
                                        </div>
                                        <div class="review-info">
                                            <h5><a href="">Air Conditioner Service</a></h5>
                                            <div class="review-date">July 11, 2020 11:38 am</div>
                                            <p class="mb-2">Good Work</p>
                                            <div class="review-user">
                                                <img class="avatar-xs rounded-circle" src="../../assets/img/provider/provider-01.jpg" alt=""> Thomas Herzberg
                                            </div>
                                        </div>
                                        <div class="review-count">
                                            <div class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <span class="d-inline-block average-rating">(5)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-list">
                                        <div class="review-img">
                                            <img class="rounded img-fluid" src="../../assets/img/services/service-11.jpg" alt="">
                                        </div>
                                        <div class="review-info">
                                            <h5><a href="">Wooden Carpentry Work</a></h5>
                                            <div class="review-date">July 05, 2020 15:33 pm</div>
                                            <p class="mb-2">Best Work</p>
                                            <div class="review-user">
                                                <img class="avatar-xs rounded-circle" src="../../assets/img/provider/provider-02.jpg" alt=""> Matthew Garcia
                                            </div>
                                        </div>
                                        <div class="review-count">
                                            <div class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <span class="d-inline-block average-rating">(5)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-list">
                                        <div class="review-img">
                                            <img class="rounded img-fluid" src="../../assets/img/services/service-10.jpg" alt="">
                                        </div>
                                        <div class="review-info">
                                            <h5><a href="">Plumbing Services</a></h5>
                                            <div class="review-date">June 29, 2020 05:04 am</div>
                                            <p class="mb-2">Excellent Service</p>
                                            <div class="review-user">
                                                <img class="avatar-xs rounded-circle" src="../../assets/img/provider/provider-03.jpg" alt=""> Yolanda Potter
                                            </div>
                                        </div>
                                        <div class="review-count">
                                            <div class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star"></i>
                                                <span class="d-inline-block average-rating">(4)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-list">
                                        <div class="review-img">
                                            <img class="rounded img-fluid" src="../../assets/img/services/service-09.jpg" alt="">
                                        </div>
                                        <div class="review-info">
                                            <h5><a href="">Commercial Painting Services</a></h5>
                                            <div class="review-date">June 26, 2020 02:22 am</div>
                                            <p class="mb-2">Thanks</p>
                                            <div class="review-user">
                                                <img class="avatar-xs rounded-circle" src="../../assets/img/provider/provider-04.jpg" alt=""> Ricardo Flemings
                                            </div>
                                        </div>
                                        <div class="review-count">
                                            <div class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <span class="d-inline-block average-rating">(5)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-list">
                                        <div class="review-img">
                                            <img class="rounded img-fluid" src="../../assets/img/services/service-08.jpg" alt="">
                                        </div>
                                        <div class="review-info">
                                            <h5><a href="">Building Construction Services</a></h5>
                                            <div class="review-date">June 13, 2020 17:38 pm</div>
                                            <p class="mb-2">Amazing</p>
                                            <div class="review-user">
                                                <img class="avatar-xs rounded-circle" src="../../assets/img/provider/provider-05.jpg" alt=""> Maritza Wasson
                                            </div>
                                        </div>
                                        <div class="review-count">
                                            <div class="rating mapgridrating1">
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star"></i>
                                                <span class="d-inline-block average-rating">(4)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		<layout-footer></layout-footer>
		</diV>
	</div>
</template>

<script>
	export default {
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>