<template>
    <ul class="nav nav-tabs menu-tabs">
        <li :class="{'active nav-item': actif == 'tab1', 'nav-item': actif != 'tab1'}">
            <router-link class="nav-link" to="/my-trajets">Trajets à venir</router-link>
        </li>
        <li :class="{'active nav-item': actif == 'tab2', 'nav-item': actif != 'tab2'}">
            <router-link class="nav-link" to="/my-trajets-inactive">Historique des Trajets</router-link>
        </li>
        <!-- <li :class="{'active nav-item': actif == 'tab3', 'nav-item': actif != 'tab3'}">
            <router-link class="nav-link" to="/my-trajets-sub">Souscriptions aux colis</router-link>
        </li> -->
    </ul>
</template>

<script>
export default {
    props: {
      actif: String,
    },
}
</script>

<style>

</style>