<template>
	<div>
		<div class="main-wrapper">
		<!-- Breadcrumb -->
		<div class="breadcrumb-bar">
			<div class="container">
				<div class="row">
					<div class="col">
						<div class="breadcrumb-title">
							<h2>Categories</h2>
						</div>
					</div>
					<div class="col-auto float-end ms-auto breadcrumb-menu">
						<nav aria-label="breadcrumb" class="page-breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<router-link to="/index">Accueil</router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Categories</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- /Breadcrumb -->
		
		<div class="content">
			<div class="container">
				<div class="catsec clearfix">
					<div class="row">
						<div v-for="categorie in categories" :key="categorie.id" class="col-lg-4 col-md-6">
							<CardCategorie :categorie="categorie"></CardCategorie>
						</div>
					</div>
					<div class="pagination">
						<ul>
							<li :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getCategorie(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
							<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
							<!-- <li><a href="javascript:void(0);">2</a></li>
							<li><a href="javascript:void(0);">3</a></li>
							<li><a href="javascript:void(0);">4</a></li> -->
							<li :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getCategorie(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
						</ul>
					</div>
				</div>
			</div>﻿ 
		</div>﻿ 
		<layout-footer></layout-footer>
		</diV>
	</div>
</template>

<script>
	import axios from 'axios'
  	import CardCategorie from './CardCategorie.vue'

	export default {
    	components: { CardCategorie },
		data () {
      		return {
				categories: [],
				itemsPerPage: null,
				page: null,
				numberOfPages: null,
				firstPageUrl: null,
				lastPageUrl: null,
				from: null,
				to: null,
				total: null,
				nextPageUrl: null,
				prevPageUrl: null,
				path: 'categories-paginate'
			}
		},
		created () {
			window.scrollTo(0, 0)
			this.getCategorie(this.path)
		},
		mounted() {
			 // Membership Add More

			$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});
		},
		methods: {
			getCategorie: function (path) {
				axios.get(path)
					.then((response) => {
						this.categories = response.data.data
						this.itemsPerPage = response.data.per_page
						this.page = response.data.current_page
						this.numberOfPages = response.data.last_page
						this.firstPageUrl = response.data.first_page_url
						this.lastPageUrl = response.data.last_page_url
						this.from = response.data.from
						this.to = response.data.to
						this.total = response.data.total
						this.nextPageUrl = response.data.next_page_url
						this.prevPageUrl = response.data.prev_page_url
					})
					.catch((error) => {
						console.log(error)
					})
			},
		},
	}
</script>