<template>
	<div class="main-wrapper">
        <div class="content">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10">
						<div class="section-header text-center">
							<h2>Conditions d’utilisation Coliturage </h2>
							<p>Conditions d'utilisation pour un site de transport de colis entre particuliers</p>
						</div>
						
						<div class="row">
							<div class="col-12">
								<div class="terms-content">
									<div class="terms-text">
										<h3>Introduction</h3>
										<p>Les présentes Conditions d'utilisation (« Conditions ») régissent votre utilisation du site de transport de colis entre particuliers (le « Site »). En utilisant le Site, vous acceptez d'être lié par ces Conditions. Si vous n'acceptez pas ces Conditions, veuillez ne pas utiliser le Site.</p>
									</div>
									<div class="terms-text">
										<h3>Définitions</h3>
										<p>
											<ul>
												<li><b>« Site »</b> désigne le site de transport de colis entre particuliers exploité par 2ASR CONSEILS, accessible à l'adresse https://coliturage.com</li>
												<li><b>« Utilisateur »</b> désigne toute personne qui accède au Site.</li>
												<li><b>« Transporteur »</b> désigne toute personne qui propose de transporter des colis pour d'autres utilisateurs du Site.</li>
												<li><b>« Expéditeur »</b> désigne toute personne qui demande à un transporteur de transporter un colis.</li>
												<li><b>« Colis »</b> désigne tout objet transporté par un transporteur pour le compte d'un expéditeur.</li>
											</ul>
										</p>
									</div>
									<div class="terms-text">
										<h3>Utilisation du Site</h3>
										<p>Vous ne pouvez utiliser le Site que dans le respect des lois applicables et de ces Conditions. Vous ne pouvez utiliser le Site qu'à des fins légales et pour effectuer des transactions de transport de colis entre particuliers.</p>
									</div>
									<div class="terms-text">
										<h3>Inscription</h3>
										<p>L'inscription au Site est gratuite. Pour devenir utilisateur, vous devez créer un compte sur le Site. Vous devez fournir des informations exactes, complètes et à jour lors de l'inscription. Vous êtes responsable de la sécurité de votre compte et de vos informations d'identification. Vous ne pouvez pas utiliser le compte d'une autre personne sans autorisation.</p>
									</div>
									<div class="terms-text">
										<h3>Utilisation du Site par les transporteurs</h3>
										<p>Les transporteurs qui souhaitent proposer leurs services sur le Site doivent être en mesure de transporter des colis en toute sécurité et en conformité avec les lois applicables. Les transporteurs sont responsables de fournir des informations précises sur leur profil et sur les colis qu'ils transportent. Les transporteurs ne doivent pas utiliser le Site pour transporter des objets illégaux ou dangereux.</p>
									</div>
									<div class="terms-text">
										<h3>Utilisation du Site par les expéditeurs</h3>
										<p>Les expéditeurs qui utilisent le Site doivent fournir des informations précises sur les colis à transporter. Les expéditeurs sont responsables de s'assurer que les colis sont légaux et ne contiennent pas d'objets dangereux.</p>
									</div>
									<div class="terms-text">
										<h3>Paiement</h3>
										<p>Le paiement des frais de transport des colis se fait directement entre l'expéditeur et le transporteur. Le Site ne perçoit aucun frais pour les transactions de transport de colis entre particuliers.</p>
									</div>
									<div class="terms-text">
										<h3>Responsabilité</h3>
										<p>Le Site ne garantit pas la qualité ou la sécurité des services de transporteurs ou des colis transportés. Les transporteurs et les expéditeurs utilisent le Site à leurs propres risques. Le Site n'est pas responsable des pertes, dommages ou préjudices résultant de l'utilisation du Site ou des services proposés sur le Site.</p>
									</div>
									<div class="terms-text">
										<h3>Modification des conditions d'utilisation</h3>
										<p>
											Le Site se réserve le droit de modifier ces Conditions à tout moment et sans préavis. Les modifications entreront en vigueur dès leur publication sur 
											le Site. Il est de votre responsabilité de vérifier régulièrement ces Conditions pour prendre connaissance des modifications éventuelles. Votre utilisation continue du Site après la publication des modifications constitue votre acceptation de ces Conditions modifiées.
										</p>
									</div>
									<div class="terms-text">
										<h3>Résiliation</h3>
										<p>Le Site peut résilier votre compte et votre accès au Site à tout moment et sans préavis en cas de violation de ces Conditions ou de toute autre violation de la loi applicable.</p>
									</div>
									<div class="terms-text">
										<h3>Propriété intellectuelle</h3>
										<p>Le Site et tout son contenu sont la propriété de 2ASR CONSEILS et sont protégés par les lois sur la propriété intellectuelle. Vous ne pouvez pas utiliser le contenu du Site à des fins commerciales sans l'autorisation préalable de 2ASR CONSEILS.</p>
									</div>
									<div class="terms-text">
										<h3>Loi applicable et juridiction compétente</h3>
										<p>Ces Conditions sont régies par les lois françaises. Tout litige relatif à ces Conditions ou à l'utilisation du Site sera soumis à la compétence exclusive des tribunaux de Paris.</p>
									</div>
									<div class="terms-text">
										<p>En utilisant le Site, vous reconnaissez avoir lu et compris ces Conditions et vous acceptez d'être lié par celles-ci.</p>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>﻿
	</div>
</template>

<script>
	export default {
		mounted() {
			document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			 // Membership Add More

		$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});

		},
	}
</script>