<template>
    <div class="service-widget">
        <div class="service-img">
            <router-link :to="'/service-trajet-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id">
                <div style="height: 220px; text-align: center; padding-top: auto;">
                    <span style="display: inline-block; height: 100%;vertical-align: middle;"></span><img class="img-fluid" alt="Service Image" :src="colis.photo2" style="vertical-align: middle;">
                </div>
            </router-link>
            <!-- <div class="fav-btn">
                <a href="javascript:void(0)" class="fav-icon">
                    <i class="fas fa-heart"></i>
                </a>
            </div> -->
            <div class="item-info">
                <div class="service-user">
                    <span class="service-price ms-1">€ {{colis.prix}}</span>
                </div>
                <div class="cate-list">
                    <router-link class="bg-yellow" to="#">{{colis.taille}}</router-link>
                </div>
            </div>
        </div>
        <div class="service-content">
            <h3 class="title">
                <router-link :to="'/service-trajet-details/'+colis.id+'/'+colis.nom+'/'+colis.user_id">{{MaskCharacter(colis.nom, 30)}}</router-link>
            </h3>
            <div class="row">	
                <span class="col-6 d-inline-block average-rating"></span>
            </div>
            <div class="user-info">
                <div class="">	
                    <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                        <span style="font-size: 14px">{{MaskCharacter(colis.depart, 25)}}</span>
                    </span>
                    <span class="col ser-location">
                        <span style="font-size: 14px">{{MaskCharacter(colis.arrivee, 25)}}</span>
                        <i class="fas fa-flag ms-1"></i>
                    </span>
                </div>
            </div>
            <div class="row">	
                <span class="col-12 d-inline-block average-rating text-right" style="font-size: 12px">Publié le {{colis.date_publication}}</span>
            </div>
            <div class="user-info">
                <div class="service-action">
                    <div class="row">
                        <div class="col-3">
                            <router-link to="#" class="text-danger" style="font-size: 12px" @click="showModalAnnuler = true">
                                <button class="btn btn-danger" style="font-size: 11px; padding: 1px">
                                    Annuler
                                </button>
                            </router-link>
                        </div>
                        <div class="col-2">
                            <router-link :to="'/edit-service/'+colis.id+'/'+colis.nom" style="font-size: 12px" class="text-success">
                                <button class="btn btn-success" style="font-size: 11px; padding: 1px">
                                    Editer
                                </button>
                            </router-link>
                        </div>
                        <div class="col-7">
                            <router-link :to="'/service-bookings/'+colis.id+'/'+colis.nom" class="text-info">
                                <button class="btn btn-primary" style="font-size: 11px; padding: 1px">
                                    Voir les propositions
                                </button>
                            </router-link>
                        </div>
                        <!-- <div class="col text-end">
                            <a href="javascript:void(0);" id="menu_close" class="nav-link menu-close header-login" data-bs-toggle="modal" @click.native="data = colis" data-bs-target="#deleteNotConfirmModal"><i class="fas fa-info-circle"></i> Inactive</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="deleteNotConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="acc_title">Annuler l'envoi du colis?</h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h3 class="title">
                            {{coli.nom}}
                        </h3>
                        <div class="user-info">
                            <div class="">	
                                <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                                    <span>{{coli.depart}}</span>
                                </span>
                                <span class="col ser-location">
                                    <span>{{coli.arrivee}}</span>
                                    <i class="fas fa-flag ms-1"></i>
                                </span>
                            </div>
                        </div>
                        <div class="row">	
                            <span class="col-12 d-inline-block average-rating text-right">Publié le {{coli.date_publication}}</span>
                        </div>
                    </div>
                    <div v-if="chargement" class="half-circle-spinner" style="margin-left: auto;">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                    <div v-else class="modal-footer">
                        <button type="button" @click="desactiver(coli.id)" class="btn btn-success si_accept_confirm">Oui</button>
                        <button type="button" class="btn btn-danger si_accept_cancel" data-bs-dismiss="modal" aria-label="Close">Non</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div>
            <ModalError v-if="showModalError" @close="showModalError = false" :text="text">
            </ModalError>
            <ModalAnnuler key="1" v-if="showModalAnnuler" @close="showModalAnnuler = false" :colis="colis" />
        </div>
    </div>
</template>

<script>
	import axios from 'axios'
    import ModalError from '../layout/modalError.vue'
    import ModalAnnuler from './ModalAnnuler.vue';
    export default {
        props: {
            cardHoverShadow: {
                type: Boolean,
                default: false,
            },
            colis: Object,
        },
		components: {
            ModalError,
            ModalAnnuler,
        },
        data () {
            return {
                data: '',
				chargement: false,
                showModalAnnuler: false,
                showModalError: false,
                text: '',
                coli: {},
            }
        },
        methods: {
            MaskCharacter(str, n) {
                if (str.length > n){
                    return ('' + str).slice(0, n) + '...';
                } else {
                    return str
                }
            },
            reload () {
                if (this.$route.name === 'service-trajet-details') {
                    sessionStorage.setItem('urltransition', '/service-trajet-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id)
                    this.$router.push('/transition')
                } else {
                    this.$router.push('/service-trajet-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id)
                }
            },
            desactiver (id) {
                this.chargement = true
                axios.delete(`colis-desactiver/${id}`)
				.then((response) => {
                    window.location.reload()
                    this.chargement = false
				})
				.catch((error) => {
                    this.text = error.response.data.message
                    this.showModalError = true
                    this.chargement = false
				})
            }
        }
    }
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
    @import "./../scss/modal.scss";
</style>