<template>
    <div class="bookings">
        <div class="booking-list">
            <div class="booking-widget">
                <!-- <router-link :to="'/service-trajet-details/' + this.booking.id + '/' + this.booking.nom + '/' + this.booking.user_id" class="booking-img">
                    <img :src="booking.photo" alt="User Image">
                </router-link> -->
                <div class="booking-det-info">
                    <h3>
                        <router-link :to="'/service-trajet-details/' + this.booking.id + '/' + this.booking.nom + '/' + this.booking.user_id">{{booking.nom}} (<i class="far fa-eye"></i>)</router-link>
                    </h3>
                    <ul class="booking-details">
                        <li><span>Soumis le</span>{{booking.date_publication}}</li>
                        <li v-if="this.booking.depart"><span>Au Départ de</span>{{booking.depart}}</li>
                        <li v-if="this.booking.arrivee"><span>Pour </span>{{booking.arrivee}}</li>
                        <li v-if="this.booking.date_depart"><span>Le </span>{{booking.date_depart}}</li>
                        <li><span>Taille</span> {{booking.taille}}</li>
                        <li><span>Poids</span> {{booking.poids}} kg</li>
                        <li><span>Montant</span> €{{booking.prix}}</li>
                        <!-- <li><span>Adresse</span> {{booking.adresse}}</li> -->
                        <li><span>Phone</span> {{MaskCharacter(booking.phone, '#', 3)}}</li>
                        <li>
                            <span>Utilisateur</span>
                            <div class="avatar avatar-xs me-1">
                                <img class="avatar-img rounded-circle" alt="User Image" src="../../assets/img/customer/user-01.jpg">
                            </div>
                            {{booking.name}}
                        </li>
                        <!-- <span class="badge badge-pill badge-prof bg-success">Complete Request sent to User</span>
                        <span class="badge badge-pill badge-prof bg-primary">Inprogress</span>
                        <span class="badge badge-pill badge-prof bg-danger">Rejected by User</span> -->
                    </ul>
                </div>
            </div>
            <div class="booking-action">
                <a v-if="booking.statut == 'Accepté' || booking.accepte == 'OUI'" href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Colis accepter</a>
                <a v-if="booking.statut == 'Refusé' || booking.accepte == 'NON'" href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Colis refuser</a>
                <a v-if="booking.expedie == 'OUI'" href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Colis livré</a>
                <a v-if="booking.expedie == 'NON'" href="javascript:;" class="btn btn-sm bg-info-light">
                    <i class="fas fa-check"></i>
                    <router-link to="/provider-colis-confirm" style="color:blue"> Transport en cours</router-link>
                </a>
                <a v-if="booking.accepte != 'OUI' && booking.accepte != 'NON'" href="javascript:;" class="btn btn-sm bg-info-light">
                    <router-link :to="'/service-trajet-details/' + this.booking.id + '/' + this.booking.nom + '/' + this.booking.user_id">Accepter/Refuser</router-link>
                </a>
            </div>
            <!-- <div class="booking-action">
                <a v-if="booking.accepte == 'OUI'" href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Colis accepter</a>
                <a v-if="booking.accepte == 'NON' || booking.statut == 'Refusé'" href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Colis refuser</a>
                <a v-if="booking.expedie == 'OUI'" href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Colis livré</a>
                <a v-if="booking.statut == 'Actif'" href="javascript:;" class="btn btn-sm bg-info-light"><i class="fas fa-check"></i>En attente</a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      booking: Object,
    },
    methods: {
        MaskCharacter(str, mask, n = 1) {
            // Slice the string and replace with
            // mask then add remaining string
            return ('' + str).slice(0, -n)
                .replace(/./g, mask)
                + ('' + str).slice(-n);
        },
        reload () {
            if (this.$route.name === 'trajet-details') {
                sessionStorage.setItem('urltransition', '/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
            }
        }
    }
}
</script>

<style>

</style>