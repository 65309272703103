<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar ">
                        <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <h4 class="widget-title  text-end">Transport de colis en cours</h4>
                        <NavTrajetEncours :actif="tab" />
                        <div class="card transaction-table mb-0">
                            <div class="card-body">
                                <div id="dataList">
                                    <div v-for="item in providerpayment" :key="item.id" class="">
                                        <div class="bookings">
                                            <div class="booking-list">
                                                <div class="booking-widget">
                                                    <div class="booking-det-info">
                                                        <h3>
                                                            {{item.nom}}
                                                        </h3>
                                                        <ul class="booking-details">
                                                            <li>
                                                                <span>Départ de </span>{{item.depart}}
                                                            </li>
                                                            <li>
                                                                <span>Arrivée à </span>{{item.arrivee}}
                                                            </li>
                                                            <li>
                                                                <span>Transporteur </span>{{item.name}}
                                                            </li>
                                                            <li><span>Montant </span>{{item.prix}} €</li>
                                                            <li>
                                                                <span>Date de départ</span>{{item.date_depart}}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="booking-action">
                                                    <a v-if="item.statut == 'Accepté'" href="javascript:;" class="btn btn-sm bg-success-light" data-bs-toggle="modal" data-bs-target="#confirmer"><i class="fas fa-check"></i> En cours de transport</a>
                                                    <!-- <a v-if="booking.statut == 'Refusé'" href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Trajet refusé</a>
                                                    <span v-if="booking.statut != 'Accepté' && booking.statut != 'Refusé'" class="badge badge-pill badge-prof bg-warning">En attente</span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="providerpayment.length == 0" class="text-center">
                            <img src="../../assets/img/nothing-found.png" class="nothing" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Accepter Modal -->
        <div class="modal account-modal fade" id="confirmer">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-0 border-0">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="login-header">
                            <h3>Code de confirmation</h3>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <textarea class="form-control service-desc" rows="5" id="more" v-model="form.code" name="more"></textarea>
                            </div>
                        </div>
                        <div class="d-grid">
                            <button :disabled="chargement" class="btn btn-success btn-block btn-lg login-btn" @click="confirmerColis">
                                <div v-if="chargement" class="half-circle-spinner">
                                    <div class="circle circle-1"></div>
                                    <div class="circle circle-2"></div>
                                </div>
                                <div v-else>
                                    Confirmer
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Accepter Modal -->

        <div>
            <Modal v-if="showModal" @close="showModal = false" :text="text">
            </Modal>
            <ModalError v-if="showModalError" @close="showModalError = false" :text="text">
            </ModalError>
        </div>
	</diV>
</template>

<script>
    const serImages = require.context('../../assets/img/services', false, /\.png$|\.jpg$/)
    const cusImages = require.context('../../assets/img/customer', false, /\.png$|\.jpg$/)
    // import providerpayment from '../../json/website/provider/providerpayment'
    import Dashboard from './Dashboard.vue'
	import axios from 'axios'
    import Modal from './modal.vue'
    import ModalError from './modalError.vue'
    import NavTrajetEncours from './NavTrajetEncours.vue'

	export default {
        components: {
            Dashboard,
            Modal,
            ModalError,
            NavTrajetEncours
        },
        data() {
            return {
                showModalError: false,
                showModal: false,
                tab: 'tab2',
                text: '',
                providerpayment: [],
                form:{},
                user: {},
				token: localStorage.getItem('coliturageToken'),
                chargement: false,
                userData: JSON.parse(localStorage.getItem('coliturageUserData')),
            }
        },
        methods: {
            confirmerColis: function () {
                this.chargement = true
                axios.post('confirmer-trajet-colis', this.form)
                    .then((response) => {
                        this.text = response.data.message
						this.showModal = true
                        this.chargement = false
                        window.location.reload()
                    })
                    .catch((error) => {
                        this.text = error.response.data.message
						this.showModalError = true
                        this.chargement = false
                    })
            },
            getColis: function () {
                axios.get('trajet-colis-transportes')
                    .then((response) => {
                        this.providerpayment = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
        },
		mounted() {

            if (!localStorage.getItem('coliturageToken')) {
                this.$router.push('/')
            } 

            $(".membership-info").on('click','.trash', function () {
                $(this).closest('.membership-cont').remove();
                return false;
            });

            $(".add-membership").on('click', function () {
                var membershipcontent = '<div class="row form-row membership-cont">' +
                '<div class="col-12 col-md-10 col-lg-6">' +
                '<div class="form-group">' +
                '<input type="text" class="form-control" name="service_offered[]" id="field1">' +
                '</div>' +
                '</div>' +
                '<div class="col-12 col-md-2 col-lg-2">' +
                '<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
                '</div>' +
                '</div>';
                $(".membership-info").append(membershipcontent);
                return false;
            });

            this.getColis()
		},
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
    @import "./../scss/loading.scss";
</style>
