<template>
		<div class="main-wrapper">
      <div class="content">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10">
						<div class="section-header text-center">
							<h2>Modifier un Déplacement</h2>
						</div>
						<form 
							@submit.prevent=""
							novalidate="true"
						>
							<div class="wrapper-stepper">
								<div class="stepper">
									<div class="stepper-progress">
										<div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
									</div>

									<div class="stepper-item" v-for="item in steps" :key="item.id" :class="{ 'current': step == item.id, 'success': step > item.id }">
										<div class="stepper-item-counter">
											<img class="icon-success" src="./../../assets/img/helpers/icons8-ok-144.svg" alt="">
											<span class="number">
												{{ item.id }}
											</span>
										</div>
										<span class="stepper-item-title">
											{{ item.text }}
										</span>
									</div>
								</div>
								<div class="stepper-content"> 
									<div class="stepper-pane" v-if="step == 1">
										<h3 class="text-white">Trajet et Date du Déplacement</h3>
										<div class="row">
											<div class="search-input2" style="width: 50%">
												<span class="line" style="color:red" v-if="formControl.depart">(requis)</span>
												<i class="fas fa-map-marker bficon" :style="[!formControl.depart ? 'margin-top: -5px' : '']"></i>
												<div class="form-group mb-3">
													<input ref="origin" type="text" class="form-control" placeholder="Départ" @change="formControl.depart = false" :value="colisForm.depart" id="depart" name="depart">
												</div>
											</div>
											<div class="search-input2" style="width: 50%">
												<span class="line" style="color:red" v-if="formControl.arrivee">(requis)</span>
												<i class="fas fa-location-arrow bficon" :style="[!formControl.arrivee ? 'margin-top: -5px' : '']"></i>
												<div class="form-group mb-3">
													<input ref="destination" type="text" class="form-control" placeholder="Arrivée" @change="formControl.arrivee = false" :value="colisForm.arrivee" id="arrivee" name="arrivee"> 
													<a class="current-loc-icon current_location" :style="[!formControl.arrivee ? 'margin-top: -5px' : '']" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a>
												</div>
											</div>
											<div class="col-lg-6 col-md-6">
												<div class="form-group">
													<label class="text-white">Date du Déplacement<span class="text-danger">*</span> <span style="color:red" v-if="formControl.date">(Date invalide)</span></label> 
													<input class="form-control" type="date" id="date" @change="formControl.date = false" v-model="colisForm.date" name="date">
												</div>
											</div>
											<div class="col-lg-6 col-md-6">
												<div class="form-group">
													<label class="text-white">Prix en euro (€)<span class="text-danger">*</span> <span style="color:red" v-if="formControl.prix">(requis)</span></label>
													<input class="form-control" min="0.1" step="0.1" type="number" id="price" @change="formControl.prix = false" v-model="colisForm.prix" name="price">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="stepper-content"> 
									<div class="stepper-pane" v-if="step == 2">
										<div class="leaderboard">
											<h3 class="text-white">Taille des colis à transporter</h3>
											<main class="leaderboard__profiles">
												<article class="leaderboard__profile" @click="colisForm.taille = taille.titre" v-for="taille in tailles" :key="taille.id" :value="taille.titre" :class="{ 'sel': colisForm.taille == taille.titre}">
													<img v-if="taille.id == 1" src="./../../assets/img/helpers/icons8-ok-144.svg" alt="Tous" class="leaderboard__picture">
													<img v-if="taille.id == 2" src="./../../assets/img/helpers/icons8-shoes-55.png" alt="Taille S" class="leaderboard__picture">
													<img v-if="taille.id == 3" src="./../../assets/img/helpers/icons8-bag-100.png" alt="Taille M" class="leaderboard__picture">
													<img v-if="taille.id == 4" src="./../../assets/img/helpers/icons8-guitar-66.png" alt="Taille S" class="leaderboard__picture">
													<img v-if="taille.id == 5" src="./../../assets/img/helpers/icons8-cupboard-64.png" alt="Taille S" class="leaderboard__picture">
													<img v-if="taille.id == 6" src="./../../assets/img/helpers/icons8-couch-64.png" alt="Taille S" class="leaderboard__picture">
													<span class="leaderboard__name">{{ taille.text }}</span>
													<span class="leaderboard__value">{{ taille.titre }}</span>
												</article>
											</main>
										</div>
									</div>
								</div>
								
								<div class="stepper-content"> 
									<div class="stepper-pane" v-if="step == 3">
										<div class="service-fields mb-3">
											<h3 class="text-white">Details sur le Déplacement</h3>
											<div class="row">
												<div class="col-lg-12">
													<div class="form-group">
														<label class="text-white">Poids Maximal accepté (En KG)<span class="text-danger">*</span> <span style="color:red" v-if="formControl.poids">(requis)</span></label>
														<input class="form-control" min="0.1" step="0.1" type="number" id="price" @change="formControl.poids = false" v-model="colisForm.poids" name="price">
													</div>
												</div>
												<div class="col-lg-12">
													<div class="form-group">
														<label class="text-white">Détails <span class="text-danger">*</span> <span style="color:red" v-if="formControl.details">(requis)</span></label>
														<textarea class="form-control service-desc" rows="5" id="more" @change="formControl.details = false" v-model="colisForm.details" name="more"></textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="stepper-content"> 
									<div class="stepper-pane" v-if="step == 4">
										<div class="service-fields mb-3">
											<h3 class="text-white">Image de Présentation</h3>
												<div class="col-lg-12">
                                                        <div
                                                            v-if="colisForm.photo"
                                                            class="showImage"
                                                        >
                                                            <img
                                                                :src="colisForm.photo"
                                                                alt=""
                                                                width="250"
                                                                height="250"
                                                            >
                                                            <button @click="colisForm.photo = ''" href="javascript:void(0);">Retirer</button>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="!file">
                                                                <div
                                                                    :class="['dropZone service-upload', dragging ? 'dropZone-over service-upload' : '']"
                                                                    @dragenter="dragging = true"
                                                                    @dragleave="dragging = false"
                                                                >
                                                                    <div
                                                                        class="dropZone-info"
                                                                        @drag="onChange"
                                                                    >
                                                                        <i class="fas fa-cloud-upload-alt"></i> <span>Chargez l'image de votre colis *</span>
                                                                        <div class="dropZone-upload-limit-info">
                                                                            <div>Taille max: 10 MB</div>
                                                                        </div>
                                                                    </div>
                                                                        <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        size="10000000"
                                                                        @change="onChange"
                                                                        >
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="dropZone-uploaded service-upload"
                                                            >
                                                                <div class="dropZone-uploaded-info">
                                                                    <span class="dropZone-title">Nom: {{ file.name }}</span>
                                                                    <span class="dropZone-title">Taille: {{ file.size }} octets</span>
                                                                    <button
                                                                    type="button"
                                                                    class="btn btn-primary removeFile"
                                                                    @click="removeFile"
                                                                    >
                                                                    Retirer
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <span style="color:red" v-if="this.errors.length">{{ errors }}</span>
										</div>
									</div>
								</div>
								<div class="controls">
									<button class="btn2" @click="step--, scrollToTop()" :disabled="step == 1">
										Précédent
									</button>
									<div v-if="colisChargement" class="half-circle-spinner" style="margin-left: auto;">
										<div class="circle circle-1"></div>
										<div class="circle circle-2"></div>
									</div>
									<button class="btn2 btn-primary" style="margin-left: auto;" @click="stepperControl()" v-if="step < 4">
										Suivant
									</button>
									<button :disabled="colisChargement" class="btn2 btn-primary" style="margin-left: auto;" @click="saveColis" v-if="step >= 4">
										Publier
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>﻿
		<div>
            <Modal v-if="showModal" @close="showModal = false" :text="text">
            </Modal>

            <ModalError v-if="showModalError" @close="showModalError = false" :text="text">
            </ModalError>
        </div>
		<loader v-if="!chargement"></loader>
		</diV>
</template>

<script>
	import axios from 'axios'
	import { apiKey } from '../googleAPIKey'
	import googlePlaces from '../googlePlaces'
    import Modal from '../provider/modal.vue'
    import ModalError from '../provider/modalError.vue'
	import 'regenerator-runtime/runtime'

	export default {
    components: { Modal, ModalError },
		data () {
			return {
        		step: 1,
				text: '',
				showModal: false,
				showModalError: false,
				user_type: 'all',
				errors: [],
				file: null,
				dragging: false,
				steps: [
					{
						id: 1,
						text: 'Etape 1'
					},
					{
						id: 2,
						text: 'Etape 2'
					},
					{
						id: 3,
						text: 'Etape 3'
					},
					{
						id: 4,
						text: 'Etape 4'
					}
				],
				colisChargement: false,
				formControl: {
					depart: false,
					arrivee: false,
					date: false,
					poids: false,
					taille: false,
					details: false,
					prix: false
				},
				errors: [],
				successMsg: [],
				categories: [],
				chargement: false,
				colisForm: {
					depart: this.$route.params.depart,
					arrivee: this.$route.params.arrivee,
					date: null,
					poids: null,
					taille: null,
					details: null,
					prix: null
				},
				model: 1,
				tailles: [
					{
					id: 1,
					titre: 'Toute Taille',
					text: 'Accepter tout type de colis à expédier',
					},
					{
					id: 2,
					titre: 'Taille S',
					text: 'Tient dans une boite à chaussures (téléphone, clés, doudou...)',
					},
					{
					id: 3,
					titre: 'Taille M',
					text: 'Tient dans une valise cabine (ordinateur, caisse de vin, platine vinyle...)',
					},
					{
					id: 4,
					titre: 'Taille L',
					text: 'C\'est l\'équivalent de 4 petites valises cabine Tient dans le coffre d\'une voiture (tableau, télévision, lit parapluie...)',
					},
					{
					id: 5,
					titre: 'Taille XL',
					text: 'Tient dans un break ou un monospace (commode, fauteuil, table basse...)',
					},
					{
					id: 6,
					titre: 'Taille XXL',
					text: 'Nécessite un petit utilitaire (scooter, armoire, canapé, lit...)',
					},
				],
			}
		},
		computed: {
			stepperProgress () {
				return ( 100 / 3) * (this.step - 1) + '%'
			},
		},
		created () {
			if (localStorage.getItem('coliturageToken') === '') {
				this.$router.push('/')
			}
			this.scrollToTop()
			this.getCategorie()
            this.getTrajet()
		},
		mounted() {
			// Membership Add More
			const link = apiKey;

			let googleScript = document.querySelector(`script[src="${link}"]`);

			if (googleScript) {
                console.log('exist')
				const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
				const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.colisForm.depart = autocompleteorigin.getPlace().formatted_address
					// console.log(this.colisForm.depart)
				});

				autocompletedestination.addListener("place_changed", () => {
					this.colisForm.arrivee = autocompletedestination.getPlace().formatted_address
					// console.log(this.colisForm.arrivee)
				});
			}

			var d = new Date()

			var today = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2)
			document.getElementById("date").setAttribute("min", today);

			googlePlaces(link).then((result) => {
				console.log('success')
				const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
				const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.colisForm.depart = autocompleteorigin.getPlace().formatted_address
					// console.log(this.colisForm.depart)
				});

				autocompletedestination.addListener("place_changed", () => {
					this.colisForm.arrivee = autocompletedestination.getPlace().formatted_address
					// console.log(this.colisForm.arrivee)
				});
			})

			this.scrollToTop()

			$(".membership-info").on('click','.trash', function () {
					$(this).closest('.membership-cont').remove();
					return false;
				});

				$(".add-membership").on('click', function () {
					var membershipcontent = '<div class="row form-row membership-cont">' +
					'<div class="col-12 col-md-10 col-lg-6">' +
					'<div class="form-gr titre: ,oup">' +
					'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
					'</div>' +
					'</div>' +
					'<div class="col-12 col-md-2 col-lg-2">' +
					'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
					'</div>' +
					'</div>';
					$(".membership-info").append(membershipcontent);
					return false;
				});
		},
		methods: {
			onChange: function (e) {
				var files = e.target.files || e.dataTransfer.files

				if (!files.length) {
				this.dragging = false
				return
				}

				this.createFile(files[0])
			},
            getTrajet: function () {
                axios.get(`trajet/${this.$route.params.id}`)
                .then((response) => {
                    this.colisForm = response.data
                    this.getUser()
                })
                .catch((error) => {
                    console.log(error)
                })
            },
			createFile: function (file) {
				if (!file.type.match('image.*')) {
				this.messageSnackbar = 'Veuillez sélectionner un fichier image'
				this.snackbar = true
				this.dragging = false
				return
				}

				if (file.size > 10000000) {
				this.messageSnackbar = 'Veuillez sélectionner un fichier de moins de 10 MB.'
				this.snackbar = true
				this.dragging = false
				return
				}

				this.file = file
				this.dragging = false
			},
			removeFile: function () {
				this.file = null
			},
			scrollToTop () {
            	window.scrollTo(0, 0);
        	},
			getCategorie: function () {
				axios.get('categories')
					.then((response) => {
						this.categories = response.data
					})
					.catch((error) => {
						console.log(error)
					})
			},
			stepperControl: function () {
				this.stepperControlReset;
				let loop = false

				if (this.step === 1) {
					if (!this.colisForm.depart || this.colisForm.depart === 'Départ') {
						this.formControl.depart = true;
						loop = true
					}
					
					if (!this.colisForm.arrivee || this.colisForm.arrivee === 'Arrivée') {
						this.formControl.arrivee = true;
						loop = true
					}
					
					if (!this.colisForm.date) {
						this.formControl.date = true;
						loop = true
					}

					if (!this.colisForm.prix) {
						this.formControl.prix = true;
						loop = true
					}
				}

				if (this.step === 2) {
					if (!this.colisForm.taille) {
						this.formControl.taille = true;
						loop = true
					}
				}

				if (this.step === 3) {
					if (!this.colisForm.details) {
						this.formControl.details = true;
						loop = true
					}
					if (!this.colisForm.poids) {
						this.formControl.poids = true;
						loop = true
					}
				}

				if (!loop) {
					this.step++
					this.scrollToTop()
				}
			},
			saveColis: function () {
				this.errors = [];
				this.registerSuccessMsg = [];
				let loop = false

                if (!this.colisForm.photo) {
                    if (!this.file) {
                        this.errors.push("Veuillez renseigner une image");
                        loop = true
                    }
                }
				
				if (loop) {
					return loop
				}
				
				if (!loop) {
					this.colisChargement = true
					const fileData = new FormData()
					fileData.append('date', this.colisForm.date)
					fileData.append('poids', this.colisForm.poids)
					fileData.append('taille', this.colisForm.taille)
					fileData.append('prix', this.colisForm.prix)
					fileData.append('details', this.colisForm.details)
					fileData.append('depart', this.colisForm.depart)
					fileData.append('arrivee', this.colisForm.arrivee)
					fileData.append('id', this.colisForm.id)
					fileData.append('photo', this.colisForm.photo)
					fileData.append('file', this.file)
					
					axios.post(`trajeturage/${this.colisForm.id}`, fileData, {
						headers: {
							'content-type': 'multipart/form-data',
						},
					})
					.then((response) => {
						this.text = 'Trajet modifié avec success!'
						this.showModal = true
						window.history.back()
						this.colisChargement = false
					})
					.catch((error) => {
						this.text = error.response.data.message
						this.showModalError = true
						this.colisChargement = false
					})
				}
			},
			CompareDate: function (date) {    
				var todayDate = new Date(); //Today Date    
				var dateOne = new Date(date);    
				if (todayDate > dateOne) {    
					alert("Today Date is greater than Date One.");    
				}else {    
					alert("Date One is greater than Today Date.");    
				}    
			},
			completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                })
            },
            // Executed when @active-step event is triggered
            isStepActive(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if(step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            },
            // Executed when @stepper-finished event is triggered
            alert(payload) {
                alert('end')
            }
		},
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>
