<template>
	<div class="main-wrapper2">
        <div>
			<ModalError v-if="showModalError" @close="showModalError = false" :text="text">
			</ModalError>
		</div>
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-3 col-xl-3 col-md-4 theiaStickySidebar">
						<div class="navbar-header">
							<a id="mobile_btn2" href="javascript:void(0);">
								<h2 style="color: rgb(0, 175, 245)">
									<i class="fa fa-filter" aria-hidden="true"></i>
									Filtrer
								</h2>
							</a>
						</div>
						<div class="main-menu-wrapper2">
							<div class="menu-header2 text-center">
								<h4 style="color: rgb(0, 175, 245)"><b>Filtrer</b></h4>
								<a id="menu_close2" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
							</div>
							<div class="stickyside">
								<div class="card filter-card">
									<div class="card-body">
										<form id="search_form">
											<div class="row align-items-left mb-4">
												<div class="col-md-7 col">
													<h4>Filtre</h4>
												</div>
												<div class="col-md-5 col-auto align-items-right">
													<button type="button" class="close btn-primary" @click="getTrajets('trajet-paginate'),errors = []" aria-label="Close" style="border-radius: 20px">
														<span aria-hidden="true">Rétablir</span>
													</button>
												</div>
											</div>
											<div class="filter-widget">
												<div class="filter-list">
													<h4 class="filter-title">Départ <span class="text-danger">*</span></h4>
													<input ref="origin" class="form-control" placeholder="Ville de départ" v-model="form.depart">
												</div>
												<div class="filter-list">
													<h4 class="filter-title">Arrivée <span class="text-danger">*</span></h4>
													<input ref="destination" class="form-control" placeholder="Ville d'arrivée'" v-model="form.arrivee">
												</div>
												<div class="filter-list">
													<h4 class="filter-title">Date du trajet <span class="text-danger">*</span></h4>
													<input class="form-control" type="date" id="date" v-model="form.date" name="date">
												</div>
											</div>
											<div v-if="spinner" class="half-circle-spinner" style="margin-left: auto;">
												<div class="circle circle-1"></div>
												<div class="circle circle-2"></div>
											</div>
											<button v-else class="btn btn-primary pl-5 pr-5 btn-block get_services w-100" @click="getFilter('trajet-filtre')" type="button" style="border-radius: 20px">Rechercher</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9 col-xl-9 col-md-8" style="min-height: 600px">
						<div class="row align-items-center mb-4">
							<div class="col-md-6 col text-end">
								<h4><span>{{trajets.length}}</span> Trajet(s)</h4>

								<div v-if="spinner2" class="half-circle-spinner" style="margin-left: auto;">
									<div class="circle circle-1"></div>
									<div class="circle circle-2"></div>
								</div>
							</div>
							<div class="col-auto float-end ms-auto breadcrumb-menu">
								<nav aria-label="breadcrumb" class="page-breadcrumb">
									<ol class="breadcrumb">
										<li class="breadcrumb-item">
											<router-link to="/index">Accueil</router-link>
										</li>
										<li class="breadcrumb-item active" aria-current="page">Tous les trajets</li>
									</ol>
								</nav>
							</div>
						</div>
						<div>
							<div class="row">
								<div v-for="trajet in trajets" :key="trajet.id" class="col-lg-4 col-md-6">
									<div class="service-widget animate__animated animate__fadeIn">
										<div class="service-content">
											<h6 class="user-info">
												<div class="row mb-1">
													<div class="col-auto ser-contact cate-list">
														<router-link class="bg-yellow" :to="'/trajet-details/'+ trajet.id + '/' + trajet.user_id">Le {{trajet.date_depart}}</router-link>
													</div>
													<div class="col ser-location">
														<span class="service-price ms-1">{{trajet.taille}}</span>
													</div>
												</div>
												<div class="">	
													<span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
														<span>{{MaskCharacter(trajet.depart, 30)}}</span>
													</span>
													<span class="col ser-location">
														<span>{{trajet.arrivee}}</span>
														<i class="fas fa-flag ms-1"></i>
													</span>
												</div>
											</h6>
											<div class="row">	
												<span class="col-12 d-inline-block average-rating text-right">Publier le {{trajet.date_publication}}  
												<router-link style="margin-left: 20px" :to="'/trajet-details/'+ trajet.id + '/' + trajet.user_id">Voir le trajet</router-link></span>
											</div>
										</div>
									</div>
								</div>
								<div v-if="trajets.length == 0 && !spinner2 && !spinner" class="text-center">
									<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
									<button  class="btn btn-primary" style="border-radius: 25px; margin-top: 10px;" @click="goTo('/publier-colis')">
										Publiez votre colis
									</button>
								</div>
							</div>
							<div v-if="(!spinner2 && !spinner) && trajets.length >= 30">
								<div v-if="filtre" class="pagination">
									<ul>
										<li v-if="firstPageUrl" :class=" firstPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.firstPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-left"></i></a></li>
										<li v-if="prevPageUrl" :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
										<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
										<li v-if="nextPageUrl" :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
										<li v-if="lastPageUrl" :class=" lastPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.lastPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-right"></i></a></li>
									</ul>
								</div>
								<div v-else class="pagination">
									<ul>
										<li v-if="firstPageUrl" :class=" firstPageUrl ?'arrow active' : 'arrow'" @click="getTrajets(this.firstPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-left"></i></a></li>
										<li v-if="prevPageUrl" :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getTrajets(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
										<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
										<li v-if="nextPageUrl" :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getTrajets(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
										<li v-if="lastPageUrl" :class=" lastPageUrl ?'arrow active' : 'arrow'" @click="getTrajets(this.lastPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-right"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { apiKey } from '../googleAPIKey'
  	import CardTrajet from './CardTrajet.vue'
	import ModalError from '../service/modalError.vue'
	import googlePlaces from '../googlePlaces'
	import 'regenerator-runtime/runtime'

	export default {
    	components: { CardTrajet, ModalError },
		data () {
      		return {
				trajets: [],
				errors: [],
				filtre: false,
				spinner: false,
				spinner2: false,
				showModalError: false,
				showModal: false,
				form: {
					ordre: 'Plus recents',
					taille: 'Toute Taille',
					depart: '',
					date: null,
					arrivee: '',
					mot: '',
				},
				text: '',
				itemsPerPage: null,
				page: null,
				numberOfPages: null,
				firstPageUrl: null,
				lastPageUrl: null,
				from: null,
				to: null,
				total: null,
				nextPageUrl: null,
				prevPageUrl: null,
				path: 'trajet-paginate'
			}
		},
		mounted() {
			if(this.$route.params.depart && this.$route.params.arrivee && this.$route.params.depart !== '' && this.$route.params.arrivee !== '') {
				this.form.depart = this.$route.params.depart
				this.form.arrivee = this.$route.params.arrivee
				this.form.date = this.$route.params.date
				this.filtre = true
				this.getFilter('trajet-filtre')
			} else {
				var d = new Date()
      			this.form.date = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2)
				this.getTrajets(this.path)
			}

			const link = apiKey;

			let googleScript = document.querySelector(`script[src="${link}"]`);

			if (googleScript) {
                console.log('exist')
				const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
				const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.form.depart = autocompleteorigin.getPlace().formatted_address
					// console.log(autocompleteorigin.getPlace())
				});

				autocompletedestination.addListener("place_changed", () => {
					this.form.arrivee = autocompletedestination.getPlace().formatted_address
					// console.log(autocompletedestination.getPlace())
				});
			}

			googlePlaces(link).then((result) => {
				console.log('success')
				const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
				const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.form.depart = autocompleteorigin.getPlace().formatted_address
					// console.log(autocompleteorigin.getPlace())
				});

				autocompletedestination.addListener("place_changed", () => {
					this.form.arrivee = autocompletedestination.getPlace().formatted_address
					// console.log(autocompletedestination.getPlace())
				});
			})

			var d = new Date()
			var today = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2)
			document.getElementById("date").setAttribute("min", today);
			
			if($(window).width() <= 991) {
            var Sidemenu = function() {
				this.$menuItem = $('.main-nav a');
			};

			function init() {
				var $this = Sidemenu;
				$('.main-nav a').on('click', function(e) {
					if($(this).parent().hasClass('has-submenu')) {
						e.preventDefault();
					}
					if(!$(this).hasClass('submenu')) {
						$('ul', $(this).parents('ul:first')).slideUp(350);
						$('a', $(this).parents('ul:first')).removeClass('submenu');
						$(this).next('ul').slideDown(350);
						$(this).addClass('submenu');
					} else if($(this).hasClass('submenu')) {
						$(this).removeClass('submenu');
						$(this).next('ul').slideUp(350);
					}
				});
                $('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			}
		    // Sidebar Initiate
            init();
            }
			// Mobile menu sidebar overlay
			$('.header-fixed2').append('<div class="sidebar-overlay2"></div>');
            $(document).on('click', '#mobile_btn2', function() {
                $('main-wrapper2').toggleClass('slide-nav2');
                $('.sidebar-overlay2').toggleClass('opened');
                $('html').addClass('menu-opened2');
                return false;
            });
            $(document).on('click', '.sidebar-overlay2', function() {
                $('html').removeClass('menu-opened2');
                $(this).removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
            });
            
            $(document).on('click', '#menu_close2', function() {
                $('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
            });

			$('.fav-btn .fav-icon').on('click', function () {
				$(this).toggleClass('favourite');
			});

					// Membership Add More

			$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});

		},
		methods: {
			goTo(url) {
				this.$router.push(url)
			},
			getTrajets: function (path) {
				this.spinner2 = true
				axios.get(path)
					.then((response) => {
						this.spinner2 = false
						this.filtre = false
						this.form.depart = ''
						this.form.arrivee = ''
						var d = new Date()
      					this.form.date = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate() + 1)).slice(-2)
						this.trajets = response.data.data
						this.itemsPerPage = response.data.per_page
						this.page = response.data.current_page
						this.numberOfPages = response.data.last_page
						this.firstPageUrl = response.data.first_page_url
						this.lastPageUrl = response.data.last_page_url
						this.from = response.data.from
						this.to = response.data.to
						this.total = response.data.total
						this.nextPageUrl = response.data.next_page_url
						this.prevPageUrl = response.data.prev_page_url
					})
					.catch((error) => {
						this.spinner2 = false
						console.log(error)
					})
				$('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			},
			MaskCharacter(str, n) {
				// Slice the string and replace with
				// mask then add remaining string
				if (str.length > n){
					return ('' + str).slice(0, n) + '...';
				} else {
					return str
				}
			},
			getFilter: function (path) {
				this.errors = [];
				
				if (!this.form.depart || !this.form.arrivee) {
					this.text = 'Veuillez renseigner une ville de départ et une ville d\'arrivée!'
					this.showModalError = true
					return
				}
				/* const today = new Date()
				const yesterday = new Date(today)

				yesterday.setDate(yesterday.getDate() - 1)
				var dateOne = new Date(this.form.date);    
				if (yesterday > dateOne) {    
					this.text = 'Veuillez renseigner une date suppérieure à la date du jour!'
					console.log('today', yesterday)
					console.log('day', dateOne)
					this.showModalError = true
					return  
				} */
				this.spinner = true
				axios.post(path, this.form)
				.then((response) => {
					this.filtre = true
					this.trajets = response.data.data
					this.itemsPerPage = response.data.per_page
					this.page = response.data.current_page
					this.numberOfPages = response.data.last_page
					this.firstPageUrl = response.data.first_page_url
					this.lastPageUrl = response.data.last_page_url
					this.from = response.data.from
					this.to = response.data.to
					this.total = response.data.total
					this.nextPageUrl = response.data.next_page_url
					this.prevPageUrl = response.data.prev_page_url
					this.spinner = false
					if (path === 'trajet-filtre' && this.trajets.length <= 0) {
						this.goTo('/publier-colis/'+this.form.depart+'/'+this.form.arrivee)
					}
				})
				.catch((error) => {
					this.spinner = false
					console.log(error)
				})
				$('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			}
		},
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>