<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-navadmin></layout-navadmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">All Notifications</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <div class="admin-noti-wrapper">
                        <div class="noti-list">
                            <div class="noti-avatar">
                                <img src="../../../assets/img/user.jpg" alt="">
                            </div>
                            <div class="noti-contents">
                                <h3>uiuii  have been subscribed</h3>
                                <span>31-08-2020 05:26 AM</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    export default {
        mounted() {

// radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});


        },
    }
</script>