<template>
	<div class="main-wrapper">
		<layout-headeradmin1></layout-headeradmin1>
        <layout-navadmin></layout-navadmin> 
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <div class="page-header">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="page-title">Payment Settings </h3>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->
                <ul class="nav nav-tabs menu-tabs">
                    <li class="nav-item ">
                        <router-link class="nav-link" to="/admin/payment-settings">Stripe</router-link>
                    </li>
                    <li class="nav-item active">
                        <router-link class="nav-link" to="/admin/razorpay">Razorpay</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/paypal">Paypal</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/paystack">Paystack</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/admin/cod">COD</router-link>
                    </li>
                </ul>
                <div class="row">
                    <div class=" col-lg-6 col-sm-12 col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-heads">
                                    <h4 class="card-title">Razorpay</h4>
                                    <div class="col-auto">
                                        <div class="status-toggle">
                                            <input id="razorpay" class="check" type="checkbox" checked="">
                                            <label for="razorpay" class="checktoggle">checkbox</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group mb-4">
                                    <label class="d-block">Razorpay Option </label>
                                    <div class="custom-control custom-radios custom-control-inline">
                                        <input class="custom-control-input" id="sandbox" type="radio" name="Razorpay" value="phpmail" checked>
                                        <label class="custom-control-label" for="sandbox">Sandbox</label>
                                    </div>
                                    <div class="custom-control custom-radios custom-control-inline">
                                        <input class="custom-control-input" id="live" type="radio" name="Razorpay" value="smtp" >
                                        <label class="custom-control-label" for="live">Live</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Gateway Name </label>
                                    <input type="text" class="form-control" value="Stripe">
                                </div>
                                <div class="form-group">
                                    <label>API Key</label>
                                    <input type="text" class="form-control" value="pk_test_AealxxOygZz84AruCGadsdWvUV00mJQZdLvr">
                                </div>
                                <div class="form-group">
                                    <label> Rest Key </label>
                                    <input type="text" class="form-control" value="sk_test_8HwqAWwBd4C4E77bgAOsdd1jUgk00hDlERgn3">
                                </div>
                                <div class="form-groupbtn">
                                    <a class="btn btn-update me-2">Save</a>
                                    <a class="btn btn-cancel">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</diV>
</template>

<script>
	export default {
		mounted() {

        // radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});

		},
	}
</script>