<template>
	<div class="main-wrapper">
		<layout-headeradmin1></layout-headeradmin1>
        <layout-navadmin></layout-navadmin> 
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <!-- Page Header -->
                <div class="page-header">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="page-title">General Settings</h3>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->
                
                <div class="row">
                    <div class=" col-lg-6 col-sm-12 col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-heads">
                                    <h4 class="card-title">Website Basic Details</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Website Name<span class="manidory">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Website Name">
                                </div>
                                <div class="form-group">
                                    <label>Contact Details <span class="manidory">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter contact details">
                                </div>
                                <div class="form-group">
                                    <label>Mobile Number <span class="manidory">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Mobile Number ">
                                </div>
                                <div class="form-group">
                                    <label>Currency<span class="manidory">*</span></label>
                                    <select class="form-control select">
                                        <option>US Dollar (USD)</option>
                                        <option>US Dollar (USD)</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Language<span class="manidory">*</span></label>
                                    
                                    <select class="form-control select">
                                        <option>English</option>
                                        <option>Dutch</option>
                                    </select>
                                </div>
                                <div class="form-group mb-5">
                                    <label>Service Location Radius</label>
                                    <div class="loan-set text-center my-2">
                                        <h3 ><span id="currencys"></span> km</h3>
                                    </div>
                                    <input type="range" min="0" max="8" value="4" class="slider" id="myRange">
                                </div>
                                <div class="form-groupbtn">
                                    <a class="btn btn-update me-2">Update</a>
                                    <a class="btn btn-cancel">Cancel</a>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <div class="card-heads">
                                    <h4 class="card-title">Placeholder Image Settings</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group ">
                                    <p class="settings-label">Service Placeholder <span class="manidory">*</span></p>
                                    <div class="settings-btn">
                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                        <label for="file" class="upload">
                                            <i class="fa fa-upload"></i>
                                        </label>
                                    </div>
                                    <h6 class="settings-size">Recommended image size is <span>800px x 600px</span></h6>
                                    <div class="upload-images">
                                        <img src="../../../assets/admin_img/services/service-13.png" alt="Image">
                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <p class="settings-label">Profile Placeholder<span class="manidory">*</span></p>
                                    <div class="settings-btn">
                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                        <label for="file" class="upload">
                                            <i class="fa fa-upload"></i>
                                        </label>
                                    </div>
                                    <h6 class="settings-size">Recommended image size is <span>150px x 150px</span></h6>
                                    <div class="upload-images upload-imagesprofile">
                                        <img src="../../../assets/admin_img/user1.png" alt="Image">
                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-lg-6 col-sm-12 col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-heads">
                                    <h4 class="card-title">Image Settings</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <p class="settings-label">Logo <span class="manidory">*</span></p>
                                    <div class="settings-btn">
                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                        <label for="file" class="upload">
                                            <i class="fa fa-upload"></i>
                                        </label>
                                    </div>
                                    <h6 class="settings-size">Recommended image size is <span>800px x 600px</span></h6>
                                    <div class="upload-images ">
                                        <img src="../../../assets/admin_img/logo1.png" class="my-4" alt="Image">
                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <p class="settings-label">Favicon <span class="manidory">*</span></p>
                                    <div class="settings-btn">
                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                        <label for="file" class="upload">
                                            <i class="fa fa-upload"></i>
                                        </label>
                                    </div>
                                    <h6 class="settings-size">Recommended image size is <span>16px x 16px or 32px x 32px</span></h6>
                                    <h6 class="settings-size"> Accepted formats: only png and icon</h6>
                                    <div class="upload-images upload-imagesprofile">
                                        <img src="../../../assets/admin_img/favicon.png" class=" bg-white w-auto" alt="Image">
                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <p class="settings-label">Icon <span class="manidory">*</span></p>
                                    <div class="settings-btn">
                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                        <label for="file" class="upload">
                                            <i class="fa fa-upload"></i>
                                        </label>
                                    </div>
                                    <h6 class="settings-size">Recommended image size is <span>100px x 100px </span></h6>
                                    <div class="upload-images upload-imagesprofile">
                                        <img src="../../../assets/admin_img/favicon.png" class=" bg-white w-auto" alt="Image">
                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="form-groupbtn">
                                    <a class="btn btn-update me-2">Update</a>
                                    <a class="btn btn-cancel">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</diV>
</template>

<script>
	export default {
		mounted() {

        // radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});

		},
	}
</script>