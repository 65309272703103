<template>
	<div class="main-wrapper2">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                        <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <h4 class="widget-title text-end">Dashboard</h4>
                        <div class="row">
                            <div class="col-lg-4">
                                <router-link to="/my-trajets" class="dash-widget dash-bg-1">
                                    <span class="dash-widget-icon">{{trajet}}</span>
                                    <div class="dash-widget-info">
                                        <span>Tous mes trajets</span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4">
                                <router-link to="/my-services" class="dash-widget dash-bg-2">
                                    <span class="dash-widget-icon">{{colis}}</span>
                                    <div class="dash-widget-info">
                                        <span>Tous mes colis</span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4">
                                <router-link to="/proposition-colis" class="dash-widget dash-bg-3">
                                    <span class="dash-widget-icon">{{propositionColis}}</span>
                                    <div class="dash-widget-info">
                                        <span style="font-size: 14px">Propositions de colis à transporter reçus</span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4">
                                <router-link to="/proposition-trajet" class="dash-widget dash-bg-4">
                                    <span class="dash-widget-icon">{{propositionTrajet}}</span>
                                    <div class="dash-widget-info">
                                        <span style="font-size: 14px">Propositions de transport de colis reçus</span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4">
                                <router-link to="/provider-payment" class="dash-widget dash-bg-5">
                                    <span class="dash-widget-icon">{{colisValidation}}</span>
                                    <div class="dash-widget-info">
                                        <span style="font-size: 16px">Mes expéditions de colis en cours</span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg-4">
                                <router-link to="/provider-colis-confirm" class="dash-widget dash-bg-6">
                                    <span class="dash-widget-icon">{{colisTransport}}</span>
                                    <div class="dash-widget-info">
                                        <span style="font-size: 16px">Mes Transports de colis en cours</span>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <!-- <div class="card mb-0">
                            <div class="row no-gutters">
                                <div class="col-lg-8">
                                    <div class="card-body">
                                        <h6 class="title">Plan Details</h6>
                                        <div class="sp-plan-name">
                                            <h6 class="title">
                                                Gold <span class="badge badge-success badge-pill">Active</span>
                                            </h6>
                                            <p>Subscription ID: <span class="text-base">100394949</span></p>
                                        </div>
                                        <ul class="row">
                                            <li class="col-6 col-lg-6">
                                                <p>Started On 15 Jul, 2020</p>
                                            </li>
                                            <li class="col-6 col-lg-6">
                                                <p>Price $1502.00</p>
                                            </li>
                                        </ul>
                                        <h6 class="title">Last Payment</h6>
                                        <ul class="row">
                                            <li class="col-sm-6">
                                                <p>Paid at 15 Jul 2020</p>
                                            </li>
                                            <li class="col-sm-6">
                                                <p><span class="text-success">Paid</span>  <span class="amount">$1502.00</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="sp-plan-action card-body">
                                        <div class="mb-2">
                                            <router-link to="/provider-subscription" class="btn btn-primary"><span>Change Plan</span></router-link>
                                        </div>
                                        <div class="next-billing">
                                            <p>Next Billing on <span>15 Jul, 2021</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>﻿
    </diV>
</template>

<script>
	import axios from 'axios'
    import Dashboard from './Dashboard.vue'
	export default {
        components: {
            Dashboard
        },
        data () {
			return {
				colis: 0,
                trajet: 0,
				propositionColis: 0,
                propositionTrajet: 0,
				colisValidation: 0,
				colisTransport: 0,		
            }
		},
		mounted() {

 // Membership Add More

            $(".membership-info").on('click','.trash', function () {
                $(this).closest('.membership-cont').remove();
                return false;
            });

            $(".add-membership").on('click', function () {
                var membershipcontent = '<div class="row form-row membership-cont">' +
                '<div class="col-12 col-md-10 col-lg-6">' +
                '<div class="form-group">' +
                '<input type="text" class="form-control" name="service_offered[]" id="field1">' +
                '</div>' +
                '</div>' +
                '<div class="col-12 col-md-2 col-lg-2">' +
                '<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
                '</div>' +
                '</div>';
                $(".membership-info").append(membershipcontent);
                return false;
            });
        },
        created () {
			this.getCount()
		},
		methods: {
			getCount: function () {
				axios.get('my-total')
				.then((response) => {
					this.colis = response.data.colis
					this.trajet = response.data.trajet
					this.propositionColis = response.data.propositionColis
					this.propositionTrajet = response.data.propositionTrajet
					this.colisValidation = response.data.colisValidation
					this.colisTransport = response.data.colisTransport
				})
				.catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>