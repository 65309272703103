<template>
		<div class="main-wrapper">
		<!-- <div class="breadcrumb-bar">
			<div class="container">
				<div class="row">
					<div class="col">
						<div class="breadcrumb-title">
							<h2>Reset Password</h2>
						</div>
					</div>
					<div class="col-auto float-end ms-auto breadcrumb-menu">
						<nav aria-label="breadcrumb" class="page-breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<router-link to="/index">Accueil</router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Reset Password</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div> -->
		<!-- /Breadcrumb -->
		
		<section class="contact-us">
			<div class="content">
				<div class="container">
					<div class="row">
						<div class="login-header">
							<h3>Reinitialisation du mot de passe</h3>
						</div>
						
						<Form  @submit="register()" :validation-schema="registerF" v-slot="{ errors }">
							<div class="form-group form-focus">
								<label class="focus-label">Email</label>
								<Field name="email" type="text" v-model="registerForm.email" class="form-control" :class="{ 'is-invalid': errors.email }" />
								<div class="invalid-feedback">{{errors.email}}</div>
							</div>
							<div class="form-group form-focus">
								<label class="focus-label">Nouveau Mot de Passe</label>
								<Field name="password" type="password" v-model="registerForm.password" class="form-control" :class="{ 'is-invalid': errors.password }" />
								<div class="invalid-feedback">{{errors.password}}</div>
							</div>
							<div class="form-group form-focus">
								<label class="focus-label">Confirmer Mot de Passe</label>
								<Field name="confirmPassword" type="password" v-model="registerForm.cPassword" class="form-control" :class="{ 'is-invalid': errors.confirmPassword }" />
								<div class="invalid-feedback">{{errors.confirmPassword}}</div>
							</div>
							<div class="d-grid">
								<button :disabled="registerChargement" class="btn btn-primary btn-block btn-lg login-btn" type="submit">
									<div v-if="registerChargement" class="half-circle-spinner">
										<div class="circle circle-1"></div>
										<div class="circle circle-2"></div>
									</div>
									<div v-else>
										Enregistrer
									</div>
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</section>
		<div>
			<ModalError v-if="showModalError" @close="showModalError = false" :text="text">
			</ModalError>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { Form, Field, defineRule, configure } from "vee-validate";
    import ModalError from '../layout/modalError.vue'
	import * as Yup from 'yup';
	export default {
		components: {
			Form,
			Field,
			ModalError,
		},
		data () {
			const registerF = Yup.object().shape({
				email: Yup.string()
					.required('L\'Email est obligatoire')
					.email('Email est invalide'),
				password: Yup.string()
					.min(6, 'Le mot de passe doit avoir minimum 6 caractères')
					.required('Le mot de passe est obligatoire'),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], 'les mots de de passe doivent être identiques')
					.required('Confirm Password est obligatoire')
			});
			return {
				registerF,
                showModalError: false,
                text: '',
				registerErrors: [],
				registerChargement: false,
				registerForm: {
					token: this.$route.params.token,
				},
				user: null,
			}
		},
		mounted() {
			document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			this.getUser()
		},
		methods: {
			register: function () {
				this.registerChargement = true
				axios.post('reset-password', this.registerForm)
					.then((response) => {
						axios.post('login', this.registerForm)
							.then((response) => {
								localStorage.setItem('coliturageToken', response.data.token)
								localStorage.setItem('coliturageUserData', JSON.stringify(response.data.user))
								window.location.reload()
								this.$router.push('/')
							})
							.catch((error) => {
								this.text = error.response.data.message
								this.showModalError = true
								this.registerChargement = false
							})
					})
					.catch((error) => {
						this.text = error.response.data.message
						this.showModalError = true
						this.registerChargement = false
					})
			},
			getUser: function () {
				axios.get(`get-token/${this.$route.params.token}`)
					.then((response) => {
						this.registerForm.email = response.data.email
					})
					.catch((error) => {
						// this.$router.push('/')
					})
			},
			validEmail: function (email) {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			}
		}
    }
</script>

<style lang="scss">
	@import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
	@import "./../scss/style.scss";
</style>