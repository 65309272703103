<template>
	<div class="main-wrapper2">
        <div>
			<ModalError v-if="showModalError" @close="showModalError = false" :text="text">
			</ModalError>
		</div>
		
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-3 col-xl-3 col-md-4 theiaStickySidebar">
						<div class="navbar-header">
							<a id="mobile_btn2" href="javascript:void(0);">
								<h2 style="color: rgb(0, 175, 245)">
									<i class="fa fa-filter" aria-hidden="true"></i>
									Filtrer
								</h2>
							</a>
						</div>
						<div class="main-menu-wrapper2">
							<div class="menu-header2 text-center">
								<h4 style="color: rgb(0, 175, 245)"><b>Filtrer</b></h4>
								<a id="menu_close2" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
							</div>
							<div class="stickyside">
								<div class="card filter-card">
									<div class="card-body">
										<form id="search_form">
											<div class="row align-items-left mb-4">
												<div class="col-md-7 col">
													<h4>Filtre</h4>
												</div>
												<div class="col-md-5 col-auto align-items-right">
													<button type="button" class="close btn-primary" @click="getColis('colis-paginate'), errors = []" aria-label="Close" style="border-radius: 20px">
														<span aria-hidden="true">Rétablir</span>
													</button>
												</div>
											</div>
											<div class="filter-widget">
												<div class="filter-list">
													<h4 class="filter-title">Classer par <span class="text-danger">*</span></h4>
													<select class="form-control selectbox select form-select" v-model="form.ordre">
														<!-- <option>Taille</option> -->
														<option>Prix du trajet</option>
														<option>Plus recents</option>
													</select>
												</div>
												<div class="filter-list">
													<h4 class="filter-title">Départ <span class="text-danger">*</span></h4>
													<input ref="origin" class="form-control" type="text" placeholder="Ville de départ" v-model="form.depart">
												</div>
												<div class="filter-list">
													<h4 class="filter-title">Arrivée <span class="text-danger">*</span></h4>
													<input ref="destination" class="form-control" type="text" placeholder="Ville d'arrivée" v-model="form.arrivee">
												</div>
											</div>
											<div v-if="spinner" class="half-circle-spinner text-center" style="margin-left: auto;">
												<div class="circle circle-1"></div>
												<div class="circle circle-2"></div>
											</div>
											<button v-else class="btn btn-primary pl-5 pr-5 btn-block get_services w-100" @click="getFilter('colis-filtre')" type="button" style="border-radius: 20px">Rechercher</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9 col-xl-9 col-md-8" style="min-height: 600px">
						<div class="row align-items-center mb-4">
							<div class="col-md-6 col text-end">
								<div v-if="spinner2" class="half-circle-spinner" style="margin-left: auto;">
									<div class="circle circle-1"></div>
									<div class="circle circle-2"></div>
								</div>
								<h4><span>{{colis.length}}</span> Colis trouvé(s)</h4>
							</div>
							<div class="col-auto float-end ms-auto breadcrumb-menu">
								<nav aria-label="breadcrumb" class="page-breadcrumb">
									<ol class="breadcrumb">
										<li class="breadcrumb-item">
											<router-link to="/index">Accueil</router-link>
										</li>
										<li class="breadcrumb-item active" aria-current="page">Tous les colis</li>
									</ol>
								</nav>
							</div>
						</div>
						<div>
							<div class="row">
								<div v-for="coli in colis" :key="coli.id" class="col-lg-3 col-md-4 col-sm-6">
									<CardProduct :colis="coli"></CardProduct>
								</div>
								<div v-if="colis.length == 0 && !spinner2 && !spinner" class="text-center">
									<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
									<button  class="btn btn-primary" style="border-radius: 25px; margin-top: 10px;" @click="goTo('/publier-trajet')">
										Publiez votre trajet
									</button>
								</div>
							</div>
							<div v-if="(!spinner2 && !spinner) && colis.length >= 30">
								<div v-if="filtre" class="pagination">
									<ul>
										<li v-if="firstPageUrl" :class=" firstPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.firstPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-left"></i></a></li>
										<li v-if="prevPageUrl" :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
										<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
										<li v-if="nextPageUrl" :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
										<li v-if="lastPageUrl" :class=" lastPageUrl ?'arrow active' : 'arrow'" @click="getFilter(this.lastPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-right"></i></a></li>
									</ul>
								</div>
								<div v-else class="pagination">
									<ul>
										<li v-if="firstPageUrl" :class=" firstPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.firstPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-left"></i></a></li>
										<li v-if="prevPageUrl" :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
										<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
										<li v-if="nextPageUrl" :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
										<li v-if="lastPageUrl" :class=" lastPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.lastPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-right"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>﻿
	</div>
</template>

<script>
	import axios from 'axios'
	import { apiKey } from '../googleAPIKey'
  	import CardProduct from './CardProduct.vue'
    import ModalError from '../service/modalError.vue'
	import googlePlaces from '../googlePlaces'
	import 'regenerator-runtime/runtime'

	export default {
    	components: { CardProduct, ModalError },
		data () {
      		return {
				colis: [],
				errors: [],
				test: '',
				filtre: false,
				spinner: false,
				spinner2: false,
				showModalError: false,
				text: '',
				form: {
					ordre: 'Plus recents',
					taille: 'Toute Taille',
					depart: null,
					arrivee: null,
					mot: '',
				},
				depart: null,
				itemsPerPage: null,
				page: null,
				numberOfPages: null,
				firstPageUrl: null,
				lastPageUrl: null,
				from: null,
				to: null,
				total: null,
				nextPageUrl: null,
				prevPageUrl: null,
				path: 'colis-paginate',
			}
		},
		created () {
			window.scrollTo(0, 0)
			this.getColis(this.path)
		},
		mounted() {
			const link = apiKey;
			
			let googleScript = document.querySelector(`script[src="${link}"]`);

			if (googleScript) {
                console.log('exist')
				const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
				const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.form.depart = autocompleteorigin.getPlace().formatted_address
					// console.log(this.form.depart)
				});

				autocompletedestination.addListener("place_changed", () => {
					this.form.arrivee = autocompletedestination.getPlace().formatted_address
					// console.log(this.form.arrivee)
				});
			}

			googlePlaces(link).then((result) => {
				console.log('success')
				const autocompleteorigin = new google.maps.places.Autocomplete(this.$refs["origin"]);
				const autocompletedestination = new google.maps.places.Autocomplete(this.$refs["destination"]);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.form.depart = autocompleteorigin.getPlace().formatted_address
					// console.log(this.form.depart)
				});

				autocompletedestination.addListener("place_changed", () => {
					this.form.arrivee = autocompletedestination.getPlace().formatted_address
					// console.log(this.form.arrivee)
				});
			})

			if($(window).width() <= 991) {
            var Sidemenu = function() {
				this.$menuItem = $('.main-nav a');
			};

			function init() {
				var $this = Sidemenu;
				$('.main-nav a').on('click', function(e) {
					if($(this).parent().hasClass('has-submenu')) {
						e.preventDefault();
					}
					if(!$(this).hasClass('submenu')) {
						$('ul', $(this).parents('ul:first')).slideUp(350);
						$('a', $(this).parents('ul:first')).removeClass('submenu');
						$(this).next('ul').slideDown(350);
						$(this).addClass('submenu');
					} else if($(this).hasClass('submenu')) {
						$(this).removeClass('submenu');
						$(this).next('ul').slideUp(350);
					}
				});
                $('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			}
		    // Sidebar Initiate
            init();
            }
			// Mobile menu sidebar overlay
			$('.header-fixed2').append('<div class="sidebar-overlay2"></div>');
            $(document).on('click', '#mobile_btn2', function() {
                $('main-wrapper2').toggleClass('slide-nav2');
                $('.sidebar-overlay2').toggleClass('opened');
                $('html').addClass('menu-opened2');
                return false;
            });
            $(document).on('click', '.sidebar-overlay2', function() {
                $('html').removeClass('menu-opened2');
                $(this).removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
            });
            
            $(document).on('click', '#menu_close2', function() {
                $('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
            });
			
			$('.fav-btn .fav-icon').on('click', function () {
				$(this).toggleClass('favourite');
			});

					// Membership Add More

			$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});

		},
		methods: {
			goTo(url) {
				this.$router.push(url)
			},
			getColis: function (path) {
				this.spinner2 = true
				axios.get(path)
					.then((response) => {
						this.spinner2 = false
						this.filtre = false
						this.form.depart = ''
						this.form.arrivee = ''
						this.colis = response.data.data
						this.itemsPerPage = response.data.per_page
						this.page = response.data.current_page
						this.numberOfPages = response.data.last_page
						this.firstPageUrl = response.data.first_page_url
						this.lastPageUrl = response.data.last_page_url
						this.from = response.data.from
						this.to = response.data.to
						this.total = response.data.total
						this.nextPageUrl = response.data.next_page_url
						this.prevPageUrl = response.data.prev_page_url
					})
					.catch((error) => {
						this.spinner2 = false
						console.log(error)
					})
					$('html').removeClass('menu-opened2');
					$('.sidebar-overlay2').removeClass('opened');
					$('main-wrapper2').removeClass('slide-nav2');
			},
			getFilter: function (path) {
				this.errors = [];
				
				if (!this.form.depart || !this.form.arrivee) {
					this.text = 'Veuillez renseigner une ville de départ et une ville d\'arrivée!'
					this.showModalError = true
					return
				}
				this.spinner = true
				axios.post(path, this.form)
				.then((response) => {
					this.filtre = true
					this.colis = response.data.data
					this.itemsPerPage = response.data.per_page
					this.page = response.data.current_page
					this.numberOfPages = response.data.last_page
					this.firstPageUrl = response.data.first_page_url
					this.lastPageUrl = response.data.last_page_url
					this.from = response.data.from
					this.to = response.data.to
					this.total = response.data.total
					this.nextPageUrl = response.data.next_page_url
					this.prevPageUrl = response.data.prev_page_url
					this.spinner = false
				})
				.catch((error) => {
					this.spinner = false
					console.log(error)
				})
				$('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			}
		},
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>