<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                        <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Wallet</h4>
                                        <div class="wallet-details">
                                            <span>Wallet Balance</span>
                                            <h3>$3885</h3>
                                            <div class="d-flex justify-content-between my-4">
                                                <div>
                                                    <p class="mb-1">Total Credit</p>
                                                    <h4>$5314</h4>
                                                </div>
                                                <div>
                                                    <p class="mb-1">Total Debit</p>
                                                    <h4>$1431</h4>
                                                </div>
                                            </div>
                                            <div class="wallet-progress-chart">
                                                <div class="d-flex justify-content-between">
                                                    <span>$1431</span>
                                                    <span>$5,314.00</span>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Withdraw</h4>
                                        <form action="#">
                                            <div class="form-group">
                                                <div class="input-group mb-3">
                                                    <label class="input-group-text display-5">$</label>
                                                    <input type="text" maxlength="4" class="form-control Withdraw-form" placeholder="00.00">
                                                </div>
                                            </div>
                                        </form>
                                        <div class="text-center mb-3">
                                            <h5 class="mb-3">OR</h5> 
                                            <ul class="list-inline mb-0">
                                                <li class="line-inline-item mb-0 d-inline-block me-1">
                                                    <a href="javascript:;" class="updatebtn">$50</a>
                                                </li>
                                                <li class="line-inline-item mb-0 d-inline-block">
                                                    <a href="javascript:;" class="updatebtn">$100</a>
                                                </li>
                                                <li class="line-inline-item mb-0 d-inline-block ms-1">
                                                    <a href="javascript:;" class="updatebtn">$150</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="javascript:void(0);" class="btn btn-primary btn-block withdraw-btn w-100">Withdraw</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <h4 class="mb-4">Recent Transactions</h4>
                        <div class="card transaction-table mb-0">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Date</th>
                                                <th>Wallet</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                                <th>Available</th>
                                                <th>Reason</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in providerwallet" :key="item.id">
                                                <td>{{item.sno}}</td>
                                                <td>{{item.date}}</td>
                                                <td>{{item.wallet}}</td>
                                                <td>{{item.credit}}</td>
                                                <td>{{item.debit}}</td>
                                                <td>{{item.available}}</td>
                                                <td>{{item.reason}}</td>
                                                <td><span class="badge bg-success-light">{{item.status}}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</diV>
</template>

<script>
     import providerwallet from '../../json/website/provider/providerwallet'
    import Dashboard from './Dashboard.vue'
	export default {
        components: {
            Dashboard
        },
        data() {
            return {
                providerwallet: providerwallet,
            }
        },
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>