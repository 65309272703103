<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                      <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <div class="row align-items-center mb-4">
                            <div class="col">
                                Proposition(s) de colis à transporter pour le trajet :
                                <h4 class="widget-title mb-0"><i class="fas fa-location-arrow"></i> {{depart}} - {{arrivee}}</h4>
                            </div>
                        </div>
                        <div id="dataList">
                            <div v-for="coli in colis" :key="coli.id" class="">
								<CardBookingT :booking="coli"></CardBookingT>
							</div>
							<div v-if="colis.length == 0">
								<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
	</div>
</template>

<script>
	import axios from 'axios'
    import Dashboard from './Dashboard.vue'
  	import CardBookingT from './CardBookingT.vue'
	export default {
        components: {
            Dashboard,
			CardBookingT
        },
		data () {
			return {
				depart: this.$route.params.depart,
				arrivee: this.$route.params.arrivee,
                colis: []
			}
		},
        created () {
            window.scrollTo(0, 400);
            this.getColis()
        },
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
        methods: {
            getColis: function () {
                axios.get(`trajet-colis/${this.$route.params.id}`)
                .then((response) => {
                    this.colis = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
            },
        }
	}
</script>