<template>
		<div class="main-wrapper">
      <div class="content">
			<div class="container">
				<div class="row">
					<div class="col-xl-3 col-md-4 theiaStickySidebar">
                        <Dashboard />
					</div>
					<div class="col-xl-9 col-md-8">
						<h4 class="widget-title  text-end">Mes colis</h4>
						<NavService :actif="tab"/>
						<div class="container">
							<div class="row">
								<div v-for="coli in colis" :key="coli.id" class="col-lg-4 col-md-6">
									<CardProduct :colis="coli"></CardProduct>
								</div>
								<div v-if="colis.length == 0">
									<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
								</div>

								<!-- Pagination Links --> 
								<div v-if="colis.length >= 30" class="pagination">
									<ul>
										<li v-if="firstPageUrl" :class=" firstPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.firstPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-left"></i></a></li>
										<li v-if="prevPageUrl" :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
										<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
										<li v-if="nextPageUrl" :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
										<li v-if="lastPageUrl" :class=" lastPageUrl ?'arrow active' : 'arrow'" @click="getColis(this.lastPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-right"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header"> 
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
						</button>
					</div> 
					<div class="modal-footer">	<a href="javascript:;" class="btn btn-success si_accept_confirm">Yes</a>
						<button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Cancel</button>
					</div>
				</div>
			</div>
		</div>
		
		<div class="modal fade" id="deleteNotConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="acc_title">Delete Service</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>Service is Booked and Inprogress..</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">OK</button>
					</div>
				</div>
			</div>
		</div>﻿
		</diV>
</template>

<script>
	import axios from 'axios'
    import Dashboard from './../provider/Dashboard.vue'
  	import CardProduct from './CardProduct3.vue'
  	import NavService from './NavService.vue'
	export default {
        components: {
            Dashboard,
			CardProduct,
			NavService,
        },
		mounted() {
 			// Membership Add More

			$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});
		},
		data () {
			return {
				colis: [],
				tab: 'tab2',
				itemsPerPage: null,
				page: null,
				numberOfPages: null,
				firstPageUrl: null,
				lastPageUrl: null,
				from: null,
				to: null,
				total: null,
				nextPageUrl: null,
				prevPageUrl: null,
				path: 'my-colis/passe',
			}
		},
		created () {
			this.getColis(this.path)
		},
		methods: {
			getColis: function (path) {
				axios.get(path)
				.then((response) => {
					this.colis = response.data.data
					this.itemsPerPage = response.data.per_page
					this.page = response.data.current_page
					this.numberOfPages = response.data.last_page
					this.firstPageUrl = response.data.first_page_url
					this.lastPageUrl = response.data.last_page_url
					this.from = response.data.from
					this.to = response.data.to
					this.total = response.data.total
					this.nextPageUrl = response.data.next_page_url
					this.prevPageUrl = response.data.prev_page_url
				})
				.catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>