<template id="modal-template">
    <transition name="modal">
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container">
            <!-- <div class="modal-header">
                <slot name="header">
                    Succès!
                </slot>
            </div> -->
            <h6>Opération effectué avec succès!</h6>

            <div class="modal-body">
            <slot name="body">
                {{ text }}
            </slot>
            </div>

            <div class="modal-footer">
                <slot name="footer">
                    <button class="Btb" @click="$emit('close'), goTo()">
                        OK
                    </button>
                </slot>
            </div>
        </div>
        </div>
    </div>
    </transition>
</template>

<script>
export default {
    props: {
      text: String,
    },
    methods: { 
        goTo () {
            window.location.reload()
        },
    }
}
</script>


<style lang="scss">
    @import "./../scss/modal.scss";
</style>

<style>
.Btb {
    width: 100%;
    border: 1px solid rgb(0, 175, 245);
    background: rgb(0, 175, 245);
    border-radius: 20px;
	color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 5px;
}
</style>