<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-navadmin></layout-navadmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Services</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <!-- Search Filter -->
                    <form action="#" method="post" id="filter_inputs">
                        <div class="card filter-card">
                            <div class="card-body pb-0">
                                <div class="row filter-row">
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>Service</label>
                                            <input class="form-control" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>From Date</label>
                                            <div class="cal-icon">
                                                <input class="form-control datetimepicker" type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>To Date</label>
                                            <div class="cal-icon">
                                                <input class="form-control datetimepicker" type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <button class="btn btn-primary btn-block" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- /Search Filter -->
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Service</th>
                                                    <th>Location</th>
                                                    <th>Category</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td> 
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-01.jpg" alt=""> Toughened Glass Fitting Services
                                                        </router-link>
                                                    </td>
                                                    <td>Wayne, New Jersey</td>
                                                    <td>Cleaning</td>
                                                    <td>$25</td>
                                                    <td>13 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_1" class="check" type="checkbox">
                                                            <label for="service_1" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-02.jpg" alt=""> Car Repair Services
                                                        </router-link>
                                                    </td>
                                                    <td>Hanover, Maryland</td>
                                                    <td>Automobile</td>
                                                    <td>$50</td>
                                                    <td>12 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_2" class="check" type="checkbox" checked>
                                                            <label for="service_2" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-03.jpg" alt=""> Electric Panel Repairing Service
                                                        </router-link>
                                                    </td>
                                                    <td>Kalispell, Montana</td>
                                                    <td>Electrical</td>
                                                    <td>$45</td>
                                                    <td>11 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_3" class="check" type="checkbox">
                                                            <label for="service_3" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-04.jpg" alt=""> Steam Car Wash
                                                        </router-link>
                                                    </td>
                                                    <td>Electra, Texas</td>
                                                    <td>Car Wash</td>
                                                    <td>$14</td>
                                                    <td>10 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_4" class="check" type="checkbox" checked>
                                                            <label for="service_4" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-05.jpg" alt=""> House Cleaning Service
                                                        </router-link>
                                                    </td>
                                                    <td>Sylvester, Georgia</td>
                                                    <td>Cleaning</td>
                                                    <td>$100</td>
                                                    <td>9 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_5" class="check" type="checkbox" checked>
                                                            <label for="service_5" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-06.jpg" alt=""> Computer & Server AMC Service
                                                        </router-link>
                                                    </td>
                                                    <td>Los Angeles, California</td>
                                                    <td>Computer</td>
                                                    <td>$80</td>
                                                    <td>8 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_6" class="check" type="checkbox">
                                                            <label for="service_6" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-07.jpg" alt=""> Interior Designing
                                                        </router-link>
                                                    </td>
                                                    <td>Hanover, Maryland</td>
                                                    <td>Interior</td>
                                                    <td>$5</td>
                                                    <td>7 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_7" class="check" type="checkbox" checked>
                                                            <label for="service_7" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-08.jpg" alt=""> Building Construction Services
                                                        </router-link>
                                                    </td>
                                                    <td>Burr Ridge, Illinois</td>
                                                    <td>Construction</td>
                                                    <td>$75</td>
                                                    <td>6 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_8" class="check" type="checkbox" checked>
                                                            <label for="service_8" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-09.jpg" alt=""> Commercial Painting Services
                                                        </router-link>
                                                    </td>
                                                    <td>Huntsville, Alabama</td>
                                                    <td>Painting</td>
                                                    <td>$500</td>
                                                    <td>5 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_9" class="check" type="checkbox" checked>
                                                            <label for="service_9" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-10.jpg" alt=""> Plumbing Services
                                                        </router-link>
                                                    </td>
                                                    <td>Richmond, Virginia</td>
                                                    <td>Plumbing</td>
                                                    <td>$150</td>
                                                    <td>4 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_10" class="check" type="checkbox" checked>
                                                            <label for="service_10" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-11.jpg" alt=""> Wooden Carpentry Work
                                                        </router-link>
                                                    </td>
                                                    <td>Columbus, Alabama</td>
                                                    <td>Carpentry</td>
                                                    <td>$32</td>
                                                    <td>3 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_11" class="check" type="checkbox" checked>
                                                            <label for="service_11" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>
                                                        <router-link to="/admin/service-details">
                                                            <img class="rounded service-img me-1" src="../../assets/admin_img/services/service-12.jpg" alt=""> Air Conditioner Service
                                                        </router-link>
                                                    </td>
                                                    <td>Vancouver, Washington</td>
                                                    <td>Appliance</td>
                                                    <td>$54</td>
                                                    <td>2 Sep 2020</td>
                                                    <td>
                                                        <div class="status-toggle">
                                                            <input id="service_12" class="check" type="checkbox" checked>
                                                            <label for="service_12" class="checktoggle">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link to="/admin/service-details" class="btn btn-sm bg-info-light">
                                                            <i class="far fa-eye me-1"></i> View
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    export default {
        mounted() {

// radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});


        },
    }
</script>