<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                        <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <h4 class="widget-title">Reviews</h4>
                        <div class="card review-card mb-0">
                            <div class="card-body">
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-08.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Building Construction Services</a></h5>
                                        <div class="review-date">July 11, 2020 11:38 am</div>
                                        <p class="mb-2">Good Work</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-01.jpg" alt=""> Jeffrey Akridge
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <span class="d-inline-block average-rating">(5)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-09.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Commercial Painting Services</a></h5>
                                        <div class="review-date">July 05, 2020 15:33 pm</div>
                                        <p class="mb-2">Best Work</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-02.jpg" alt=""> Nancy Olson
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <span class="d-inline-block average-rating">(5)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-10.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Plumbing Services</a></h5>
                                        <div class="review-date">June 29, 2020 05:04 am</div>
                                        <p class="mb-2">Excellent Service</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-03.jpg" alt=""> Ramona Kingsley
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-11.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Wooden Carpentry Work</a></h5>
                                        <div class="review-date">June 26, 2020 02:22 am</div>
                                        <p class="mb-2">Thanks</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-04.jpg" alt=""> Ricardo Lung
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <span class="d-inline-block average-rating">(5)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-12.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Air Conditioner Service</a></h5>
                                        <div class="review-date">June 13, 2020 17:38 pm</div>
                                        <p class="mb-2">Amazing</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-05.jpg" alt=""> Annette Silva
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-01.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Toughened Glass Fitting Services</a></h5>
                                        <div class="review-date">June 10, 2020 17:18 pm</div>
                                        <p class="mb-2">Great!</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-06.jpg" alt=""> Stephen Wilson
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <span class="d-inline-block average-rating">(5)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-02.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Car Repair Services</a></h5>
                                        <div class="review-date">June 10, 2020 14:25 pm</div>
                                        <p class="mb-2">Good Support</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-07.jpg" alt=""> Ryan Rodriguez
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                                <!-- Review -->
                                <div class="review-list">
                                    <div class="review-img">
                                        <img class="rounded img-fluid" src="../../assets/img/services/service-03.jpg" alt="">
                                    </div>
                                    <div class="review-info">
                                        <h5><a href="">Electric Panel Repairing Service</a></h5>
                                        <div class="review-date">June 09, 2020 06:13 am</div>
                                        <p class="mb-2">Goooodddd!!</p>
                                        <div class="review-user">
                                            <img class="avatar-xs rounded-circle" src="../../assets/img/customer/user-08.jpg" alt=""> Lucile Devera
                                        </div>
                                    </div>
                                    <div class="review-count">
                                        <div class="rating mapgridrating1">
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star filled"></i>
                                            <i class="fas fa-star"></i>
                                            <span class="d-inline-block average-rating">(4)</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Review -->
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</diV>
</template>

<script>
    import Dashboard from './Dashboard.vue'
	export default {
        components: {
            Dashboard
        },
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>