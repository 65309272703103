<template>
	<div class="main-wrapper">
		<layout-headerbookings></layout-headerbookings>
        <div class="content">
            <div class="container">
                <div class="row"> 
                    <div class="col-xl-3 col-md-4">
                        <div class="mb-4">
                            <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                                <img alt="profile image" src="../../assets/img/customer/user-01.jpg" class="avatar-lg rounded-circle">
                                <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                                    <h6 class="mb-0">Jeffrey Akridge</h6>
                                    <p class="text-muted mb-0">Member Since Apr 2020</p>
                                </div>
                            </div>
                        </div>
                        <div class="widget settings-menu">
                            <ul role="tablist" class="nav nav-tabs">
                                <li class="nav-item current">
                                    <router-link to="/user-dashboard" class="nav-link">
                                        <i class="fas fa-chart-line"></i> <span>Dashboard</span>
                                    </router-link>
                                </li>
                                <li class="nav-item current">
                                    <router-link to="/favourites" class="nav-link">
                                        <i class="fas fa-heart"></i> <span>Favourites</span>
                                    </router-link>
                                </li>
                                <li class="nav-item current">
                                    <router-link to="/user-bookings" class="nav-link">
                                        <i class="far fa-calendar-check"></i> <span>My Bookings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-settings" class="nav-link">
                                        <i class="far fa-user"></i> <span>Profile Settings</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-wallet" class="nav-link active">
                                        <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-reviews" class="nav-link">
                                        <i class="far fa-star"></i> <span>Reviews</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/user-payment" class="nav-link">
                                        <i class="fas fa-hashtag"></i> <span>Payment</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Wallet</h4>
                                        <div class="wallet-details">
                                            <span>Wallet Balance</span>
                                            <h3>$872.1</h3>
                                            <div class="d-flex justify-content-between my-4">
                                                <div>
                                                    <p class="mb-1">Total Credit</p>
                                                    <h4>$29,720.80</h4>
                                                </div>
                                                <div>
                                                    <p class="mb-1">Total Debit</p>
                                                    <h4>$29,258.00</h4>
                                                </div>
                                            </div>
                                            <div class="wallet-progress-chart">
                                                <div class="d-flex justify-content-between">
                                                    <span>$29258</span>
                                                    <span>$29,720.80</span>
                                                </div>
                                                <div class="progress mt-1">
                                                    <div class="progress-bar bg-theme" role="progressbar" aria-valuenow="41" aria-valuemin="0" aria-valuemax="100" style="width:98%">98.44%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Add Wallet</h4>
                                        <form action="#">
                                            <div class="form-group">
                                                <div class="input-group mb-3">
                                                        <label class="input-group-text display-5">$</label>
                                                    
                                                    <input type="text" maxlength="4" class="form-control" name="wallet_amt" id="wallet_amt" placeholder="00.00">
                                                </div>
                                            </div>
                                        </form>
                                        <div class="text-center mb-3">
                                            <h5 class="mb-3">OR</h5>
                                            <ul class="list-inline mb-0">
                                                <li class="line-inline-item mb-0 d-inline-block me-1">
                                                    <a href="javascript:;" class="updatebtn">$50</a>
                                                </li>
                                                <li class="line-inline-item mb-0 d-inline-block">
                                                    <a href="javascript:;" data-amount="100" class="updatebtn">$100</a>
                                                </li>
                                                <li class="line-inline-item mb-0 d-inline-block ms-1">
                                                    <a href="javascript:;" data-amount="150" class="updatebtn">$150</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="javascript:void(0);" class="btn btn-primary btn-block withdraw-btn w-100">Add to Wallet</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="mb-4">Wallet Transactions</h4>
                        <div class="card transaction-table mb-0">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Date</th>
                                                <th>Wallet</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                                <th>Txt amt</th>
                                                <th>Available</th>
                                                <th>Reason</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in userwallet" :key="item.id">
                                                <td>{{item.sno}}</td>
                                                <td>{{item.date}}</td>
                                                <td>{{item.wallet}}</td>
                                                <td>{{item.credit}}</td>
                                                <td>{{item.debit}}</td>
                                                <td>{{item.txtamt}}</td>
                                                <td>{{item.available}}</td>
                                                <td>{{item.reason}}</td>
                                                <td><span class="badge bg-danger-light">Debit</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<layout-footer></layout-footer>
	</diV>
</template>

<script>
     import userwallet from '../../json/website/user/userwallet'
	export default {
        data() {
            return {
                userwallet: userwallet,
            }
        },
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>