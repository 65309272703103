<template>
    <div class="bookings">
        <div class="booking-list">
            <div class="booking-widget">
                <!-- <router-link :to="'/trajet-details/' + this.booking.trajet_id" class="booking-img">
                    <img :src="booking.photo" alt="User Image">
                </router-link> -->
                <div class="booking-det-info">
                    <h3>
                        {{booking.nom}} <router-link :to="'/trajet-details/' + this.booking.trajet_id"> (Voir le trajet <i class="far fa-eye"></i>)</router-link>
                    </h3>
                    <ul class="booking-details">
                        <li>
                            <span>Soumis le</span>{{booking.date_publication}}
                        </li>
                        <li><span>Taille</span> {{booking.taille}}</li>
                        <li><span>Poids</span> {{booking.poids}} kg</li>
                        <li><span>Montant</span> €{{booking.prix}}</li>
                    </ul>
                </div>
            </div>
            <div class="booking-action">
                <a v-if="booking.accepte != 'OUI' && booking.accepte != 'NON'" href="javascript:;" class="btn btn-sm bg-warning-light"><i class="far fa-eye"></i> En attente</a>
                <a v-if="booking.accepte == 'OUI'" href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Colis accepté</a>
                <a v-if="booking.accepte == 'NON' || booking.statut == 'Refusé'" href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Colis refusé</a>
                <a v-if="booking.expedie == 'OUI'" href="javascript:;" class="btn btn-sm bg-info-light"><i class="fas fa-check"></i> Colis livré</a>
                <!-- <router-link to="/user-chat" class="btn btn-sm bg-info-light"><i class="far fa-eye"></i> Chat</router-link>
                <a href="javascript:;" class="btn btn-sm bg-danger-light" data-toggle="modal" data-target="#myCancel"><i class="fas fa-times"></i> Cancel the Service</a>
                
                <button type="button" data-id="542" class="btn btn-sm bg-default-light"><i class="fas fa-info-circle"></i> Reason</button> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      booking: Object,
    },
    methods: {
        MaskCharacter(str, mask, n = 1) {
            // Slice the string and replace with
            // mask then add remaining string
            return ('' + str).slice(0, -n)
                .replace(/./g, mask)
                + ('' + str).slice(-n);
        },
        reload () {
            if (this.$route.name === 'trajet-details') {
                sessionStorage.setItem('urltransition', '/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
            }
        }
    }
}
</script>

<style>

</style>