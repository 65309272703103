<template>
    <div class="main-wrapper">
		<div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="service-view">
                            <div class="service-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h1>Départ le {{trajet.date_depart}}</h1>
                                    <div class="fav-btn fav-btn-big">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                </div>
                                <div class="service-cate">
                                    <h3>Contre proposition du transporteur : <b>€ {{trajet.prix}}</b></h3>
                                </div>
                            </div>
                            <div class="animate__animated animate__fadeIn mb-0" style="text-align: center; margin-bottom: 20px;">
                                <a :href="trajet.photo">
                                    <img :src="trajet.photo" alt="" class="img-fluid">
                                </a>
                            </div>
                            <div class="service-details">
                                <h5 id="pills-home-tab">Détails du Trajet</h5>
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="card service-description">
                                            <div class="card-body">
                                                <p class="mb-0">{{trajet.details}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elHide">
                        </div>
                        </div>
                    <div class="col-lg-4 theiaStickySidebar">
                        <div class="stickyside">
                            <div class="sidebar-widget widget">
                                <div class="service-amount">
                                    <span> {{trajet.taille}}</span>
                                </div>
                                <div v-if="trajet.accepte != 'OUI' && trajet.accepte != 'NON' && trajet.type == 'SS' && (trajet.user_id != userData.id)" class="row">
                                    <div class="col-6 service-book">
                                        <a href="javascript:void(0);" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#accepter" @click="accepter = true">Accepter</a>
                                    </div>
                                    <br>
                                    <div class="col-6 service-book">
                                        <a href="javascript:void(0);" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#refuser" @click="refuser = true">Refuser</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card provider-widget clearfix">
                                <div class="card-body">
                                    <h5 class="card-title">Transporteur</h5>
                                    <div class="about-author">
                                        <div class="about-provider-img">
                                            <div class="provider-img-wrap">
                                                <a href="javascript:void(0);">
                                                    <img v-if="trajet.photo" class="rounded-circle" :src="trajet.profil" alt="" height="80" width="80">
                                	                <img v-else class="img-fluid rounded-circle" src="../../assets/img/provider/provider-01.jpg" alt="">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="provider-details">
                                            <a href="javascript:void(0);" class="ser-provider-name">{{trajet.name}}</a>
                                            <p class="mb-1"><i class="far fa-envelope"></i> <a href="javascript:void(0);">{{MaskCharacterEmail(trajet.email, '*', 4)}}</a></p>
                                            <p class="mb-0"><i class="fas fa-phone-alt"></i> {{MaskCharacter(trajet.phone, '#', 3)}}</p>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="service-book">
                                        <a href="javascript:void(0);" class="btn btn-primary" @click="back">Retour à la liste</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 elShow">
                        <h4 class="card-title">Pour ce même trajet</h4>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Accepter Modal -->
        <div class="modal account-modal fade" id="accepter">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-0 border-0">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="login-header">
                            <h3>Accepter le trajet?</h3>
                        </div>
                        <div class="d-grid">
                            <button :disabled="chargement" class="btn btn-success btn-block btn-lg login-btn" @click="accepterTrajet">
                                <div v-if="chargement" class="half-circle-spinner">
                                    <div class="circle circle-1"></div>
                                    <div class="circle circle-2"></div>
                                </div>
                                <div v-else>
                                    Oui j'accepte
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Accepter Modal -->

        <div>
            <Modal v-if="showModal" @close="showModal = false" :text="text">
            </Modal>
            <ModalError v-if="showModalError" @close="showModalError = false" :text="text">
            </ModalError>
        </div>


        <!-- Refuser Modal -->
        <div class="modal account-modal fade" id="refuser">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header p-0 border-0">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="login-header">
                            <h3>Refuser le trajet?</h3>
                        </div>
                        <div class="col-lg-12">
                            <label class="text-black">Motif du refus<span class="text-danger">*</span></label>
                            <div class="form-group">
                                <textarea class="form-control service-desc" rows="5" id="more" v-model="form.motif" name="more"></textarea>
                            </div>
                        </div>
                        <div class="d-grid">
                            <button :disabled="chargement" class="btn btn-danger btn-block btn-lg login-btn" @click="refuserTrajet">
                                <div v-if="chargement" class="half-circle-spinner">
                                    <div class="circle circle-1"></div>
                                    <div class="circle circle-2"></div>
                                </div>
                                <div v-else>
                                    Oui je refuse
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /refuser Modal -->
    </div>
</template>

<script>
	import axios from 'axios'
  	import CardTrajet from './../page/CardTrajet.vue'
    import Modal from '../service/modal.vue'
    import ModalError from '../service/modalError.vue'

	export default {
    	components: { CardTrajet, Modal, ModalError },
        data () {
      		return {
				trajet: {
                    id: 0,
                    photo: '',
                    prix: '',
                    taille: 'Taille',
                    date_publication: 'Aujourd\'hui',
                    depart: 'Départ',
                    arrivee: 'Arrivée',
                },
                showModalError: false,
                showModal: false,
                form: {},
                autres: [],
                user: {},
				token: localStorage.getItem('coliturageToken'),
                chargement: false,
                accepter: false,
                refuser: false,
                userData: JSON.parse(localStorage.getItem('coliturageUserData')),
			}
		},
		created () {
            this.getTrajet()
			window.scrollTo(0, 0)
		},
		mounted() {
            if (!localStorage.getItem('coliturageToken')) {
                this.$router.push('/')
            } 
            if (this.$route.params.redirect) {
                if (this.userData.id != this.$route.params.id_user) {
                    this.$router.push('/')
                } 
            }
            if($('.testimonials-slider1').length > 0) {
                $('.testimonials-slider1').slick({
                    dots: true,
                    autoplay:false,
                    infinite: true,
                    prevArrow: false,
                    nextArrow: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    responsive: [
                        {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                        },
                        {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                        }
                    ]
                });
            }
            if($('.testimonials-slider2').length > 0) {
                $('.testimonials-slider2').slick({
                    dots: true,
                    autoplay:false,
                    infinite: true,
                    prevArrow: false,
                    nextArrow: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    responsive: [
                        {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                        },
                        {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                        }
                    ]
                });
            }
            $('.fav-btn .fav-icon').on('click', function () {
                $(this).toggleClass('favourite');
            });

                    // Membership Add More

            $(".membership-info").on('click','.trash', function () {
                $(this).closest('.membership-cont').remove();
                return false;
            });

            $(".add-membership").on('click', function () {
                var membershipcontent = '<div class="row form-row membership-cont">' +
                '<div class="col-12 col-md-10 col-lg-6">' +
                '<div class="form-group">' +
                '<input type="text" class="form-control" name="service_offered[]" id="field1">' +
                '</div>' +
                '</div>' +
                '<div class="col-12 col-md-2 col-lg-2">' +
                '<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
                '</div>' +
                '</div>';
                $(".membership-info").append(membershipcontent);
                return false;
            });
		},
        methods: {
            back() {
                this.$router.go(-1)
            },
            MaskCharacter(str, mask, n = 1) {
                // Slice the string and replace with
                // mask then add remaining string
                return ('' + str).slice(0, -n)
                    .replace(/./g, mask)
                    + ('' + str).slice(-n);
            },
            MaskCharacterEmail(str, mask, n = 1) {
                // Slice the string and replace with
                // mask then add remaining string
                return('' + str).slice(0, 5) + ('' + str).slice(6, -n)
                    .replace(/./g, mask)
                    + ('' + str).slice(-n);
            },
            getTrajet: function () {
                axios.get(`colis-trajet-booking/${this.$route.params.id}`)
                    .then((response) => {
                        this.trajet = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            accepterTrajet: function () {
                this.chargement = true
                axios.get(`accepter-ct/${this.$route.params.id}`)
                    .then((response) => {
                        this.text = 'Opération effectué avec succès!'
						this.showModal = true
                        this.chargement = false
                        // window.location.reload();
                        $("[data-bs-dismiss=modal]").trigger({ type: "click" });
                        this.$router.push('/provider-payment')
                    })
                    .catch((error) => {
                        this.text = error.response.data.message
						this.showModalError = true
                        this.chargement = false
                    })
            },
            refuserTrajet: function () {
                if(!this.form.motif){
                    return
                }
                this.chargement = true
                axios.post(`refuser-ct/${this.$route.params.id}`, this.form)
                    .then((response) => {
                        this.text = 'Opération effectué avec succès!'
						this.showModal = true
                        this.chargement = false
                        $("[data-bs-dismiss=modal]").trigger({ type: "click" });
                        this.$router.go(-1)
                    })
                    .catch((error) => {
                        this.text = error.response.data.message
						this.showModalError = true
                        this.chargement = false
                    })
            },
        },
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
    @import "./../scss/loading.scss";
</style>