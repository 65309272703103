<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                      <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <div class="row align-items-center mb-4">
                            <div class="col">
                                <h4 class="widget-title mb-0">Transporteur(s) potentiel(s) pour mon colis "{{nom}}"</h4>
                            </div>
                            <!-- <div class="col-auto">
                                <div class="sort-by">
                                    <select class="form-control-sm custom-select searchFilter" id="status">
                                        <option>All</option>
                                        <option>Pending</option>
                                        <option>Inprogress</option>
                                        <option>Complete Request</option>
                                        <option>Rejected</option>
                                        <option>Cancelled</option>
                                        <option>Completed</option>
                                    </select>
                                </div>
                            </div> -->
                        </div>
                        <div id="dataList">
                            <div v-for="trajet in trajets" :key="trajet.id" class="">
								<CardBookingS :booking="trajet"></CardBookingS>
							</div>
							<div v-if="trajets.length == 0">
								<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
							</div>
                            <!-- <div class="pagination">
                                <ul>
                                    <li class="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">4</a>
                                    </li>
                                    <li class="arrow">
                                        <a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
	</div>
</template>

<script>
	import axios from 'axios'
    import Dashboard from './Dashboard.vue'
  	import CardBookingS from './CardBookingS.vue'
	export default {
        components: {
            Dashboard,
			CardBookingS
        },
		data () {
			return {
				nom: this.$route.params.colis,
                trajets: [],
			}
		},
        created () {
            window.scrollTo(0, 400);
            this.getTrajet()
        },
		mounted() {

 // Membership Add More

            $(".membership-info").on('click','.trash', function () {
                $(this).closest('.membership-cont').remove();
                return false;
            });

            $(".add-membership").on('click', function () {
                var membershipcontent = '<div class="row form-row membership-cont">' +
                '<div class="col-12 col-md-10 col-lg-6">' +
                '<div class="form-group">' +
                '<input type="text" class="form-control" name="service_offered[]" id="field1">' +
                '</div>' +
                '</div>' +
                '<div class="col-12 col-md-2 col-lg-2">' +
                '<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
                '</div>' +
                '</div>';
                $(".membership-info").append(membershipcontent);
                return false;
            });
        },
        methods: {
            getTrajet: function () {
                axios.get(`colis-trajet/${this.$route.params.id}`)
                .then((response) => {
                    this.trajets = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
            },
        }
	}
</script>