<template>
    <div class="service-widget animate__animated animate__fadeIn">
        <div class="service-img">
            <router-link @click.native="reload()" to="#">
                <img v-if="trajet.photo" class="img-fluid serv-img" alt="Service Image" :src="trajet.photo">
                <img v-else class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-01.jpg">
            </router-link>
            <div class="fav-btn">
                <!-- <a href="javascript:void(0)" class="fav-icon">
                    <i class="fas fa-heart"></i>
                </a> -->
            </div>
            <div class="item-info">
                <div class="service-user d-xs-none">
                    <a href="javascript:void(0);">
                        <img src="../../assets/img/customer/user-01.jpg" alt="">
                    </a>
                    <span class="service-price ms-1">{{trajet.taille}}</span>
                </div>
                <div class="cate-list">
                    <router-link class="bg-yellow" to="#">{{trajet.date_depart}}</router-link>
                </div>
            </div>
        </div>
        <div class="service-content">
            <h6 class="user-info">
                <div class="row">	
                    <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                        <span>{{MaskCharacter(trajet.depart, 15)}}</span>
                    </span>
                    <span class="col ser-location">
                        <span>{{trajet.arrivee}}</span>
                        <i class="fas fa-flag ms-1"></i>
                    </span>
                </div>
            </h6>
            <div class="rating mapgridrating1 d-xs-none">	
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star"></i>		
                <span class="d-inline-block average-rating">(4.3)</span>
            </div>
            <!-- <h3 class="title">
                <span class="col ser-location"> <i class="fas fa-event ms-1"></i>
                    <router-link to="#">Départ le {{trajet.date_string}}</router-link>
                </span>
            </h3> -->
            <div class="row d-xs-none">	
                <span class="col-12 d-inline-block average-rating text-right">Publié le {{trajet.date_publication}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      trajet: Object,
    },
    methods: {
        MaskCharacter(str, n) {
            // Slice the string and replace with
            // mask then add remaining string
            if (str.length > n){
                return ('' + str).slice(0, n) + '...';
            } else {
                return str
            }
        },
        reload () {
            if (this.$route.name === 'trajet-details') {
                sessionStorage.setItem('urltransition', '/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
            }
        }
    }
}
</script>

<style lang="scss">
.colis-card {
    @media (min-width: 0px) and (max-width: 575px){
      width: 45%;
      height: 150PX;
      margin: auto;
    }

    /* @media (min-width: 376px) and (max-width: 425px){
      width: 400PX;
    }

    @media (min-width: 426px) and (max-width: 768px){
      width: 500PX;
    }

    @media (min-width: 769px) and (max-width: 1024px){
      width: 800PX;
    } */
  }
  .d-xs-none {
    @media (min-width: 0px) and (max-width: 575px){
      display: none !important;
    }
  }
  .noth {
    height: 70PX;
     @media (min-width: 0px) and (max-width: 575px){
      height: 70PX;
    }

    /*@media (min-width: 376px) and (max-width: 425px){
      width: 400PX;
    }

    @media (min-width: 426px) and (max-width: 768px){
      width: 500PX;
    } */

    @media (min-width: 426px){
      width:100%;
      height:220px;
    }
  }
</style>