import {createRouter, createWebHistory} from 'vue-router';
import Index from '../components/dashboard/Index'
import AboutUs from '../components/about/AboutUs'
import AddService from '../components/service/AddService'
import AddTrajet from '../components/service/AddTrajet'
import PublierTrajet from '../components/service/PublierTrajet'
import PublierColis from '../components/service/PublierService'
import BookService from '../components/service/BookService'
import BookTrajet from '../components/service/BookTrajet'
import Categories from '../components/category/Categories'
import Chat from '../components/chat/Chat'
import ContactUs from '../components/page/ContactUs'
import ResetPassword from '../components/page/ResetPassword'
import EditService from '../components/service/EditService'
import EditTrajet from '../components/service/EditTrajet'
import Faq from '../components/page/Faq'
import MyServicesInactive from '../components/service/MyServicesInactive'
import MyServices from '../components/service/MyServices'
import MyServicesSub from '../components/service/MyServicesSub'
import MyTrajetsInactive from '../components/service/MesTrajetsInactive'
import MyTrajets from '../components/service/MesTrajets'
import MyTrajetsSub from '../components/service/MesTrajetsSub'
import Notifications from '../components/page/Notifications'
import PrivacyPolicy from '../components/page/PrivacyPolicy'
import ProviderAvailability from '../components/provider/ProviderAvailability'
import ProviderBookings from '../components/provider/ProviderBookings'
import TrajetBookings from '../components/provider/TrajetBookings'
import ProviderDashboard from '../components/provider/ProviderDashboard'
import ProviderPayment from '../components/provider/ProviderPayment'
import ProviderPayment2 from '../components/provider/ProviderPayment2'
import ProviderColisConfirm from '../components/provider/ProviderColisConfirm'
import ProviderColisConfirm2 from '../components/provider/ProviderColisConfirm2'
import ProviderReveiws from '../components/provider/ProviderReveiws'
import ProviderSettings from '../components/provider/ProviderSettings'
import ProviderSubscription from '../components/provider/ProviderSubscription'
import ProviderWallet from '../components/provider/ProviderWallet'
import Search from '../components/page/Search'
import Transition from '../components/page/Transition'
import SearchTransporteur from '../components/page/SearchTransporteur'
import ServiceDetails from '../components/service/ServiceDetails'
import TrajetDetails from '../components/service/TrajetDetails'
import ServiceTrajetDetails from '../components/provider/ServiceDetails'
import TrajetServiceDetails from '../components/provider/TrajetDetails' 
import PropositionColis from '../components/provider/PropositionColis'
import PropositionTrajet from '../components/provider/PropositionTrajet' 
import TermCondition from '../components/page/TermCondition'
import UserBookings from '../components/user/UserBookings'
import UserDashboard from '../components/user/UserDashboard'
import UserPayment from '../components/user/UserPayment'
import UserReviews from '../components/user/UserReviews'
import UserSettings from '../components/user/UserSettings'
import UserWallet from '../components/user/UserWallet'
import Favourites from '../components/user/Favourites'
/***Admin routes***/
import Admin from '../components/admin/Index'
import AddCategory from '../components/admin/category/AddCategory'
import AddSubCategory from '../components/admin/category/AddSubCategory'
import AddRatingstype from '../components/admin/ratingstype/AddRatingstype'
import AddSubscription from '../components/admin/subscription/AddSubscription'
import AdminNotification from '../components/admin/admin/AdminNotification'
import AdminProfile from '../components/admin/admin/AdminProfile'
import CancelReport from '../components/admin/report/CancelReport'
import indexcategory from '../components/admin/category/indexcategory'
import CompleteReport from '../components/admin/report/CompleteReport'
import EditCategory from '../components/admin/category/EditCategory'
import EditRatingstype from '../components/admin/ratingstype/EditRatingstype'
import EditSubcategory from '../components/admin/category/EditSubcategory'
import EditSubscription from '../components/admin/subscription/EditSubscription'
import EmailSettings from '../components/admin/EmailSettings'
import InprogressReport from '../components/admin/report/InprogressReport'
import login from '../components/admin/login'
import PaymentList from '../components/admin/PaymentList'
import PendingReport from '../components/admin/report/PendingReport'
import Ratingstype from '../components/admin/ratingstype/Ratingstype'
import RejectReport from '../components/admin/report/RejectReport'
import ReviewReports from '../components/admin/report/ReviewReports'
import TotalReport from '../components/admin/report/TotalReport'
import Service from '../components/admin/Service'
import ServiceList from '../components/admin/ServiceList'
import ServiceProviders from '../components/admin/ServiceProviders'
import setting from '../components/admin/setting'
import StripePaymentGateway from '../components/admin/StripePaymentGateway'
import Subcategories from '../components/admin/Subcategories'
import subscriber from '../components/admin/subscriber'
import users from '../components/admin/users'
import WalletHistory from '../components/admin/WalletHistory'
import wall from '../components/admin/wall'
import addfaq from '../components/admin/frontendsetting/addfaq'
import addlanguage from '../components/admin/settings/addlanguage'
import cod from '../components/admin/settings/cod'
import editlanguage from '../components/admin/settings/editlanguage'
import factor from '../components/admin/settings/factor'
import footersettings from '../components/admin/frontendsetting/footersettings'
import frontsett from '../components/admin/frontendsetting/frontsett'
import homepage from '../components/admin/frontendsetting/homepage'
import language from '../components/admin/settings/language'
import localization from '../components/admin/settings/localization'
import othersetting from '../components/admin/settings/othersetting'
import pages from '../components/admin/frontendsetting/pages'
import paymentsettings from '../components/admin/settings/paymentsettings'
import paypal from '../components/admin/settings/paypal'
import paystack from '../components/admin/settings/paystack'
import privacypolicy from '../components/admin/frontendsetting/privacypolicy'
import razorpay from '../components/admin/settings/razorpay'
import seosetting from '../components/admin/settings/seosetting'
import sett from '../components/admin/settings/sett'
import smssettings from '../components/admin/settings/smssettings'
import socialsettings from '../components/admin/settings/socialsettings'
import systemsettings from '../components/admin/settings/systemsettings'
import themesettings from '../components/admin/settings/themesettings'
import translation from '../components/admin/settings/translation'
import twilio from '../components/admin/settings/twilio'

import Stepper from '../components/StepperComponent'
import Tweet from '../components/TweetComponent'
const routes = [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/index',
      name: 'indexx',
      component: Index
    },
    {
      path: '/stepper',
      name: 'stepper',
      component: Stepper
    },
    {
      path: '/tweet',
      name: 'tweet',
      component: Tweet
    },
    {
      path: '/transition',
      name: 'transition',
      component: Transition
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: AboutUs
    },
    {
      path: '/add-service',
      name: 'add-service',
      component: AddService
    },
    {
      path: '/add-trajet',
      name: 'add-trajet',
      component: AddTrajet
    },
    {
      path: '/publier-trajet',
      name: 'publier-trajet',
      component: PublierTrajet
    },
    {
      path: '/publier-colis/:depart?/:arrivee?',
      name: 'publier-colis',
      component: PublierColis
    },
    {
      path: '/book-service/:id/:nom',
      name: 'book-service',
      component: BookService
    },
    {
      path: '/book-trajet/:id',
      name: 'book-trajet',
      component: BookTrajet
    },
    {
      path: '/categories',
      name: 'categories',
      component: Categories
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: ContactUs
    },
    {
      path: '/reset-password/:token?',
      name: 'reset-password',
      component: ResetPassword
    },
    {
      path: '/edit-trajet/:id/:depart/:arrivee',
      name: 'edit-trajet',
      component: EditTrajet
    },
    {
      path: '/edit-service/:id/:colis',
      name: 'edit-service',
      component: EditService
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq
    },
    {
      path: '/my-services-inactive',
      name: 'my-services-inactive',
      component: MyServicesInactive
    },
    {
      path: '/my-services',
      name: 'my-services',
      component: MyServices
    },
    {
      path: '/proposition-colis',
      name: 'proposition-colis',
      component: PropositionColis
    },
    {
      path: '/proposition-trajet',
      name: 'proposition-trajet',
      component: PropositionTrajet
    },
    {
      path: '/my-services-sub',
      name: 'my-services-sub',
      component: MyServicesSub
    },
    {
      path: '/my-trajets-inactive',
      name: 'my-trajets-inactive',
      component: MyTrajetsInactive
    },
    {
      path: '/my-trajets',
      name: 'my-trajets',
      component: MyTrajets
    },
    {
      path: '/my-trajets-sub',
      name: 'my-trajets-sub',
      component: MyTrajetsSub
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: Notifications
    },
    {
      path: '/privacy-policy1',
      name: 'privacy-policy1',
      component: PrivacyPolicy
    },
    {
      path: '/provider-availability',
      name: 'provider-availability',
      component: ProviderAvailability
    },
    {
      path: '/service-bookings/:id/:colis',
      name: 'service-bookings',
      component: ProviderBookings
    },
    {
      path: '/trajet-bookings/:id/:depart/:arrivee',
      name: 'trajet-bookings',
      component: TrajetBookings
    },
    {
      path: '/provider-dashboard',
      name: 'provider-dashboard',
      component: ProviderDashboard
    },
    {
      path: '/provider-payment',
      name: 'provider-payment',
      component: ProviderPayment
    },
    {
      path: '/provider-payment2',
      name: 'provider-payment2',
      component: ProviderPayment2
    },
    {
      path: '/provider-colis-confirm',
      name: 'provider-colis-confirm',
      component: ProviderColisConfirm
    },
    {
      path: '/provider-colis-confirm2',
      name: 'provider-colis-confirm2',
      component: ProviderColisConfirm2
    },
    {
      path: '/provider-reviews',
      name: 'provider-reviews',
      component: ProviderReveiws
    },
    {
      path: '/provider-settings',
      name: 'provider-settings',
      component: ProviderSettings
    },
    {
      path: '/provider-subscription',
      name: 'provider-subscription',
      component: ProviderSubscription
    },
    {
      path: '/provider-wallet',
      name: 'provider-wallet',
      component: ProviderWallet
    },
    {
      path: '/search-colis',
      name: 'search-colis',
      component: Search
    },
    {
      path: '/search-transporteur/:depart?/:arrivee?/:date?',
      name: 'search-transporteur',
      component: SearchTransporteur
    },
    {
      path: '/service-details/:id/:colis?/:user_id?',
      name: 'service-details',
      component: ServiceDetails
    },
    {
      path: '/trajet-details/:id/:user_id?',
      name: 'trajet-details',
      component: TrajetDetails
    },
    {
      path: '/service-trajet-details/:id/:colis/:user_id/:id_user?/:redirect?',
      name: 'service-trajet-details',
      component: ServiceTrajetDetails
    },
    {
      path: '/trajet-service-details/:id/:user_id/:id_user?/:redirect?',
      name: 'trajet-service-details',
      component: TrajetServiceDetails
    },
    {
      path: '/term-condition',
      name: 'term-condition',
      component: TermCondition
    },
    {
      path: '/user-bookings',
      name: 'user-bookings',
      component: UserBookings
    },
    {
      path: '/user-dashboard',
      name: 'user-dashboard',
      component: UserDashboard
    },
    {
      path: '/user-payment',
      name: 'user-payment',
      component: UserPayment
    },
    {
      path: '/user-reviews',
      name: 'user-reviews',
      component: UserReviews
    },
    {
      path: '/user-settings',
      name: 'user-settings',
      component: UserSettings
    },
    {
      path: '/user-wallet',
      name: 'user-wallet',
      component: UserWallet
    },
    {
      path: '/favourites',
      name: 'favourites',
      component: Favourites
    },
    {
      path: '/admin/index',
      name: 'admin',
      component: Admin
    },
    {
      path: '/admin/add-category',
      name: 'add-category',
      component: AddCategory
    },
    {
      path: '/admin/add-ratingstype',
      name: 'add-ratingstype',
      component: AddRatingstype
    },
    {
      path: '/admin/add-subcategory',
      name: 'add-subcategory',
      component: AddSubCategory
    },
    {
      path: '/admin/add-subscription',
      name: 'add-subscription',
      component: AddSubscription
    },
    {
      path: '/admin/admin-notification',
      name: 'admin-notification',
      component: AdminNotification
    },
    {
      path: '/admin/admin-profile',
      name: 'admin-profile',
      component: AdminProfile
    },
    {
      path: '/admin/cancel-report',
      name: 'cancel-report',
      component: CancelReport
    },
    {
      path: '/admin/categories',
      name: 'admin-categories',
      component: indexcategory
    },
    {
      path: '/admin/complete-report',
      name: 'complete-report',
      component: CompleteReport
    },
    {
      path: '/admin/edit-category',
      name: 'edit-category',
      component: EditCategory
    },
    {
      path: '/admin/edit-ratingstype',
      name: 'edit-ratingstype',
      component: EditRatingstype
    },
    {
      path: '/admin/edit-subcategory',
      name: 'edit-subcategory',
      component: EditSubcategory
    },
    {
      path: '/admin/edit-subscription',
      name: 'edit-subscription',
      component: EditSubscription
    },
    {
      path: '/admin/email-settings',
      name: 'emailsettings',
      component: EmailSettings
    },
    {
      path: '/admin/inprogress-report',
      name: 'inprogress-report',
      component: InprogressReport
    },
    {
      path: '/admin/login',
      name: 'login',
      component: login
    },
    {
      path: '/admin/payment_list',
      name: 'payment_list',
      component: PaymentList
    },
    {
      path: '/admin/pending-report',
      name: 'pending-report',
      component: PendingReport
    },
    {
      path: '/admin/ratingstype',
      name: 'ratingstype',
      component: Ratingstype
    },
    {
      path: '/admin/reject-report',
      name: 'reject-report',
      component: RejectReport
    },
    {
      path: '/admin/review-reports',
      name: 'review-reports',
      component: ReviewReports
    },
    {
      path: '/admin/service-details',
      name: 'admin-service-details',
      component: Service
    },
    {
      path: '/admin/service-list',
      name: 'service-list',
      component: ServiceList
    },
    {
      path: '/admin/service-providers',
      name: 'service-providers',
      component: ServiceProviders
    },
    {
      path: '/admin/setting',
      name: 'setting',
      component: setting
    },
    {
      path: '/admin/stripe_payment_gateway',
      name: 'stripe_payment_gateway',
      component: StripePaymentGateway
    },
    {
      path: '/admin/subcategories',
      name: 'subcategories',
      component: Subcategories
    },
    {
      path: '/admin/subscriptions',
      name: 'subscriptions',
      component: subscriber
    },
    {
      path: '/admin/total-report',
      name: 'total-report',
      component: TotalReport
    },
    {
      path: '/admin/users',
      name: 'users',
      component: users
    },
    {
      path: '/admin/wallet-history',
      name: 'wallet-history',
      component: WalletHistory
    },
    {
      path: '/admin/wallet',
      name: 'wallet',
      component: wall
    },
    {
      path: '/admin/add-faq',
      name: 'add-faq',
      component: addfaq
    },
    {
      path: '/admin/add-language',
      name: 'add-language',
      component: addlanguage
    },
    {
      path: '/admin/cod',
      name: 'cod',
      component: cod
    },
    {
      path: '/admin/edit-language',
      name: 'edit-language',
      component: editlanguage
    },
    {
      path: '/admin/factor',
      name: 'factor',
      component: factor
    },
    {
      path: '/admin/footer-settings',
      name: 'footer-settings',
      component: footersettings
    },
    {
      path: '/admin/front-settings',
      name: 'front-settings',
      component: frontsett
    },
    {
      path: '/admin/home-page',
      name: 'home-page',
      component: homepage
    },
    {
      path: '/admin/language-settings',
      name: 'language-settings',
      component: language
    },
    {
      path: '/admin/localization',
      name: 'localization',
      component: localization
    },
    {
      path: '/admin/others-settings',
      name: 'others-settings',
      component: othersetting
    },
    {
      path: '/admin/pages',
      name: 'pages',
      component: pages
    },
    {
      path: '/admin/payment-settings',
      name: 'payment-settings',
      component: paymentsettings
    },
    {
      path: '/admin/paypal',
      name: 'paypal',
      component: paypal
    },
    {
      path: '/admin/paystack',
      name: 'paystack',
      component: paystack
    },
     {
      path: '/admin/privacy-policy',
      name: 'privacy-policy',
      component: privacypolicy
    },
    {
      path: '/admin/razorpay',
      name: 'razorpay',
      component: razorpay
    },
     {
      path: '/admin/seo-settings',
      name: 'seo-settings',
      component: seosetting
    },
     {
      path: '/admin/settings',
      name: 'settings',
      component: sett
    },
     {
      path: '/admin/sms-setting',
      name: 'sms-setting',
      component: smssettings
    },
    {
      path: '/admin/social-settings',
      name: 'social-settings',
      component: socialsettings
    },
     {
      path: '/admin/system-settings',
      name: 'system-settings',
      component: systemsettings
    },
    {
      path: '/admin/theme-settings',
      name: 'theme-settings',
      component: themesettings
    },
     {
      path: '/admin/translation',
      name: 'translation',
      component: translation
    },
    {
      path: '/admin/twilio',
      name: 'twilio',
      component: twilio
    }

  ];
  export const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes
});