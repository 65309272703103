<template>
	<div>
		<div class="main-wrapper">
		<layout-headerservice></layout-headerservice>
        <div class="content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div>
                            <h4 class="widget-title">Notifications</h4>
                            <div class="notcenter">
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-01.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Jeffrey Akridge has booked your service</strong></h3>
                                                <span>Today 01:35 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-02.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Nancy Olson booked your service</strong></h3>
                                                <span>Yesterday 01:31 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-03.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Ramona Kingsley booked your service</strong></h3>
                                                <span>18 Sep 2020 01:29 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-04.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Ricardo Lung have rejected the service</strong></h3>
                                                <span>17 Sep 2020 01:21 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-05.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Annette Silva has booked your service</strong></h3>
                                                <span>16 Sep 2020 01:15 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-06.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Stephen Wilson has booked your service</strong></h3>
                                                <span>15 Sep 2020 08:03 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-07.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Ryan Rodriguez has booked your service</strong></h3>
                                                <span>14 Sep 2020 09:29 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-08.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Lucile Devera booked your service</strong></h3>
                                                <span>13 Sep 2020 08:40 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-09.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Roland Storey has booked your service</strong></h3>
                                                <span>12 Sep 2020 01:59 PM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notificationlist">
                                    <div class="inner-content-blk position-relative">
                                        <div class="d-flex text-dark">
                                            <img class="rounded" src="../../assets/img/customer/user-10.jpg" width="50" alt="">
                                            <div class="noti-contents">
                                                <h3><strong>Lindsey Parmley has cancelled the service</strong></h3>
                                                <span>11 Sep 2020 04:38 AM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                                
                            <!-- Pagination -->
                            <div class="pagination">
                                <ul>
                                    <li class="active">
                                        <a href="javascript:void(0);">1</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">2</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">3</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">4</a>
                                    </li>
                                    <li class="arrow">
                                        <a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <!-- /Pagination -->
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
		</diV>
	</div>
</template>

<script>
	export default {
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>