<template>
    <div class="inactive-service">
        <div class="service-widget">
            <div class="service-img">
                <router-link :to="'/service-trajet-details/'+colis.id+'/'+colis.nom+'/'+colis.user_id">
                    <div style="height: 220px; text-align: center; padding-top: auto;">
                        <span style="display: inline-block; height: 100%;vertical-align: middle;"></span><img class="img-fluid" alt="Service Image" :src="colis.photo2" style="vertical-align: middle;">
                    </div>
                </router-link>
                <!-- <div class="fav-btn">
                    <a href="javascript:void(0)" class="fav-icon">
                        <i class="fas fa-heart"></i>
                    </a>
                </div> -->
                <div class="item-info">
                    <div class="service-user">
                        <!-- <a href="javascript:void(0);">
                            <img src="../../assets/img/customer/user-01.jpg" alt="">
                        </a>	 -->
                        <span class="service-price ms-1">€ {{colis.prix}}</span>
                    </div>
                    <div class="cate-list">
                        <router-link class="bg-yellow" to="#">{{colis.taille}}</router-link>
                    </div>
                </div>
            </div>
            <div class="service-content">
                <h3 class="title">
                    <router-link :to="'/service-trajet-details/'+colis.id+'/'+colis.nom+'/'+colis.user_id">{{MaskCharacter(colis.nom, 30)}}</router-link>
                </h3>
                <div class="row">	
                    <span class="col-6 d-inline-block average-rating"></span>
                </div>
                <div class="user-info">
                    <div class="">	
                        <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                            <span style="font-size: 14px">{{MaskCharacter(colis.depart, 25)}}</span>
                        </span>
                        <span class="col ser-location">
                            <span style="font-size: 14px">{{colis.arrivee}}</span>
                            <i class="fas fa-flag ms-1"></i>
                        </span>
                    </div>
                </div>
                <div class="row">	
                    <span class="col-12 d-inline-block average-rating text-right" style="font-size: 12px">Publié le {{colis.date_publication}}</span>
                </div>
                <div class="user-info">
                    <div class="service-action">
                        <div class="row">
                            <!-- <div class="col">
                                <a href="javascript:void(0)" class="si-delete-inactive-service text-danger" data-id="149"><i class="far fa-trash-alt"></i> Delete</a>
                            </div> -->
                            <div class="col text-end">
                                <a href="javascript:void(0)" :class="{'si-delete-active-service text-danger': colis.statut == 'Annulé', 'si-delete-active-service text-success': colis.statut == 'En cours', 'si-delete-active-service text-info': colis.statut != 'En cours'}"><i class="fas fa-info-circle"></i> {{colis.statut}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header"> 
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
						</button>
					</div> 
					<div class="modal-footer">	<a href="javascript:;" class="btn btn-success si_accept_confirm">Yes</a>
						<button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Cancel</button>
					</div>
				</div>
			</div>
		</div>
		
		<div class="modal fade" id="deleteNotConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="acc_title">Inactive Service?</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>Service is Booked and Inprogress..</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">OK</button>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      colis: Object,
    },
    methods: {
        MaskCharacter(str, n) {
            // Slice the string and replace with
            // mask then add remaining string
            if (str.length > n){
                return ('' + str).slice(0, n) + '...';
            } else {
                return str
            }
        },
        reload () {
            if (this.$route.name === 'service-trajet-details') {
                sessionStorage.setItem('urltransition', '/service-trajet-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/service-trajet-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id)
            }
        }
    }
}
</script>

<style>

</style>