<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-navadmin></layout-navadmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title">General Settings</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <ul class="nav nav-tabs menu-tabs">
                        <li class="nav-item active">
                            <router-link class="nav-link" to="/admin/settings">General Settings</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/admin/email-settings">Email Settings</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/admin/stripe_payment_gateway">Payment Gateway</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/admin/sms-settings">SMS Gateway</router-link>
                        </li>
                    </ul>
                    
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-4 settings-tab">
                            <div class="card">
                                <div class="card-body">
                                    <div class="nav flex-column">
                                        <a class="nav-link active" data-toggle="tab" href="#general">General</a>
                                        <a class="nav-link" data-toggle="tab" href="#push_notification">Push Notification</a>
                                        <a class="nav-link" data-toggle="tab" href="#terms">Terms & Conditions</a>
                                        <a class="nav-link mb-0" data-toggle="tab" href="#privacy">Privacy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-8 col-md-8">
                            <div class="card">
                                <div class="card-body p-0">
                                    <form> 
                                        <div class="tab-content pt-0">
                                        
                                            <!-- General Settings -->
                                            <div id="general" class="tab-pane active">
                                                <div class="card mb-0">
                                                    <div class="card-header">
                                                        <h4 class="card-title">General Settings</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-group">
                                                            <label>Website Name</label>
                                                            <input type="text" class="form-control" placeholder="Dreamguy's Technologies">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Contact Details</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Mobile Number</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Currency</label>
                                                            <p><strong>USD $ </strong></p>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Website Logo</label>
                                                            <div class="uploader">
                                                                <input type="file" class="form-control">
                                                            </div>
                                                            <p class="form-text text-muted small mb-0">Recommended image size is <b>150px x 150px</b>
                                                            </p>
                                                            <img src="../../assets/admin_img/logo.png" class="site-logo" alt="">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Favicon</label>
                                                            <div class="uploader">
                                                                <input type="file" class="form-control">
                                                            </div>
                                                            <p class="form-text text-muted small mb-0">Recommended image size is <b>16px x 16px</b> or <b>32px x 32px</b></p>
                                                            <p class="form-text text-muted small mb-1">Accepted formats: only png and ico</p>
                                                            <img src="../../assets/admin_img/favicon.png" class="fav-icon" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /General Settings -->
                                            
                                            <!-- Push Notification -->
                                            <div id="push_notification" class="tab-pane">
                                                <div class="card mb-0">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Push Notification</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-group">
                                                            <label>Firebase Server Key</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>APNS Key</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /Push Notification -->
                                            
                                            <!-- Terms & Conditions -->
                                            <div id="terms" class="tab-pane">
                                                <div class="card mb-0">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Terms & Conditions</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-group">
                                                            <label>Page Content</label>
                                                            <textarea class="form-control content-textarea" rows="4">Nunc placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scel erisque the mattis, leo quam aliquet congue justo ut scelerisque. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue justo ut scelerisque.</textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /Terms & Conditions -->
                                            
                                            <!-- Privacy Policy -->
                                            <div id="privacy" class="tab-pane pt-0">
                                                <div class="card mb-0 shadow-none">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Privacy</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-group">
                                                            <label>Page Content</label>
                                                            <textarea class="form-control content-textarea" rows="4">Nunc placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scel erisque the mattis, leo quam aliquet congue justo ut scelerisque. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue justo ut scelerisque.</textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /Privacy Policy -->
                                            
                                            <div class="card-body pt-0">
                                                <button type="submit" class="btn btn-primary">Save Changes</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>
<script>
    export default {
        mounted() {

// radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});


        },
    }
</script>