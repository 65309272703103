<template>
	<div>
		<div class="main-wrapper">
			<layout-headerbookings></layout-headerbookings>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-md-4">
                            <div class="mb-4">
                            <div class="d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
                                <img alt="profile image" src="../../assets/img/customer/user-01.jpg" class="avatar-lg rounded-circle">
                                <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                                    <h6 class="mb-0">Jeffrey Akridge</h6>
                                    <p class="text-muted mb-0">Member Since Apr 2020</p>
                                </div>
                            </div>
                        </div>
                            <div class="widget settings-menu">
                                <ul role="tablist" class="nav nav-tabs">
                                    <li class="nav-item current"> 
                                        <router-link to="/user-dashboard" class="nav-link">
                                            <i class="fas fa-chart-line"></i> <span>Dashboard</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item current">
                                    <router-link to="/favourites" class="nav-link">
                                        <i class="fas fa-heart"></i> <span>Favourites</span>
                                    </router-link>
                                </li>
                                    <li class="nav-item current">
                                        <router-link to="/user-bookings" class="nav-link">
                                            <i class="far fa-calendar-check"></i> <span>My Bookings</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-settings" class="nav-link">
                                            <i class="far fa-user"></i> <span>Profile Settings</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-wallet" class="nav-link">
                                            <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-reviews" class="nav-link">
                                            <i class="far fa-star"></i> <span>Reviews</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/user-payment" class="nav-link">
                                            <i class="fas fa-hashtag"></i> <span>Payment</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-9 col-md-8">
                            <h4 class="widget-title">Payment History</h4>
                            <div class="card transaction-table mb-0">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Customer</th>
                                                    <th>Date</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in userpayment" :key="item.id">
                                                    <td>
                                                        <a href="javascript:void(0);">
                                                            <img :src="loadImg(item.ser_image, 1)" class="pro-avatar" alt=""> {{item.service}}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <img class="avatar-xs rounded-circle" :src="loadImg(item.pro_image, 2)" alt=""> {{item.customer}}
                                                    </td>
                                                    <td>{{item.date}}</td>
                                                    <td><strong>{{item.amount}}</strong></td>
                                                    <td><span class="badge bg-danger-light">{{item.status}}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>﻿
		<layout-footer></layout-footer>
		</diV>
	</div>
</template>

<script>
    const serImages = require.context('../../assets/img/services', false, /\.png$|\.jpg$/)
    const proImages = require.context('../../assets/img/provider', false, /\.png$|\.jpg$/)
        import userpayment from '../../json/website/user/userpayment'
	export default {
        data() {
            return {
                userpayment: userpayment,
            }
        },
        methods: {
        
		 loadImg(imgPath, value) {
			 if(value == 1) {
				 return serImages('./' + imgPath).default
			 } else {
				 return proImages('./' + imgPath).default
			 }
            
        },
        
    },
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>