<template>
	<div class="main-wrapper">
    <!-- Hero Section -->
		<section class="hero-section">
			<div class="layer">
				<div class="home-banner"></div>
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="section-search">
								<h3>Transport de colis entre <span>Particuliers</span></h3>
								<p>Une solution écologique et économique pour transporter vos colis!</p>
								<div class="search-box">
									<form> 
										<div class="search-input">
											<i class="fas fa-location-arrow bficon"></i>
											<div class="form-group mb-0">
												<input ref="origin" class="form-control" placeholder="Départ" v-model="depart">
											</div>
										</div>
										<div class="search-input">
											<i class="fas fa-flag bficon"></i>
											<div class="form-group mb-0">
												<input class="form-control" ref="destination" placeholder="Arrivée" v-model="arrivee"> 
												<a class="current-loc-icon current_location" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a>
											</div>
										</div>
										
										<div class="search-input3">
											<i class="fas fa-calendar bficon"></i>
											<div class="form-group mb-0">
												<input class="form-control" style="color: #555a64; font-family: Poppins,sans-serif;" type="date" id="date" v-model="date" name="date">
											</div>
										</div>
										<div class="search-btn">
											<!-- <router-link class="btn" :to=" verifier ? '/search-transporteur/'+depart+'/'+arrivee+'/'+date : '#'">Expédier</router-link> -->
											<div v-if="colisChargement" class="half-circle-spinner" style="margin-left: 40%;">
												<div class="circle circle-1"></div>
												<div class="circle circle-2"></div>
											</div>
											<v-btn v-else class="btn btn-primary" @click="search()">Expédier</v-btn>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /Hero Section -->
		
		<!-- How It Works -->
		<section class="how-work">
			<div class="">
				<div class="row cWEIrK" style="margin-bottom: 30px">
					<div class="col-lg-12 container">
						<h3 style="margin-bottom: 20px; font-weight:bold; text-align: center;">Comment expédier mon colis ?</h3>
						<div class="howworksec">
							<div class="row">
								<div class="col-lg-4">
									<div class="howwork">
										<div class="iconround">
											<div class="steps">01</div>
											<img src="../../assets/img/icon-1.png" alt="">
										</div>
										<h3>Entrez le trajet de votre colis</h3>
										<p>Saisissez votre ville de départ, votre ville d'arrivée et la date à laquelle vous souhaitez expédier votre colis.</p>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="howwork">
										<div class="iconround">
											<div class="steps">02</div>
											<img src="../../assets/img/icon-2.png" alt="">
										</div>
										<h3>Trouvez un transporteur</h3>
										<p>Parmi la liste des transporteurs qui vous sera proposée, choisissez celui qui vous semble le plus abordable.</p>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="howwork">
										<div class="iconround">
											<div class="steps">03</div>
											<img src="../../assets/img/icon-3.png" alt="">
										</div>
										<h3>... Et expédiez !</h3>
										<p>Ensuite soumettez votre colis via le formulaire et vous recevrez une réponse de la part du transporteur.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row trab cWEIrK" style="padding-bottom: 24px">
					<div class="col-lg-12 container">
						<div class="howworksec2">
							<div class="row">
								<div class="col-lg-6 col-md-6">
									<div class="howwork">
										<img class="imge" src="https://cdn.blablacar.com/kairos/assets/images/phishing-b200bc23cc51c0950d45..svg" alt="">
									</div>
								</div>
								<div class="col-lg-6 col-md-6 imge">
									<div class="howwork" style="color: white; text-align: left">
										<h3 style="font-size: 25px; color: white; margin-left: 15px;">Aidez-nous à vous protéger des arnaques</h3>
										<p style="color: white; margin-left: 15px;">Chez Coliturage, nous travaillons dur pour rendre notre plateforme aussi sécurisée que possible. Mais lorsque des tentatives d’escroqueries se produisent, nous voulons que vous sachiez exactement comment les éviter et les signaler. Contactez nous par email pour nous aider à assurer votre sécurité.</p>
										<!-- <button class="btn btn-primary mt-3" style="margin-left: 20px; border-radius: 20px;">
											Lire Plus
										</button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /How It Works -->

			<!-- Category Section -->
		<section class="category-section">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 container">
						<div class="howworksec2">
							<div class="row">
								<div class="col-lg-6 col-md-6">
									<div class="howwork">
										<img width="400" class="imge" src="../../assets/img/7506749.jpg" alt="">
									</div>
								</div>
								<div class="col-lg-6 col-md-6">
									<div class="howwork" style="color: white; text-align: left;">
										<h3 style="font-size: 25px; ; margin-left: 15px;">Vous effectuez un trajet et vous souhaitez transporter un colis  ?</h3>
										<p style="margin-left: 15px;">Publiez votre trajet sur la plateforme COLITURAGE et recevez dans de brefs délais des propositons de colis à transporter!</p>
										<div class="searchB">
											<li>
												<router-link class="btn nav-link header-login menu-close" id="menu_close" to="/publier-trajet">
													Publier un trajet
												</router-link>
											</li>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> 
		<section class="category-section trab">
			<div class="container">
				<div class="row cWEIrK">
					<div class="col-lg-12">
						<div class="row">
							<div class="col-md-6">
								<div class="heading">
									<h2 style="color: antiquewhite;">Transporter un colis</h2>
									<span style="color: antiquewhite;">Soumettez une proposition de transport!</span>
								</div>
							</div>
							<div class="col-md-6">
								<div class="viewall">
									<h4><router-link to="/search-colis"  style="color: antiquewhite;">Tout voir <i class="fas fa-angle-right"></i></router-link></h4>
									<span style="color: grey;">Colis récents</span>
								</div>
							</div>
						</div>
						<div class="catsec">
							<div class="row">
								<div v-for="coli in colis" :key="coli.id" class="col-lg-3 col-md-4 col-sm-6">
									<CardProduct :colis="coli"></CardProduct>
								</div>
								<div v-if="colis.length == 0" class="text-center">
									<!-- <img src="../../assets/img/nothing-found.png" class="nothing" alt=""> -->
									<button  class="btn btn-primary" style="border-radius: 25px; margin-top: 10px;" @click="goTo('/publier-trajet')">
										Publier un trajet
									</button>
								</div>
							</div>
							<div class="text-center">
								<h4><router-link class="btn" to="/search-colis" style="color: antiquewhite;">Afficher tous les colis <i class="fas fa-angle-right"></i></router-link></h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /Category Section -->

		<section class="category-section">
			<div class="container">
				<div class="row" style="padding-bottom: 24px">
					<div class="col-lg-12 container">
						<div class="howworksec2">
							<div class="row">
								<div class="col-lg-6 col-md-6">
									<div class="howwork">
										<img class="imge" width="300" src="../../assets/img/4002758.jpg" alt="">
									</div>
								</div>
								<div class="col-lg-6 col-md-6 imge cWEIrK">
									<div class="howwork" style="color: white; text-align: left;">
										<h3 style="font-size: 25px; ; margin-left: 15px;">Vous souhaitez expédier un colis  ?</h3>
										<p style="margin-left: 15px;">Publiez simplement votre colis en proposant un tarif et les transporteurs disponibles sur votre trajet vous ferons une proposition </p>
										<div class="searchB">
											<li>
												<router-link class="btn nav-link header-login menu-close" id="menu_close" to="/publier-colis">
													Expédier un colis 
												</router-link>
											</li>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> 

		<section class="category-section trab">
			<div class="container">
				<div class="row cWEIrK">
					<div class="col-lg-12">
						<div class="row">
							<div class="col-md-6">
								<div class="heading">
									<h2 style="color: antiquewhite;">Expédier un colis?</h2>
									<span style="color: antiquewhite;">Trouvez le trajet qui correspond</span>
								</div>
							</div>
							<div class="col-md-6">
								<div class="viewall">
									<h4><router-link to="/search-transporteur"  style="color: antiquewhite;">Tout voir <i class="fas fa-angle-right"></i></router-link></h4>
									<span style="color: grey">Trajets publiés</span>
								</div>
							</div>
						</div>
						<div class="catsec">
							<div class="row">
								<div v-for="trajet in trajets" :key="trajet.id" class="col-lg-4 col-md-6">
									<div class="service-widget animate__animated animate__fadeIn">
										<div class="service-content">
											<h6 class="user-info">
												<div class="row mb-1">
													<div class="col-auto ser-contact cate-list">
														<router-link class="bg-yellow" :to="'/trajet-details/'+ trajet.id + '/' + trajet.user_id">Départ {{trajet.date_depart}}</router-link>
													</div>
													<div class="col ser-location">
														<span class="service-price ms-1">{{trajet.taille}}</span>
													</div>
												</div>
												<div class="">	
													<span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
														<span>{{MaskCharacter(trajet.depart, 30)}}</span>
													</span>
													<span class="col ser-location">
														<span>{{trajet.arrivee}}</span>
														<i class="fas fa-flag ms-1"></i>
													</span>
												</div>
											</h6>
											<div class="row">	
												<span class="col-12 d-inline-block average-rating text-right">Publié le {{trajet.date_publication}}  
												<router-link style="margin-left: 40px" :to="'/trajet-details/'+ trajet.id + '/' + trajet.user_id">Voir le trajet</router-link></span>
											</div>
										</div>
									</div>
								</div>
								<div v-if="trajets.length == 0" class="text-center">
									<!-- <img src="../../assets/img/nothing-found.png" class="nothing" alt=""> -->
									<button  class="btn btn-primary" style="border-radius: 25px; margin-top: 10px;" @click="goTo('/publier-colis')">
										Expédier un colis
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> 

		<!-- /our app -->
		<section class="app-set">
			<div class="container">
				<div class="row align-items-center cWEIrK">
					<div class="col-lg-6 col-12">
						<div class="col-md-12">
							<div class="heading">
								<h2>Notre Application Mobile</h2>
								<span>Disponible très bientôt!!</span>
							</div>
						</div>
						<div class="downlaod-set">
							<ul>
								<li>
									<a><img src="../../assets/img/gp.png" alt="img"></a>
								</li>
								<li>
									<a><img src="../../assets/img/ap.png" alt="img"></a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="appimg-set">
							<img src="../../assets/img/phoneApp.png" height="500" alt="img">
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /our app -->
	</div>
</template>

<script>
	import axios from 'axios'
	import { onMounted, ref  } from 'vue';
	import { apiKey } from '../googleAPIKey'
  	import CardProduct from './../page/CardProduct.vue'
	import googlePlaces from '../googlePlaces'
	import autocomplet from '../autocompletion'
	import 'regenerator-runtime/runtime'

	export default {
    	components: { CardProduct },
		data () {
      		return {
				colis: [],
				date: null,
				colisChargement: false,
				trajets: [],
				token: localStorage.getItem('coliturageToken'),
				depart: '',
				arrivee: '',
				user: null,
				results: [],
				form: {
					ordre: 'Plus recents',
					taille: 'Toute Taille',
					depart: '',
					date: null,
					arrivee: '',
					mot: '',
				},
			}
		},
		computed: {
			verifier () {
				if (this.arrivee === '' || this.depart === ''){
					return false
				} else {
					return true
				}
			}
		},
		created () {
			if (localStorage.getItem('coliturageToken') !== '') {
				this.user = JSON.parse(localStorage.getItem('coliturageUserData'))
			}
			this.getColis()
			this.getTrajet()
		},
		mounted() {
			document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			
			const link = apiKey;

			let googleScript = document.querySelector(`script[src="${link}"]`);

			if (googleScript) {
				const origin = this.$refs["origin"];
				const destination = this.$refs["destination"];
                var options = {
					types: ['(cities)'],
					//fields: ["address_components", "geometry", "name"],
					componentRestrictions: {country: ["fr", "us"]}
				};
				const autocompleteorigin = new google.maps.places.Autocomplete(origin);
				const autocompletedestination = new google.maps.places.Autocomplete(destination);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.depart = autocompleteorigin.getPlace().formatted_address
					this.form.depart = autocompleteorigin.getPlace().formatted_address
					console.log('test', autocompleteorigin.getPlace())
				});

				autocompletedestination.addListener("place_changed", () => {
					this.arrivee = autocompletedestination.getPlace().formatted_address
					this.form.arrivee = autocompletedestination.getPlace().formatted_address
					console.log(autocompletedestination.getPlace())
				});
			}

			googlePlaces(link).then((result) => {
				const origin = this.$refs["origin"];
				const destination = this.$refs["destination"];
                var options = {
					types: ['(cities)'],
					// fields: ["address_components", "geometry", "name"],
					componentRestrictions: {country: ["fr", "us"]}
				};
				const autocompleteorigin = new google.maps.places.Autocomplete(origin);
				const autocompletedestination = new google.maps.places.Autocomplete(destination);
				
				autocompleteorigin.addListener("place_changed", () => {
					this.depart = autocompleteorigin.getPlace().formatted_address
					this.form.depart = autocompleteorigin.getPlace().formatted_address
					console.log('test', autocompleteorigin.getPlace())
				});

				autocompletedestination.addListener("place_changed", () => {
					this.arrivee = autocompletedestination.getPlace().formatted_address
					this.form.arrivee = autocompletedestination.getPlace().formatted_address
					console.log(autocompletedestination.getPlace())
				});
			})

			var d = new Date()
      		this.date = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2)
			
			var today = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2)
			document.getElementById("date").setAttribute("min", today);
			$('.fav-btn .fav-icon').on('click', function () {
				$(this).toggleClass('favourite');
			});

			$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});

			if($('.testimonials-slider').length > 0) {
				$('.testimonials-slider').slick({
					dots: true,
					autoplay:false,
					infinite: true,
					prevArrow: false,
					nextArrow: false,
					slidesToShow: 3,
					slidesToScroll: 3,
					responsive: [
						{
						breakpoint: 991,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
						}
						},
						{
						breakpoint: 767,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
						},
					]
				});
			}
		},
		methods: {
			goTo(url) {
				this.$router.push(url)
			},
			getColis: function () {
				axios.get('colis')
					.then((response) => {
						this.colis = response.data
					})
					.catch((error) => {
						console.log(error)
					})
			},
			search: function () {
				this.form.date = this.date
				this.form.depart = this.depart
				this.form.arrivee = this.arrivee
				if (!this.form.depart || !this.form.arrivee || !this.form.date) {
					return
				}
				this.colisChargement = true
				axios.post('trajet-filtre', this.form)
				.then((response) => {
					this.results = response.data.data
					if(this.results.length > 0) {
						this.colisChargement = false
						this.$router.push('/search-transporteur/'+this.depart+'/'+this.arrivee+'/'+this.date)
					}else{
						this.colisChargement = false
						this.$router.push('/publier-colis/'+this.depart+'/'+this.arrivee)
					}
				})
				.catch((error) => {
					this.spinner = false
					console.log(error)
				})
				$('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			},
			fucnt: function () {
				var todayDate = new Date(); //Today Date    
				var dateOne = new Date(this.colisForm.date);    
				if (todayDate >= dateOne) {    
					this.formControl.date = true;
					loop = true    
				}
			},
			MaskCharacter(str, n) {
				// Slice the string and replace with
				// mask then add remaining string
				if (str.length > n){
					return ('' + str).slice(0, n) + '...';
				} else {
					return str
				}
			},
			getTrajet: function () {
                axios.get('trajet')
                    .then((response) => {
                        this.trajets = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
			},
		},
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>

<style>
.trab {
	margin-top: 24px;
    margin-bottom: 0px;
    padding-top: 0px 24px;
    background-color: rgb(5, 71, 82);
}

.cWEIrK {
    padding: 24px 24px 48px;
}

.searchB {
	float: left;
    width: auto;
}
.searchB .btn {
    width: 100%;
    border: 1px solid rgb(0, 175, 245);
    background: rgb(0, 175, 245);
    border-radius: 20px;
	color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 5px;
}

div, img, .imge {
    margin: 0px;
    padding: 0px;
    border: 0px;
    font: inherit;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
}

.datz {
	color: #333;
	font-family: 'CircularStd', sans-serif;
	font-size: 1rem;
	height: 100%;
}
</style>
