<template>
    <div class="inactive-service">
        <div class="service-widget">
            <div class="service-img">
                <router-link :to="'/trajet-service-details/'+ this.trajet.id + '/' + this.trajet.user_id">
                    <img v-if="trajet.photo" style="width:100%;height:220px;" class="img-fluid serv-img" alt="Service Image" :src="trajet.photo">
                    <img v-else style="width:100%;height:220px;" class="img-fluid serv-img" alt="Service Image" src="../../assets/img/services/service-01.jpg">
                </router-link>
                <div class="item-info">
                    <div class="service-user">
                        <a href="javascript:void(0);">
                            <img src="../../assets/img/customer/user-01.jpg" alt="">
                        </a>
                    </div>
                    <div class="cate-list">
                        <router-link class="bg-yellow" to="#">{{trajet.date_depart}}</router-link>
                    </div>
                </div>
            </div>
            <div class="service-content">
                <h6 class="user-info">
                    <div class="">	
                        <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                            <span style="font-size: 14px">{{MaskCharacter(trajet.depart, 25)}}</span>
                        </span>
                        <span class="col ser-location">
                            <span style="font-size: 14px">{{MaskCharacter(trajet.arrivee, 25)}}</span>
                            <i class="fas fa-flag ms-1"></i>
                        </span>
                    </div>
                </h6>
                <div class="row">	
                    <span class="col-12 d-inline-block average-rating text-right" style="font-size: 12px">Publier le {{trajet.date_publication}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      trajet: Object,
    },
    methods: {
        MaskCharacter(str, n) {
            // Slice the string and replace with
            // mask then add remaining string
            if (str.length > n){
                return ('' + str).slice(0, n) + '...';
            } else {
                return str
            }
        },
        reload () {
            if (this.$route.name === 'trajet-service-details') {
                sessionStorage.setItem('urltransition', '/trajet-service-details/'+ this.trajet.id + '/' + this.trajet.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/trajet-service-details/'+ this.trajet.id + '/' + this.trajet.user_id)
            }
        }
    }
}
</script>
