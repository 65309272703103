<template>
    <div class="bookings">
        <div class="booking-list">
            <div class="booking-widget">
                <!-- <router-link :to="'/trajet-service-details/' + this.booking.id + '/' + this.booking.user_id" class="booking-img">
                    <img :src="booking.profil" alt="User Image">
                </router-link> -->
                <div class="booking-det-info">
                    <h3>
                        <router-link :to="'/service-details/' + this.booking.colis_id"> Voir le colis à tranporter (<i class="far fa-eye"></i>)</router-link>
                    </h3>
                    <ul class="booking-details">
                        <li>
                            <span>Départ le</span>{{booking.date_depart}}
                        </li>
                        <!-- <li><span>Booking time</span> 13:00:00 - 14:00:00</li> -->
                        <li><span>Tarif </span> €{{booking.prix}}</li>
                        <li>
                            <span>Soumis le</span>{{booking.date_publication}}
                        </li>
                        <!-- <li><span>Location</span> 223 Jehovah Drive Roanoke</li> -->
                        <!-- <span class="badge badge-pill badge-prof bg-success">Complete Request sent to User</span>
                        <span class="badge badge-pill badge-prof bg-primary">Inprogress</span>
                        <span class="badge badge-pill badge-prof bg-danger">Rejected by User</span> -->
                    </ul>
                </div>
            </div>
            <div class="booking-action">
                <a v-if="booking.accepte == 'OUI'" href="javascript:;" class="btn btn-sm bg-success-light"><i class="fas fa-check"></i> Trajet accepté</a>
                <a v-if="booking.accepte == 'NON'" href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> Trajet refusé</a>
                <span v-if="booking.accepte != 'OUI' && booking.accepte != 'NON'" class="badge badge-pill badge-prof bg-warning">En attente</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      booking: Object,
    },
    methods: {
        MaskCharacter(str, mask, n = 1) {
            // Slice the string and replace with
            // mask then add remaining string
            return ('' + str).slice(0, -n)
                .replace(/./g, mask)
                + ('' + str).slice(-n);
        },
        reload () {
            if (this.$route.name === 'trajet-details') {
                sessionStorage.setItem('urltransition', '/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/trajet-details/'+ this.trajet.id + '/' + this.trajet.user_id)
            }
        }
    }
}
</script>

<style>

</style>