<template>
<div>
	<div class="main-wrapper container" style="background-color: rgb(221, 221, 221)">
	<!-- Header -->
		<header class="header">
			<nav class="navbar navbar-expand-lg header-nav">
				<div class="navbar-header">
					<a id="mobile_btn" href="javascript:void(0);">
						<h1 style="font-style: italic; color: rgb(0, 175, 215); margin-top: auto;" class="mt-3">
							<i class="fa fa-bars fa-lg" style=""></i>
						</h1>
					</a>
					<router-link to="/index" class="navbar-brand logo text-center">
						<img src="../../assets/img/logo.png" width="250"  class="img-fluid" alt="Logo">
						<!-- <h3 style="font-style: italic; color: rgb(0, 175, 245); font-weight: bold" class="mt-3">COLITURAGE</h3> -->
					</router-link>
				</div>
				<div class="main-menu-wrapper">
					<div class="menu-header text-center">
						<router-link to="/index" class="menu-logo text-center" >
							<img src="../../assets/img/logo.png" width="250"  class="img-fluid" alt="Logo">
							<!-- <h3 style="font-style: italic; color: rgb(0, 175, 245); font-weight: bold">COLITURAGE</h3> -->
						</router-link>
						<a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
					</div>
					<ul class="main-nav">
						<li v-bind:class="{'active': currentPath == 'index'}">
							<router-link to="/index" id="menu_close" class="menu-close" href="javascript:void(0);"><span class="rlink">Accueil</span></router-link>
						</li>
						<li v-if="!user">
							<router-link to="/search-colis" id="menu_close" class="menu-close" href="javascript:void(0);"><span class="rlink">Tous les colis</span></router-link>
						</li>
						<li v-if="!user">
							<router-link to="/search-transporteur" id="menu_close" class="menu-close" href="javascript:void(0);"><span class="rlink">Tous les transporteurs</span></router-link>
						</li>
						<!-- <li>
							<a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#provider-register">Tous les colis</a>
						</li> -->
						<li v-if="user">
							<router-link to="/publier-colis" id="menu_close" class="menu-close" href="javascript:void(0);">
								<span class="rlink">Publier un colis</span>
							</router-link>
						</li>
						<li  v-if="user">
							<router-link to="/publier-trajet" id="menu_close" class="menu-close" href="javascript:void(0);">
								<span class="rlink">Publier un trajet</span>
							</router-link>
						</li>
					</ul>
				</div>
				<!-- <ul class="nav header-navbar-rht">
					<li class="nav-item">
						<a class="nav-link header-login" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#login_modal">Transporter un colis</a>
					</li>
				</ul> -->
				<ul class="nav header-navbar-rht">
					<li v-if="!user">
						<a href="javascript:void(0);" id="menu_close" class="nav-link menu-close header-login" data-bs-toggle="modal" data-bs-target="#login_modal">Connexion</a>
					</li>
					<li v-else>
						<v-btn class="btn btn-primary" id="menu_close" @click="countColis()">Transporter un colis</v-btn>
						<!-- <router-link class="nav-link header-login menu-close" id="menu_close" to="#" @click="countColis()">
							Transporter un colis
						</router-link> -->
					</li>
					<!-- <li>
						<a href="javascript:void(0);" id="menu_close" class="nav-link menu-close header-login" data-bs-toggle="modal" data-bs-target="#provider-register">Test</a>
					</li> -->
					<!-- User Menu -->
                    <li v-if="user" class="nav-item dropdown has-arrow logged-item cDZQKS.kirk-avatar--image">
                        <a href="#" class="dropdown-toggle nav-link menu-close" id="menu_close" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="user-img">
								<img v-if="user.photo" class="rounded-circle test-img" :src="user.photo" alt="">
                                <img v-else class="rounded-circle test-img" src="../../assets/img/provider/provider-01.jpg" alt="">
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <div class="user-header">
                                <div class="avatar avatar-sm">
									<img v-if="user.photo" class="avatar-img rounded-circle" :src="user.photo" alt="" sty>
                                	<img v-else class="avatar-img rounded-circle" src="../../assets/img/provider/provider-01.jpg" alt="">
                                </div>
                                <div class="user-text">
                                    <h6 class="text-truncate">{{ user.name }}</h6>
                                    <p class="text-muted mb-0">{{ user.email }}</p>
                                </div>
                            </div>
                            <router-link class="dropdown-item" to="/provider-dashboard">Dashboard</router-link>
                            <!-- <router-link class="dropdown-item" to="/my-services">My Services</router-link>
                            <router-link class="dropdown-item" to="/provider-bookings">Booking List</router-link>
                            <router-link class="dropdown-item" to="/provider-settings">Profile Settings</router-link>
                            <router-link class="dropdown-item" to="/provider-wallet">Wallet</router-link>
                            <router-link class="dropdown-item" to="/provider-subscription">Subscription</router-link>
                            <router-link class="dropdown-item" to="/provider-availability">Availability</router-link>
                            <router-link class="dropdown-item" to="/chat">Chat</router-link>
                            <router-link class="dropdown-item" to="#">Mes trajets</router-link>
                            <router-link class="dropdown-item" to="#">Mes colis</router-link> -->
							<button class="btn btn-secondary btn-block btn-sm dropdown-item" @click="logout()">Déconnexion</button>
                        </div>
                    </li>
                    <!-- /User Menu -->
				</ul>
			</nav>
		</header>
		<!-- /Header -->
	</div>
	
	<!-- Login Modal -->
	<div class="modal account-modal fade" id="login_modal">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Je me connecte</h3>
					</div>
					<form id="app"
						@submit.prevent="login"
						novalidate="true"
					>

					<p v-if="loginErrors.length">
						<b>Erreur(s):</b>
						<ul>
						<li v-for="error in loginErrors" :key="error" style="color: red;">{{ error }}</li>
						</ul>
					</p>
						<div class="form-group form-focus">
							<label class="focus-label">Email <span style="color:red" v-if="loginFormControl.email">(email invalide)</span></label>
							<input type="email" class="form-control" @change="loginFormControl.email = false" placeholder="email@example.com" id="email" v-model="loginForm.email" name="email" >
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Mot de passe <span style="color:red" v-if="loginFormControl.password">(requis)</span></label>
							<input type="password" id="password" @change="loginFormControl.password = false" v-model="loginForm.password" name="password" class="form-control" placeholder="********">
						</div>
						<!-- <div class="fulfilling-bouncing-circle-spinner">
							<div class="circle"></div>
							<div class="orbit"></div>
						</div> -->
						<div class="text-end">	
						</div>
						<div class="d-grid">
							<button :disabled="loginChargement" class="btn btn-primary btn-block btn-lg login-btn" type="submit">
								<div v-if="loginChargement" class="half-circle-spinner">
									<div class="circle circle-1"></div>
									<div class="circle circle-2"></div>
								</div>
								<div v-else>
									Se connecter
								</div>
							</button>
						</div>
						<!-- <div class="login-or">	<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="row form-row social-login">
							<div class="col-6 d-grid"><a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f ms-0 mx-1"></i> Login</a>
							</div>
							<div class="col-6 d-grid"><a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google ms-0 mx-1"></i> Login</a>
							</div>
						</div> -->
						<div class="text-center dont-have" v-if="!loginChargement">Pas de compte? <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#user-register" @click="clearLoginFormControl()">S'inscrire</a>
						<div class="text-center dont-have" v-if="!loginChargement">Mot de passe <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#reset_modal" @click="clearLoginFormControl()">Oublié</a>
						</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /Login Modal -->
	
	<!-- User Register Modal -->
	<div class="modal account-modal fade multi-step" id="user-register" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Inscription</h3>
					</div>
					
					<!-- Register Form -->
					<Form  @submit="register()" :validation-schema="registerF" v-slot="{ errors }">
						<div class="form-group form-focus">
							<label class="focus-label">Nom d'utilisateur</label>
							<Field name="name" type="text" v-model="registerForm.name" class="form-control" :class="{ 'is-invalid': errors.name }" />
							<div class="invalid-feedback">{{errors.name}}</div>
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Email</label>
							<Field name="email" type="text" v-model="registerForm.email" class="form-control" :class="{ 'is-invalid': errors.email }" />
							<div class="invalid-feedback">{{errors.email}}</div>
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Pays</label>
							<select name="pays" v-model="registerForm.pays" class="form-control" :class="{ 'is-invalid': errors.phone }">
								<option v-for="pay in countryList" :key="pay.value" :value="pay">{{pay.label + ' ('+ pay.value +')'}}</option>
							</select>
							<div class="invalid-feedback">{{errors.phone}}</div>
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Téléphone</label>
							<Field name="phone" :prefix="pays.value" v-model="registerForm.phone" class="form-control" :class="{ 'is-invalid': errors.phone }" />
							<div class="invalid-feedback">{{errors.phone}}</div>
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Mot de Passe</label>
							<Field name="password" type="password" v-model="registerForm.password" class="form-control" :class="{ 'is-invalid': errors.password }" />
							<div class="invalid-feedback">{{errors.password}}</div>
						</div>
						<div class="form-group form-focus">
							<label class="focus-label">Confirmer Mot de Passe</label>
							<Field name="confirmPassword" type="password" v-model="registerForm.cPassword" class="form-control" :class="{ 'is-invalid': errors.confirmPassword }" />
							<div class="invalid-feedback">{{errors.confirmPassword}}</div>
						</div>
						<div class="form-group form-check">
							<Field name="acceptTerms" type="checkbox" id="acceptTerms" value="true" class="form-check-input" :class="{ 'is-invalid': errors.acceptTerms }" />
							<label for="acceptTerms" class="form-check-label">Accepter les Termes & Conditions</label>
							<div class="invalid-feedback">{{errors.acceptTerms}}</div>
						</div>
						<div class="text-end" v-if="!registerChargement">
							<a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#login_modal" >J'ai déjà un compte</a>
						</div>
						<div class="d-grid">
							<button :disabled="registerChargement" class="btn btn-primary btn-block btn-lg login-btn" type="submit">
								<div v-if="registerChargement" class="half-circle-spinner">
									<div class="circle circle-1"></div>
									<div class="circle circle-2"></div>
								</div>
								<div v-else>
									S'inscrire
								</div>
							</button>
						</div>
						<!-- <div class="login-or">
							<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="row form-row social-login">
							<div class="col-6 d-grid">
								<a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f ms-0 mx-1"></i> Login</a>
							</div>
							<div class="col-6 d-grid">
								<a href="javascript:void(0);" class="btn btn-google btn-block" @click="clearRegisterFormControl()"><i class="fab fa-google ms-0 mx-1"></i> Login</a>
							</div>
						</div> -->
					</Form>
					<!-- /Register Form -->
					
				</div>
			</div>
		</div>
	</div>
	<!-- /User Register Modal -->

	<!-- Reset Password -->
	<div class="modal account-modal fade" id="reset_modal">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header p-0 border-0">
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-header">
						<h3>Reinitialisation du mot de passe</h3>
					</div>
					<form id="app"
						@submit.prevent="reset"
						novalidate="true"
					>
						<div class="form-group form-focus">
							<label class="focus-label">Votre Email</label>
							<input type="email" class="form-control" placeholder="email@example.com" id="email" v-model="resetForm.email" name="email" >
						</div>
						<div class="text-end">	
						</div>
						<div class="d-grid">
							<button :disabled="loginChargement" class="btn btn-primary btn-block btn-lg login-btn" type="submit">
								<div v-if="loginChargement" class="half-circle-spinner">
									<div class="circle circle-1"></div>
									<div class="circle circle-2"></div>
								</div>
								<div v-else>
									Réinitialiser
								</div>
							</button>
						</div>
						<!-- <div class="login-or">	<span class="or-line"></span>
							<span class="span-or">or</span>
						</div>
						<div class="row form-row social-login">
							<div class="col-6 d-grid"><a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f ms-0 mx-1"></i> Login</a>
							</div>
							<div class="col-6 d-grid"><a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google ms-0 mx-1"></i> Login</a>
							</div>
						</div> -->
						<div class="text-center dont-have" v-if="!loginChargement">Pas de compte? <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#user-register" @click="clearLoginFormControl()">S'inscrire</a>
						<div class="text-center dont-have" v-if="!loginChargement"><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#login_modal" @click="clearLoginFormControl()">j'ai déja un compte</a>
						</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /Reset Password -->

	<div>
		<ModalError v-if="showModalError" @close="showModalError = false" :text="text">
		</ModalError>
	</div>
	</div>
</template>
<script>
	import axios from 'axios'
	import { Form, Field, defineRule, configure } from "vee-validate";
    import ModalError from './modalError.vue'
	import * as Yup from 'yup';

    export default {
		components: {
			Form,
			Field,
			ModalError,
		},
		data () {
			const schema = Yup.object().shape({
				title: Yup.string()
					.required('Title is required'),
				firstName: Yup.string()
					.required('First Name is required'),
				lastName: Yup.string()
					.required('Last name is required'),
				dob: Yup.string()
					.required('Date of Birth is required')
					.matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
				email: Yup.string()
					.required('Email is required')
					.email('Email is invalid'),
				password: Yup.string()
					.min(6, 'Password must be at least 6 characters')
					.required('Password is required'),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], 'Passwords must match')
					.required('Confirm Password is required'),
				acceptTerms: Yup.string()
					.required('Accept Ts & Cs is required')
			});
			const registerF = Yup.object().shape({
				name: Yup.string()
					.required('Le nom est obligatoire'),
				/* pays: Yup.string()
					.required('Le pays est obligatoire'), */
				phone: Yup.string()
					.required('Le téléphone est obligatoire'),
				email: Yup.string()
					.required('L\'Email est obligatoire')
					.email('Email est invalide'),
				password: Yup.string()
					.min(6, 'Le mot de passe doit avoir minimum 6 caractères')
					.required('Le mot de passe est obligatoire'),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], 'les mots de de passe doivent être identiques')
					.required('Confirm Password est obligatoire'),
				acceptTerms: Yup.string()
					.required('Accept Ts & Cs est obligatoire')
			});
			return {
				schema,
				pays: {},
				registerF,
                showModalError: false,
                text: '',
				// validations: { size: 100 },
				loginErrors: [],
				registerErrors: [],
				registerSuccessMsg: [],
				loginChargement: false,
				resetChargement: false,
				registerChargement: false,
				countryList: [
					{ label: "Autriche", value: "+43" },
					{ label: "Belgique", value: "+32" },
					{ label: "Bulgarie", value: "+359" },
					{ label: "Croatie", value: "+385" },
					{ label: "Chypre", value: "+357" },
					{ label: "Tchèque", value: "+420" },
					{ label: "Danemark", value: "+45" },
					{ label: "Estonie", value: "+372" },
					{ label: "Finlande", value: "+358" },
					{ label: "France", value: "+33" },
					{ label: "Allemagne", value: "+49" },
					{ label: "Grèce", value: "+30" },
					{ label: "Hongrie", value: "+36" },
					{ label: "Islande", value: "+354" },
					{ label: "Irlande", value: "+353" },
					{ label: "Italie", value: "+39" },
					{ label: "Lettonie", value: "+371" },
					{ label: "Lituanie", value: "+370" },
					{ label: "Luxembourg", value: "+352" },
					{ label: "Malte", value: "+356" },
					{ label: "Pays-Bas", value: "+31" },
					{ label: "Norvège", value: "+47" },
					{ label: "Pologne", value: "+48" },
					{ label: "Portugal", value: "+351" },
					{ label: "Roumanie", value: "+40" },
					{ label: "Slovaquie", value: "+421" },
					{ label: "Slovénie", value: "+386" },
					{ label: "Espagne", value: "+34" },
					{ label: "Suède", value: "+46" },
					{ label: "Royaume-Uni", value: "+44" }
				],
				registerForm: {},
				loginForm: {
					email: null,
					password: null,
				},
				resetForm: {
					email: null,
				},
				registerFormControl: {
					name: false,
					email: false,
					pays: false,
					phone: false,
					password: false,
					cPassword: false
				},
				loginFormControl: {
					email: false,
					password: false,
				},
				colis: [],
				user: null,
			}
		},
		created () {
			if (localStorage.getItem('coliturageToken')) {
				this.user = JSON.parse(localStorage.getItem('coliturageUserData'))
			} else {
				this.$router.push('/index')
			}
		},
        mounted() {
			if($(window).width() <= 991) {
			var Sidemenu = function() {
				this.$menuItem = $('.main-nav a');
			};

			function init() {
				var $this = Sidemenu;
				$('.main-nav a').on('click', function(e) {
					if($(this).parent().hasClass('has-submenu')) {
						e.preventDefault();
					}
					if(!$(this).hasClass('submenu')) {
						$('ul', $(this).parents('ul:first')).slideUp(350);
						$('a', $(this).parents('ul:first')).removeClass('submenu');
						$(this).next('ul').slideDown(350);
						$(this).addClass('submenu');
					} else if($(this).hasClass('submenu')) {
						$(this).removeClass('submenu');
						$(this).next('ul').slideUp(350);
					}
				});
			}

		// Sidebar Initiate
		init();
		}
			// Mobile menu sidebar overlay
			$('.header-fixed').append('<div class="sidebar-overlay"></div>');
		$(document).on('click', '#mobile_btn', function() {
			$('main-wrapper').toggleClass('slide-nav');
			$('.sidebar-overlay').toggleClass('opened');
			$('html').addClass('menu-opened');
			return false;
		});
		$(document).on('click', '.sidebar-overlay', function() {
			$('html').removeClass('menu-opened');
			$(this).removeClass('opened');
			$('main-wrapper').removeClass('slide-nav');
		});
		
		$(document).on('click', '#menu_close', function() {
			$('html').removeClass('menu-opened');
			$('.sidebar-overlay').removeClass('opened');
			$('main-wrapper').removeClass('slide-nav');
		});
		},
       	computed: {
           	currentPath() {
               return this.$route.name   
           	},
		   	providerMenu() {
				return this.$route.name == 'provider-dashboard' || this.$route.name == 'my-services-inactive' || this.$route.name == 'my-services' || this.$route.name == 'provider-bookings' ||  this.$route.name == 'provider-settings' ||  this.$route.name == 'provider-wallet' ||  this.$route.name == 'provider-subscription' ||  this.$route.name == 'provider-availability' ||  this.$route.name == 'provider-reviews' ||  this.$route.name == 'provider-payment';
			},
			pageMenu() {
				return this.$route.name == 'search' || this.$route.name == 'service-details' || this.$route.name == 'add-service' ||  this.$route.name == 'edit-service' ||  this.$route.name == 'chat' ||  this.$route.name == 'notifications' ||  this.$route.name == 'about-us' ||  this.$route.name == 'contact-us' ||  this.$route.name == 'faq' ||  this.$route.name == 'term-condition' ||  this.$route.name == 'privacy-policy1';
			},
			customerMenu() {
				return this.$route.name == 'user-dashboard' || this.$route.name == 'favourites' || this.$route.name == 'user-bookings' || this.$route.name == 'user-settings' ||  this.$route.name == 'user-wallet' ||  this.$route.name == 'user-reviews' ||  this.$route.name == 'user-payment';
			},
       	},
		methods: {
			onSubmit(values) {
				alert('SUCCESS!! :-)\n\n' + JSON.stringify(values, null, 4));
			},
			login: function () {
				let loop = false
				this.clearLoginFormControl()
				if (!this.loginForm.email) {
					this.loginFormControl.email = true
					loop = true
				} else if (!this.validEmail(this.loginForm.email)) {
					this.loginFormControl.email = true
					loop = true
				}
				if (!this.loginForm.password) {
					this.loginFormControl.password = true
					loop = true
				}

				if (!loop) {
					this.loginChargement = true
					axios.post('login', this.loginForm)
						.then((response) => {
							localStorage.setItem('coliturageToken', response.data.token)
							localStorage.setItem('coliturageUserData', JSON.stringify(response.data.user))
							this.clearLoginForm()
							window.location.reload()
						})
						.catch((error) => {
							this.text = error.response.data.message
							this.showModalError = true
							this.loginChargement = false
						})
				}	
			},
			reset: function () {
				let loop = false
				if (!this.resetForm.email) {
					return
				}

				this.loginChargement = true
				axios.post('resetMail', this.resetForm)
					.then((response) => {
						alert(response.data.message)
						window.location.reload()
					})
					.catch((error) => {
						this.text = error.response.data.message
						this.showModalError = true
						this.loginChargement = false
					})
			},
			logout: function () {
				axios.get('logout')
					.then((response) => {
						localStorage.removeItem('coliturageToken')
						localStorage.removeItem('coliturageUserData')
						window.location.reload()
					})
					.catch((error) => {
						localStorage.removeItem('coliturageToken')
						localStorage.removeItem('coliturageUserData')
						window.location.reload()
					})
			},
			register: function () {
				let loop = false
				this.clearRegisterFormControl()
				this.registerSuccessMsg = [];
				if (!this.registerForm.name) {
					this.registerFormControl.name = true
					loop = true
				}
				if (!this.registerForm.email) {
					this.registerFormControl.email = true
					loop = true
				} else if (!this.validEmail(this.registerForm.email)) {
					this.registerFormControl.email = true
					loop = true
				}
				if (!this.pays) {
					this.registerFormControl.pays = true
					loop = true
				}
				if (!this.registerForm.phone) {
					this.registerFormControl.phone = true
					loop = true
				}
				if (!this.registerForm.password) {
					this.registerFormControl.password = true
					loop = true
				} else if (this.registerForm.password !== this.registerForm.cPassword) {
					this.registerFormControl.cPassword = true
					loop = true
				}

				if (!loop) {
					this.registerChargement = true
					axios.post('register', this.registerForm)
						.then((response) => {
							axios.post('login', this.registerForm)
								.then((response) => {
									localStorage.setItem('coliturageToken', response.data.token)
									localStorage.setItem('coliturageUserData', JSON.stringify(response.data.user))
									this.clearLoginForm()
									window.location.reload()
								})
								.catch((error) => {
									this.text = error.response.data.message
									this.showModalError = true
									this.registerChargement = false
								})
						})
						.catch((error) => {
							this.text = error.response.data.message
							this.showModalError = true
							this.registerChargement = false
						})
				}
			},
			getUser: function () {
				axios.get('get_user')
					.then((response) => {
						this.user = response.data.user
						sessionStorage.setItem('profil', this.user.photo)
						sessionStorage.setItem('nom', this.user.name)
						sessionStorage.setItem('email', this.user.email)
					})
					.catch((error) => {
						localStorage.removeItem('coliturageToken')
						localStorage.removeItem('coliturageUserData')
						this.user = null
						// this.$router.push('/')
					})
			},
			countColis: function () {
				axios.get('colis-paginate')
					.then((response) => {
						this.colis = response.data.data
						if(this.colis.length > 0) {
							this.$router.push('/search-colis')
						}else{
							this.$router.push('/publier-trajet')
						}
					})
					.catch((error) => {
						console.log(error)
					})
			},
			clearRegisterForm: function () {
				this.registerForm.name = null
				this.registerForm.email = null
				this.registerForm.phone = null
				this.registerForm.password = null
				this.registerForm.cPassword = null
			},
			clearLoginForm: function () {
				this.loginForm.email = null
				this.loginForm.password = null
			},
			clearRegisterFormControl: function () {
				this.registerErrors = [];
				this.registerFormControl.name = false
				this.registerFormControl.email = false
				this.registerFormControl.pays = false
				this.registerFormControl.phone = false
				this.registerFormControl.password = false
				this.registerFormControl.cPassword = false
			},
			clearLoginFormControl: function () {
				this.loginErrors = [];
				this.loginFormControl.email = false
				this.loginFormControl.password = false
			},
			validEmail: function (email) {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			}
		}
    }
    </script>

<style lang="scss">
	@import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
	@import "./../scss/style.scss";
</style>

<style>
.rlink {
	color: rgb(0, 175, 245);
	font-size: 18px;
}

.cDZQKS.kirk-avatar--image img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.test-img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>