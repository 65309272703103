<template>

<!-- Footer -->
        <footer class="footer cWEIrK">
        
            <!-- Footer Top -->
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <!-- <div class="col-lg-4 col-md-4">
                            <div class="footer-widget footer-menu">
                                <h2 class="footer-title">Quick Links  </h2>
                                <ul>
                                    <li>
                                        <router-link to="/about-us">About Us</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/contact-us">Contact Us</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/faq">Faq</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">Help</a>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="col-lg-6 col-md-6">
                            <!-- Footer Widget -->
                            <div class="footer-widget footer-contact">
                                <h2 class="footer-title">Contactez Nous</h2>
                                <div class="footer-contact-info">
                                    <div class="footer-address">
                                        <span><i class="far fa-building"></i></span>
                                        <p>1 rue claude bernard 92230 Gennevilliers</p>
                                    </div>
                                    <p><i class="fas fa-headphones"></i>07 55 89 59 01 </p>
                                    <p class="mb-0"><i class="fas fa-envelope"></i>info@coliturage.com</p>
                                </div>
                            </div>
                            <!-- /Footer Widget -->
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <!-- Footer Widget -->
                            <div class="footer-widget">
                                <h2 class="footer-title">Suivez Nous</h2>
                                <div class="social-icon">
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);" target="_blank"><i class="fab fa-facebook-f"></i> </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i> </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" target="_blank"><i class="fab fa-youtube"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" target="_blank"><i class="fab fa-google"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="subscribe-form">
                                    <input type="email" class="form-control" placeholder="Enter your email">
                                    <button type="submit" class="btn footer-btn">
                                        <i class="fas fa-paper-plane"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- /Footer Widget -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Footer Top -->
            
            <!-- Footer Bottom -->
            <div class="footer-bottom">
                <div class="container">
                    <!-- Copyright -->
                    <div class="copyright">
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <div class="copyright-text">
                                    <p class="mb-0">&copy; 2022 <router-link to="/index">Coliturage</router-link>. All rights reserved.</p>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <!-- Copyright Menu -->
                                <div class="copyright-menu">
                                    <ul class="policy-menu">
                                        <li>
                                             <router-link class="me-2" to="/term-condition">Conditions d'utilisation</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/privacy-policy1">Politique</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/reset-password">reset PWD</router-link>
                                        </li>
                                    </ul>
                                </div>
                                <!-- /Copyright Menu -->
                            </div>
                        </div>
                    </div>
                    <!-- /Copyright -->
                </div>
            </div>
            <!-- /Footer Bottom -->
            
        </footer>
        <!-- /Footer -->

</template>
<script>
	import axios from 'axios'
	export default {
		data () {
      		return {
				  categories: [],
			}
		},
    }
</script>

<style>
.cWEIrK {
    padding: 24px 24px 48px;
}
</style>