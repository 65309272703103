<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                      <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <form
                            @submit.prevent=""
							novalidate="true"
                        >
                            <div class="widget">
                                <h4 class="widget-title  text-end">Paramettres de profil</h4> 
                                <div class="row">
                                    <div class="col-xl-12">
                                        <h5 class="form-title">Informations Basiques</h5>
                                    </div>
                                    <div class="form-group col-xl-12">
                                        <div class="media align-items-center mb-3 d-flex">
                                            <img v-if="user.photo" class="user-image" width="120" height="120" :src="user.photo" alt="">
                                            <img v-else class="user-image" src="../../assets/img/provider/provider-01.jpg" alt="">
                                            <div class="media-body">
                                                <h5 class="mb-0">{{ user.name }}</h5>
                                                <p>Membre dépuis le {{ user.date_crea }}</p>
                                                <div class="jstinput">
                                                    <a href="javascript:void(0);" id="menu_close" class="browsephoto" data-bs-toggle="modal" data-bs-target="#deleteNotConfirmModal"><i class="fas fa-info-circle"></i> Modifier</a>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Nom <span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" v-model="user.name">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Email <span class="text-danger">*</span></label>
                                    <input class="form-control" type="email" v-model="user.email" readonly>
                                </div>
                                <!-- <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Code Pays/label>
                                    <input class="form-control" type="text" value="+1" readonly>
                                </div> -->
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Téléphone <span class="text-danger">*</span></label>
                                    <input class="form-control no_only" type="text" v-model="user.phone" required>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Date de naissance</label>
                                    <input type="date" class="form-control provider_datepicker" autocomplete="off" v-model="user.date_nais">
                                </div>
                                <div class="col-xl-12">
                                    <h5 class="form-title">Adresse</h5>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Numéro de rue</label>
                                    <input type="text" class="form-control" v-model="user.num_rue">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Nom de rue</label>
                                    <input type="text" class="form-control" v-model="user.nom_rue">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Postal Code</label>
                                    <input type="text" class="form-control" v-model="user.code_postal">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="me-sm-2">Ville</label>
                                    <input type="text" class="form-control" ref="ville" :value="user.ville">
                                </div>
                                <span style="color:red" v-if="this.errors.length">{{ errors }}</span>
                                <div class="form-group col-xl-12">
                                    <div v-if="colisChargement" class="half-circle-spinner" style="margin-left: auto;">
										<div class="circle circle-1"></div>
										<div class="circle circle-2"></div>
									</div>
                                    <button v-else class="btn btn-primary ps-5 pe-5" @click="updateUser()">Enregistrer</button>
                                </div> 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteNotConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="acc_title">Modifier ma photo de profil</h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12">
                            <div v-if="!file">
                                <div
                                    :class="['dropZone service-upload', dragging ? 'dropZone-over service-upload' : '']"
                                    @dragenter="dragging = true"
                                    @dragleave="dragging = false"
                                >
                                    <div
                                        class="dropZone-info"
                                        @drag="onChange"
                                    >
                                        <i class="fas fa-cloud-upload-alt"></i> <span>Chargez une photo de profil *</span>
                                        <div class="dropZone-upload-limit-info">
                                            <div>Taille max: 10 MB</div>
                                        </div>
                                    </div>
                                        <input
                                        type="file"
                                        accept="image/*"
                                        size="10000000"
                                        @change="onChange"
                                        >
                                </div>
                            </div>
                            <div
                                v-else
                                class="dropZone-uploaded service-upload"
                            >
                                <div class="dropZone-uploaded-info">
                                    <span class="dropZone-title">Nom: {{ file.name }}</span>
                                    <span class="dropZone-title">Taille: {{ file.size }} octets</span>
                                    <button
                                    type="button"
                                    class="btn btn-primary removeFile"
                                    @click="removeFile"
                                    >
                                    Retirer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div v-if="colisChargement" class="half-circle-spinner" style="margin-left: auto;">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                        <button v-else type="button" @click="updateProfil()" class="btn btn-success si_accept_confirm">Enregister</button>
                    </div>
                </div>
            </div>
        </div>
	</diV>
</template>

<script>
	import axios from 'axios'
	import { apiKey } from '../googleAPIKey'
    import Dashboard from './Dashboard.vue'
	import googlePlaces from '../googlePlaces'
	export default {
        components: {
            Dashboard
        },
        data () {
			return {
				loginErrors: [],
				user: {},
                colisChargement: false,
				errors: [],
				file: null,
				dragging: false,
                hide: false,
			}
		},
		mounted() {
            const link = apiKey;

			let googleScript = document.querySelector(`script[src="${link}"]`);

			if (googleScript) {
                console.log('exist')
				const autocompleteVille = new google.maps.places.Autocomplete(this.$refs["ville"]);
			
                autocompleteVille.addListener("place_changed", () => {
                    this.user.ville = autocompleteVille.getPlace().formatted_address
                    console.log(this.user.ville)
                });
			}

			googlePlaces(link).then((result) => {
				console.log('success')
				const autocompleteVille = new google.maps.places.Autocomplete(this.$refs["ville"]);
			
                autocompleteVille.addListener("place_changed", () => {
                    this.user.ville = autocompleteVille.getPlace().formatted_address
                    console.log(this.user.ville)
                });
			})
        // Membership Add More

            $(".membership-info").on('click','.trash', function () {
                $(this).closest('.membership-cont').remove();
                return false;
            });

            $(".add-membership").on('click', function () {
                var membershipcontent = '<div class="row form-row membership-cont">' +
                '<div class="col-12 col-md-10 col-lg-6">' +
                '<div class="form-group">' +
                '<input type="text" class="form-control" name="service_offered[]" id="field1">' +
                '</div>' +
                '</div>' +
                '<div class="col-12 col-md-2 col-lg-2">' +
                '<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
                '</div>' +
                '</div>';
                $(".membership-info").append(membershipcontent);
                return false;
            });
		},
        created () {
            this.getUser()
        },
        methods: {
            onChange: function (e) {
				var files = e.target.files || e.dataTransfer.files

				if (!files.length) {
				this.dragging = false
				return
				}

				this.createFile(files[0])
			},
			createFile: function (file) {
				if (!file.type.match('image.*')) {
				this.messageSnackbar = 'Veuillez sélectionner un fichier image'
				this.snackbar = true
				this.dragging = false
				return
				}

				if (file.size > 10000000) {
				this.messageSnackbar = 'Veuillez sélectionner un fichier de moins de 10 MB.'
				this.snackbar = true
				this.dragging = false
				return
				}

				this.file = file
				this.dragging = false
			},
			removeFile: function () {
				this.file = null
			},
            updateProfil: function () {
				let loop = false

				if (!this.file) {
					return
				}
				
                this.colisChargement = true
                const fileData = new FormData()
                fileData.append('photo', this.file)
                
                axios.post('update-profil', fileData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                })
                .then((response) => {
					window.location.reload()
                    this.colisChargement = false
                    this.getUser()
                    this.file = null
                    this.hide = true
                })
                .catch((error) => {
                    alert(error.response.data.message)
                    this.colisChargement = false
                })
			},
            getUser: function () {
				axios.get('get_user')
					.then((response) => {
						this.user = response.data.user
                        localStorage.setItem('coliturageUserData', JSON.stringify(response.data.user))
					})
					.catch((error) => {
						localStorage.setItem('coliturageToken', '')
						this.user = null
						this.$router.push('/')
					})
			},
            updateUser: function () {
                this.errors = [];
				
				if (!this.user.name || !this.user.phone || !this.user.email) {
					this.errors.push("Veuillez renseigner les champs avec une étoile!");
                    return
				}
                this.colisChargement = true
				axios.put(`user/${this.user.id}`, this.user)
					.then((response) => {
                        this.colisChargement = false
						this.user = response.data.user
                        localStorage.setItem('coliturageUserData', JSON.stringify(response.data.user))
					})
					.catch((error) => {
                        this.colisChargement = false
                        this.errors.push(error.response.data.message);
                        console.log(error)
					})
			},
        }
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>
