<template>
  <div id="app">
    <Header class="container"></Header>
    <loader v-if="!status"></loader>
    <router-view v-slot="{ Component }" style="">
      <transition 
        enter-active-class="animate__animated animate__fadeIn animate__faster	"
        leave-active-class="animate__animated animate__fadeOut animate__faster	"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- <router-view/> -->
    <Footer></Footer>
  </div>
</template>
<script>
	import axios from 'axios'
  import Header from './components/layout/Header.vue'
  import Footer from './components/layout/Footer.vue'
	import 'regenerator-runtime/runtime'
  
  export default {
    components: { Header, Footer },
    name: 'App',
    data: function() { 
      return { 
        status: false,
      } 
    },
		created () {
		},
  }
</script>

<style>
.fade-enter-form,
.fade-leave-to {
  opacity: 0;
}

:root {
  --animate-duration: 500ms;
  --animate-delay: 0.9s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
</style>

<style lang="scss">
  @import "./components/scss/style.scss";
</style>
