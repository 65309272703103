<template>
    <div>
        <div class="navbar-header">
            <a id="mobile_btn2" href="javascript:void(0);">
                <h4 style="color: rgb(0, 175, 245); margin-bottom: 30px;">
                    <i class="fa fa-map-signs" aria-hidden="true"></i>
                    <b> Dashboard</b>
                </h4>
            </a>
        </div>
        <div class="main-menu-wrapper2">
            <div class="menu-header2 text-center">
                <h4 style="color: rgb(0, 175, 245)"><b>Dashboard</b></h4>
                <a id="menu_close2" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
            </div>
            <div class="stickyside">
                <div class="mb-4">
                    <div class="d-ms-flex d-md-flex flex-row flex-wrap text-center text-ms-start text-md-start align-items-center">
                        <img v-if="userData.photo" class="avatar-lg rounded-circle ms-sm-3 ms-md-3 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0" :src="userData.photo" alt="profil image">
                        <img v-else class="avatar-lg rounded-circle ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0" src="../../assets/img/provider/provider-01.jpg" alt="profile image">
                        <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
                            <h6 class="mb-0" style="color: rgb(0, 175, 245);">{{ userData.name }}</h6>
                            <p class="text-muted mb-0">{{ userData.email }}</p>
                        </div>
                    </div>
                </div>          
                <div class="widget settings-menu ml-1">
                    <ul>
                        <li class="nav-item">
                            <router-link to="/provider-dashboard" class="nav-link">
                                <i class="fa fa-home" aria-hidden="true"></i> <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/provider-settings" class="nav-link">
                                <i class="far fa-user"></i> <span>Parametres de profil</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/my-services" class="nav-link">
                                <i class="fa fa-gift" aria-hidden="true"></i> <span>Tous mes colis</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/my-trajets" class="nav-link">
                                <i class="fa fa-map-marker" aria-hidden="true"></i> <span>Tous mes Trajets</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/proposition-colis" class="nav-link">
                                <i class="fa fa-shopping-bag" aria-hidden="true"></i> <span>Propositions de colis</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/proposition-trajet" class="nav-link">
                                <i class="fa fa-cubes" aria-hidden="true"></i> <span>Propositions de transport</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/provider-payment" class="nav-link">
                                <i class="fa fa-paper-plane" aria-hidden="true"></i> <span>Expéditions en cours</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/provider-colis-confirm" class="nav-link">
                                <i class="fa fa-truck" aria-hidden="true"></i> <span>Transports en cours</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from 'axios'
    export default {
        data () {
            return {
                userData: JSON.parse(localStorage.getItem('coliturageUserData')),
            }
        },
        mounted() {
			if($(window).width() <= 991) {
            var Sidemenu = function() {
				this.$menuItem = $('.main-nav a');
			};

			function init() {
				var $this = Sidemenu;
				$('.main-nav a').on('click', function(e) {
					if($(this).parent().hasClass('has-submenu')) {
						e.preventDefault();
					}
					if(!$(this).hasClass('submenu')) {
						$('ul', $(this).parents('ul:first')).slideUp(350);
						$('a', $(this).parents('ul:first')).removeClass('submenu');
						$(this).next('ul').slideDown(350);
						$(this).addClass('submenu');
					} else if($(this).hasClass('submenu')) {
						$(this).removeClass('submenu');
						$(this).next('ul').slideUp(350);
					}
				});
                $('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
			}
		    // Sidebar Initiate
            init();
            }
			// Mobile menu sidebar overlay
			$('.header-fixed2').append('<div class="sidebar-overlay2"></div>');
            $(document).on('click', '#mobile_btn2', function() {
                $('main-wrapper2').toggleClass('slide-nav2');
                $('.sidebar-overlay2').toggleClass('opened');
                $('html').addClass('menu-opened2');
                return false;
            });
            $(document).on('click', '.sidebar-overlay2', function() {
                $('html').removeClass('menu-opened2');
                $(this).removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
            });
            
            $(document).on('click', '#menu_close2', function() {
                $('html').removeClass('menu-opened2');
                $('.sidebar-overlay2').removeClass('opened');
                $('main-wrapper2').removeClass('slide-nav2');
            });
		},
    }
</script>

<style>

</style>