<template>
	<div>
		<div class="main-wrapper">
			<layout-headeradmin></layout-headeradmin>
            <layout-navadmin></layout-navadmin>
            <div class="page-wrapper">
                <div class="content container-fluid">
                
                    <!-- Page Header -->
                    <div class="page-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="page-title">Users</h3>
                            </div>
                            <div class="col-auto text-end">
                                <a class="btn btn-white filter-btn" href="javascript:void(0);" id="filter_search">
                                    <i class="fas fa-filter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
                    
                    <!-- Search Filter -->
                    <div class="card filter-card" id="filter_inputs">
                        <div class="card-body pb-0">
                            <form>
                                <div class="row filter-row">
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input class="form-control" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>From Date</label>
                                            <div class="cal-icon">
                                                <input class="form-control datetimepicker" type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <label>To Date</label>
                                            <div class="cal-icon">
                                                <input class="form-control datetimepicker" type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <div class="form-group">
                                            <button class="btn btn-primary btn-block" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- /Search Filter -->
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-center mb-0 datatable">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Contact No</th>
                                                    <th>Signup Date</th>
                                                    <th>Last Login</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-01.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Jeffrey Akridge</a>
                                                        </h2>
                                                    </td>
                                                    <td>jeffreyakridge@example.com</td>
                                                    <td>850-847-0459</td>
                                                    <td>10 Sep 2020</td>
                                                    <td>12 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-02.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Nancy Olson</a>
                                                        </h2>
                                                    </td>
                                                    <td>nancyolson@example.com</td>
                                                    <td>901-690-8272</td>
                                                    <td>8 Sep 2020</td>
                                                    <td>11 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-03.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Ramona Kingsley</a>
                                                        </h2>
                                                    </td>
                                                    <td>ramonakingsley@example.com</td>
                                                    <td>608-712-2413</td>
                                                    <td>7 Sep 2020</td>
                                                    <td>7 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-04.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Ricardo Lung</a>
                                                        </h2>
                                                    </td>
                                                    <td>ricardolung@example.com</td>
                                                    <td>714-564-6443</td>
                                                    <td>3 Sep 2020</td>
                                                    <td>5 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-05.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Annette Silva</a>
                                                        </h2>
                                                    </td>
                                                    <td>annettesilva@example.com</td>
                                                    <td>517-697-4628</td>
                                                    <td>31 Aug 2020</td>
                                                    <td>4 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-06.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Stephen Wilson</a>
                                                        </h2>
                                                    </td>
                                                    <td>stephenwilson@example.com</td>
                                                    <td>903-367-8263</td>
                                                    <td>19 Aug 2020</td>
                                                    <td>29 Aug 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-07.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Ryan Rodriguez</a>
                                                        </h2>
                                                    </td>
                                                    <td>ryanrodriguez@example.com</td>
                                                    <td>912-748-9361</td>
                                                    <td>15 Aug 2020</td>
                                                    <td>13 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-08.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Lucile Devera</a>
                                                        </h2>
                                                    </td>
                                                    <td>luciledevera@example.com</td>
                                                    <td>817-913-0462</td>
                                                    <td>6 Aug 2020</td>
                                                    <td>13 Aug 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-09.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Roland Storey</a>
                                                        </h2>
                                                    </td>
                                                    <td>rolandstorey@example.com</td>
                                                    <td>201-929-0473</td>
                                                    <td>27 Jun 2020</td>
                                                    <td>9 Sep 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>
                                                        <h2 class="table-avatar">
                                                            <a href="javascript:void(0);" class="avatar avatar-sm me-2">
                                                                <img class="avatar-img rounded-circle" src="../../assets/admin_img/customer/user-10.jpg" alt="User Image">
                                                            </a>
                                                            <a href="javascript:void(0);">Lindsey Parmley</a>
                                                        </h2>
                                                    </td>
                                                    <td>lindseyparmley@example.com</td>
                                                    <td>740-485-5513</td>
                                                    <td>9 Jun 2020</td>
                                                    <td>5 Sep 2020</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</diV>
	</div>
</template>

<script>
	export default {
		mounted() {

        // radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});

		},
	}
</script>