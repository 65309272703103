<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                        <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <div class="row pricing-box">
                            <div class="col-xl-4 col-md-6 ">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="pricing-header">
                                            <h2>Basic</h2>
                                            <p>Monthly Price</p>
                                        </div>
                                        <div class="pricing-card-price">
                                            <h3 class="heading2 price">$0.00</h3>
                                            <p>Duration: <span>3 Months</span></p>
                                        </div>
                                        <ul class="pricing-options">
                                            <li><i class="far fa-check-circle"></i> One listing submission</li>
                                            <li><i class="far fa-check-circle"></i> 90 days expiration</li>
                                        </ul>
                                        <a href="javascript:void(0);" class="btn btn-primary btn-block w-100">Select Plan</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 ">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="pricing-header">
                                            <h2>Standard</h2>
                                            <p>Monthly Price</p>
                                        </div>
                                        <div class="pricing-card-price">
                                            <h3 class="heading2 price">$50.00</h3>
                                            <p>Duration: <span>6 Months</span></p>
                                        </div>
                                        <ul class="pricing-options">
                                            <li><i class="far fa-check-circle"></i> One listing submission</li>
                                            <li><i class="far fa-check-circle"></i> 180 days expiration</li>
                                        </ul>
                                        <a href="javascript:void(0);" class="btn btn-primary btn-block w-100">Select Plan</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 pricing-selected">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="pricing-header">
                                            <h2>Enterprice</h2>
                                            <p>Monthly Price</p>
                                        </div>
                                        <div class="pricing-card-price">
                                            <h3 class="heading2 price">$1200.00</h3>
                                            <p>Duration: <span>12 Months</span></p>
                                        </div>
                                        <ul class="pricing-options">
                                            <li><i class="far fa-check-circle"></i> One listing submission</li>
                                            <li><i class="far fa-check-circle"></i> 360 days expiration</li>
                                        </ul>
                                        <a href="javascript:void(0);" class="btn btn-primary btn-block w-100">Subscribed</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="plan-det">
                                    <h6 class="title">Plan Details</h6>
                                    <ul class="row">
                                        <li class="col-sm-4">
                                            <p><span class="text-muted">Started On</span> 15 Jul 2020</p>
                                        </li>
                                        <li class="col-sm-4">
                                            <p><span class="text-muted">Price</span> $1502.00</p>
                                        </li>
                                        <li class="col-sm-4">
                                            <p><span class="text-muted">Expired On</span> 15 Jul 2021</p>
                                        </li>
                                    </ul>
                                    <h6 class="title">Last Payment</h6>
                                    <ul class="row">
                                        <li class="col-sm-4">
                                            <p>Paid at 15 Jul 2020</p>
                                        </li>
                                        <li class="col-sm-4">
                                            <p><span class="amount">$1502.00 </span>  <span class="badge bg-success-light">Paid</span></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="mb-4">Subscribed Details</h5>    
                        <div class="card transaction-table mb-0">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-center mb-0 no-footer">
                                        <thead>
                                            <tr>
                                                <th>Plan</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Basic</td>
                                                <td>03-04-2020</td>
                                                <td>02-07-2020</td>
                                                <td>0.00</td>
                                                <td><span class="badge bg-success-light">Paid</span></td>
                                            </tr>
                                            <tr>
                                                <td>Standard</td>
                                                <td>04-04-2020</td>
                                                <td>01-10-2020</td>
                                                <td>50.00</td>
                                                <td><span class="badge bg-success-light">Paid</span></td>
                                            </tr>
                                            <tr>
                                                <td>Enterprice</td>
                                                <td>28-05-2020</td>
                                                <td>28-05-2021</td>
                                                <td>1200.00</td>
                                                <td><span class="badge bg-success-light">Paid</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</diV>
</template>

<script>
    import Dashboard from './Dashboard.vue'
	export default {
        components: {
            Dashboard
        },
		mounted() {

 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });
		},
	}
</script>