<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <!-- <div class="modal-header">
                        <slot name="header">
                            
                        </slot>
                    </div> -->
                    <h6>Annuler l'expédition du colis</h6>

                    <div class="modal-body">
                    <slot name="body">
                            <h3 class="title">
                                {{colis.nom}}
                            </h3>
                            <div class="user-info">	
                                    <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                                        <span>{{colis.depart}}</span>
                                    </span>
                                    <span class="col ser-location">
                                        <span>{{colis.arrivee}}</span>
                                        <i class="fas fa-flag ms-1"></i>
                                    </span>
                            </div>
                            <br>
                            <div class="row">	
                                <span class="col-12 d-inline-block average-rating text-right">Publier le {{colis.date_publication}}</span>
                            </div>
                    </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <div v-if="chargement" class="half-circle-spinner" style="margin-left: auto;">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                            </div>
                            <div v-else class="modal-footer">
                                <button type="button" @click="desactiver(colis.id)" class="btn btn-success si_accept_confirm">Oui</button>
                                <button type="button" class="btn btn-danger si_accept_cancel" @click="$emit('close')">Non</button>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    
    <div>
        <Model v-if="showModal" @close="showModal = false" :text="text">
        </Model>
        <ModalError key="2" v-if="showModalError" @close="showModalError = false" :text="text">
        </ModalError>
    </div>
</template>

<script>
	import axios from 'axios'
    import ModalError from './modalError.vue'
    import Modal from './modal.vue';
export default {
    props: {
      colis: Object,
    },
    components: {
        ModalError,
        Modal
    },
    data () {
        return {
            data: '',
            chargement: false,
            showModalError: false,
            showModal: false,
            text: '',
        }
    },
    methods: {
        MaskCharacter(str, n) {
            if (str.length > n){
                return ('' + str).slice(0, n) + '...';
            } else {
                return str
            }
        },
        desactiver (id) {
            this.chargement = true
            axios.delete(`colis-desactiver/${id}`)
            .then((response) => {
                window.location.reload()
                this.chargement = false
            })
            .catch((error) => {
                this.text = error.response.data.message
                // $("[data-bs-dismiss=modal]").trigger({ type: "click" });
                this.showModalError = true
                this.chargement = false
            })
        }
    }
}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
    @import "./../scss/modal.scss";
</style>

<style>
.Btb {
    width: 100%;
    border: 1px solid rgb(0, 175, 245);
    background: rgb(0, 175, 245);
    border-radius: 20px;
	color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 5px;
}
</style>