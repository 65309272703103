<template>
	<div class="main-wrapper">
		<!-- <layout-header></layout-header> -->
        <div class="content">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10">
						<div class="section-header text-center">
							<h2>Politique de confidentialité de coliturage.com</h2>
						</div>
						
						<div class="row">
							<div class="col-12">
								<div class="terms-content">
									<div class="terms-text">
										<p>La présente Politique de confidentialité explique comment 2ASR CONSEILS, exploitant le site <b>coliturage.com</b> (le « Site »), collecte, utilise et protège les données personnelles des utilisateurs.</p>
									</div>
									<div class="terms-text">
										<h4>Données personnelles collectées</h4>
										<p>Nous collectons les données personnelles suivantes lorsque vous utilisez le Site :</p>

										<p>
											<ul>
												<li>●	Les informations que vous nous fournissez lors de l'inscription, telles que votre nom, votre adresse e-mail et votre numéro de téléphone.</li>
												<li>●	Les informations relatives aux colis que vous souhaitez transporter ou faire transporter, telles que le lieu de départ et le lieu de destination.</li>
												<li>●	Les informations que vous nous fournissez lorsque vous nous contactez ou que vous utilisez notre service d'assistance client.</li>
											</ul>
										</p>
									</div>
									<div class="terms-text">
										<h4>Utilisation des données personnelles</h4>
										<p>Nous utilisons les données personnelles collectées pour les finalités suivantes :</p>
										<p>
											<ul>
												<li>●	Pour fournir les services proposés sur le Site, notamment pour faciliter les transactions de transport de colis entre particuliers.</li>
												<li>●	Pour améliorer le Site et nos services, en analysant l'utilisation du Site et en recueillant des commentaires des utilisateurs.</li>
												<li>●	Pour communiquer avec vous, notamment pour vous envoyer des informations sur les transactions en cours, les mises à jour du Site ou les offres spéciales.</li>
												<li>●	Pour respecter les lois et réglementations applicables.</li>
											</ul>
										</p>
									</div>
									<div class="terms-text">
										<h4>Partage des données personnelles</h4> 
										<p>Nous ne partageons pas les données personnelles des utilisateurs avec des tiers, sauf dans les cas suivants :</p>
										<p>
											<ul>
												<li>●	Pour faciliter les transactions de transport de colis entre particuliers, nous pouvons partager les informations relatives à un transporteur avec un expéditeur et vice versa.</li>
												<li>●	Pour se conformer aux lois et réglementations applicables, nous pouvons être amenés à divulguer des données personnelles à des autorités gouvernementales ou à des organismes de réglementation.</li>
											</ul>
										</p>
									</div>
									<div class="terms-text">
										<h4>Sécurité des données personnelles</h4>

										<p>Nous prenons des mesures de sécurité raisonnables pour protéger les données personnelles des utilisateurs contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation, la modification ou la destruction. Cependant, aucune méthode de transmission de données sur Internet ou de stockage électronique n'est totalement sûre.</p>

									</div>	
									<div class="terms-text">
										<h4>Conservation des données personnelles</h4>

										<p>Nous conservons les données personnelles des utilisateurs aussi longtemps que nécessaire pour fournir les services proposés sur le Site, sauf si la loi applicable exige ou autorise une période de conservation plus longue.</p>
									</div>	
									<div class="terms-text">
										<h4>Droits des utilisateurs</h4>

										<p>Vous avez le droit de demander l'accès, la rectification, la suppression ou la limitation de l'utilisation de vos données personnelles. Vous avez également le droit de vous opposer à l'utilisation de vos données personnelles à des fins de marketing direct. Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante : info@coliturage.com.</p>

									</div>	
									<div class="terms-text">
										<h4>Modifications de la politique de confidentialité</h4>

										<p>Nous nous réservons le droit de modifier cette Politique de confidentialité à tout moment. Toute modification prendra effet immédiatement après la publication sur le Site. Nous encourageons les utilisateurs à vérifier régulièrement cette Politique de confidentialité pour prendre connaissance des modifications éventuelles.</p>

									</div>	
									<div class="terms-text">
										<p>En utilisant le Site, vous acceptez cette Politique de confidentialité. Si vous n'acceptez pas cette Politique de confidentialité, veuillez ne pas utiliser le Site.</p>
									</div>	
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {

			document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			 // Membership Add More

 $(".membership-info").on('click','.trash', function () {
    	$(this).closest('.membership-cont').remove();
    	return false;
    });

    $(".add-membership").on('click', function () {
    	var membershipcontent = '<div class="row form-row membership-cont">' +
    	'<div class="col-12 col-md-10 col-lg-6">' +
    	'<div class="form-group">' +
    	'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
    	'</div>' +
    	'</div>' +
    	'<div class="col-12 col-md-2 col-lg-2">' +
    	'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
    	'</div>' +
    	'</div>';
    	$(".membership-info").append(membershipcontent);
    	return false;
    });

		},
	}
</script>