<template>
    <div class="wrapper-stepper">
        <div class="stepper">
            <div class="stepper-progress">
                <div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
            </div>

            <div class="stepper-item" v-for="item in 4" :key="item" :class="{ 'current': step == item, 'success': step > item }">
                <div class="stepper-item-counter">
                    <img class="icon-success" src="./../assets/img/helpers/icons8-ok-144.svg" alt="">
                    <span class="number">
                        {{ item }}
                    </span>
                </div>
                <span class="stepper-item-title">
                    paso {{ item }}
                </span>
            </div>
        </div>
        <div class="stepper-content" v-for="item in 4" :key="item"> 
            <div class="stepper-pane" v-if="step == item">
                Esta en el paso <span class="tx-green-1">{{ item }}</span> aqui
                mostracias el contenido :D
            </div>
        </div>

        <div class="controls">
            <button class="btn2" @click="step--" :disabled="step == 1">
                Anterior
            </button>
            <button class="btn2 btn2--green-1" @click="step++" :disabled="step == 4">
                Siguiente
            </button>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        step: 1
    }),
    computed: {
        stepperProgress () {
             return ( 100 / 3) * (this.step - 1) + '%'
        }
    }
}
</script>
<style lang="scss">
    @import "./scss/stepper_component.scss";
</style>