<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4 theiaStickySidebar">
                      <Dashboard />
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <div class="row align-items-center mb-4">
                            <h4 class="widget-title  text-end">Propositions de transport pour mes colis</h4>
                        </div>
                        <div id="dataList">
                            <div v-for="trajet in trajets" :key="trajet.id" class="">
								<CardBookingS :booking="trajet"></CardBookingS>
							</div>
							<div v-if="trajets.length == 0">
								<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
							</div>
                            <div v-if="trajets.length >= 29" class="pagination">
                                <button :disabled="spinner" class="btn btn-primary" style="font-size: 16px; padding: 3px" @click="afficherTout()">
                                    Tout Afficher
                                </button>
                                <div v-if="spinner" class="half-circle-spinner" style="margin-left: 15%;">
                                    <div class="circle circle-1"></div>
                                    <div class="circle circle-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>﻿
	</div>
</template>

<script>
	import axios from 'axios'
    import Dashboard from './Dashboard.vue'
  	import CardBookingS from './CardBookingS.vue'
	export default {
        components: {
            Dashboard,
			CardBookingS
        },
		data () {
			return {
				nom: this.$route.params.colis,
                trajets: [],
                spinner: false,
			}
		},
        created () {
            window.scrollTo(0, 0);
            this.getTrajet()
        },
		mounted() {

 // Membership Add More

            $(".membership-info").on('click','.trash', function () {
                $(this).closest('.membership-cont').remove();
                return false;
            });

            $(".add-membership").on('click', function () {
                var membershipcontent = '<div class="row form-row membership-cont">' +
                '<div class="col-12 col-md-10 col-lg-6">' +
                '<div class="form-group">' +
                '<input type="text" class="form-control" name="service_offered[]" id="field1">' +
                '</div>' +
                '</div>' +
                '<div class="col-12 col-md-2 col-lg-2">' +
                '<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
                '</div>' +
                '</div>';
                $(".membership-info").append(membershipcontent);
                return false;
            });
        },
        methods: {
            getTrajet: function () {
                axios.get('colis-trajet-all')
                .then((response) => {
                    this.trajets = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            afficherTout() {
                this.spinner = true
                axios.get('colis-trajet-tout')
                .then((response) => {
                    this.trajets = response.data
                    this.spinner = false
                })
                .catch((error) => {
                    this.spinner = false
                })
            }
        }
	}
</script>

<style lang="scss">
    @import "./../scss/stepper_component.scss";
	@import "./../scss/loading.scss";
    @import "./../scss/select_card.scss";
</style>