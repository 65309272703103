// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import VueGoogleApi from 'vue-google-api'
import VueGapi from 'vue-gapi'
import { router } from './router';
import App from "./App.vue";
import LayoutHeader from './components/layout/Header'
import LayoutHeaderservice from './components/layout/Headerservice'
import LayoutHeaderadmin from './components/layout/Headeradmin'
import LayoutHeaderadmin1 from './components/layout/Headeradmin1'
import LayoutHeaderbookings from './components/layout/Headerbookings'
import LayoutFooter from './components/layout/Footer'
import LayoutNavadmin from './components/layout/Navadmin'
import loader from './components/loader'
import './assets/css/bootstrap/css/bootstrap.min.css'
import './assets/css/bootstrap/js/bootstrap.bundle.min.js'
import './assets/fontawesome/css/fontawesome.min.css'
import './assets/fontawesome/css/all.min.css'
import jquery from 'jquery'; 
import './axios'

import './index.css'

window.$ = jquery
if(window.location.href.includes("template/admin")) {
  require('./assets/css/select2.min.css');
  require('./assets/css/select2.min.js');
  require('./assets/css/bootstrap-tagsinput/css/bootstrap-tagsinput.css')
  require('./assets/css/bootstrap-tagsinput/js/bootstrap-tagsinput.js')
  require('./assets/css/bootstrap-datetimepicker.min.css');
  require('./assets/css/bootstrap-datetimepicker.min.js');
  require('./assets/css/slick.js');
  require('./assets/css/admin.css');
  require('./assets/css/bootstrap4.min.css')
  require('./assets/css/dataTables.bootstrap4.min.css')
  require('./assets/css/jquery.dataTables.min.js')
  require('./assets/css/dataTables.bootstrap4.min.js')
}else {
  require('./assets/css/select2.min.css');
  require('./assets/css/select2.min.js');
  require('./assets/css/bootstrap-datetimepicker.min.css');
  require('./assets/css/bootstrap-datetimepicker.min.js');
  require('./assets/css/slick.js');
  require('./assets/css/style.css');
  require('./assets/css/bootstrap4.min.css')
  require('./assets/css/dataTables.bootstrap4.min.css')
  require('./assets/css/jquery.dataTables.min.js')
  require('./assets/css/dataTables.bootstrap4.min.js')
}
/* const config = {
  apiKey: 'AIzaSyARKSMqpCztUoVgeqGOnDSy_lEQ2K7MQu8',
  clientId: 'madeinlas.apps.googleusercontent.com',
  scope: 'places',
  discoveryDocs: [ 'https://maps.googleapis.com/maps/api/js' ]
} */
const app = createApp(App)
app.component('layout-header', LayoutHeader);
app.component('layout-headerservice', LayoutHeaderservice);
app.component('layout-headeradmin', LayoutHeaderadmin);
app.component('layout-headeradmin1', LayoutHeaderadmin1);
app.component('layout-headerbookings', LayoutHeaderbookings);
app.component('layout-footer', LayoutFooter);
app.component('layout-navadmin', LayoutNavadmin);
app.component('Loader', loader);
app.use(router)
// app.use(VueGapi, config)
.mount('#app');
