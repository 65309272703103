<template>
		<div class="main-wrapper">
			<layout-headeradmin1></layout-headeradmin1>
            <layout-navadmin></layout-navadmin>
        <div class="page-wrapper">
            <div class="content container-fluid">
                <div class="row">
                    <div class="col-lg-8 m-auto">
                        <!-- Page Header -->
                        <div class="page-header">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="page-title">Home Page</h3>
                                </div>
                            </div>
                        </div>
                        <!-- /Page Header -->
                        
                        <div class="row">
                            <div class=" col-lg-12 col-sm-12 col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-heads">
                                            <h4 class="card-title">Banner Settings</h4>
                                            <div class="col-auto">
                                                <div class="status-toggle">
                                                    <input id="banner" class="check" type="checkbox" checked="">
                                                    <label for="banner" class="checktoggle">checkbox</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <p class="settings-label">Banner image</p>
                                                <div class="settings-btn">
                                                    <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                    <label for="file" class="upload">
                                                        <i class="fa fa-upload"></i>
                                                    </label>
                                                </div>
                                                <div class="upload-images d-block">
                                                    <img src="../../../assets/admin_img/services/service-13.png" alt="Image">
                                                    <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                    <h6>banner-2.jpg</h6>
                                                    <h5>43 KB</h5>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-5 col-12">
                                                        <div class="card-heads mb-3">
                                                            <h4 class="card-title f-14">Main Seacrch </h4>
                                                            <div class="col-auto">
                                                                <div class="status-toggle">
                                                                    <input id="default_o" class="check" type="checkbox" checked="">
                                                                    <label for="default_o" class="checktoggle">checkbox</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-heads mb-3">
                                                            <h4 class="card-title f-14">Popular Searches </h4>
                                                            <div class="col-auto">
                                                                <div class="status-toggle">
                                                                    <input id="default" class="check" type="checkbox" checked="">
                                                                    <label for="default" class="checktoggle">checkbox</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Popular Searches Label Name</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-heads">
                                            <h4 class="card-title">Featured Categories</h4>
                                            <div class="col-auto">
                                                <div class="status-toggle">
                                                    <input id="featured" class="check" type="checkbox" checked="">
                                                    <label for="featured" class="checktoggle">checkbox</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label class="form-head mb-0">Categories<span>( Max 6 only )</span></label>
                                            </div>
                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-11 col-12">
                                                        <select class="form-control select">
                                                            <option>Construction</option>
                                                            <option>Car Wash</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-1 col-12">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-11 col-12">
                                                        <select class="form-control select">
                                                            <option>Car Wash</option>
                                                            <option>Electrical</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-1 col-12">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-11 col-12">
                                                        <select class="form-control select">
                                                            <option>Electrical</option>
                                                            <option>Electrical</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-1 col-12">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-11 col-12">
                                                        <select class="form-control select">
                                                            <option>Cleaning</option>
                                                            <option>Electrical</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-1 col-12">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-11 col-12">
                                                        <select class="form-control select">
                                                            <option>Interior</option>
                                                            <option>Electrical</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-1 col-12">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-11 col-12">
                                                        <select class="form-control select">
                                                            <option>Select Category</option>
                                                            <option>Electrical</option>
                                                            <option>Interior</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-1 col-12">
                                                        <a href="javascript:void(0);" class="btn btn-sm bg-danger-light  delete_review_comment">
                                                            <i class="far fa-trash-alt "></i> 
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-3 col-12">
                                                        <a class="btn  btn-success"><i class="fa fa-plus me-2"></i>Add New</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-heads">
                                            <h4 class="card-title">Popular Services</h4>
                                            <div class="col-auto">
                                                <div class="status-toggle">
                                                    <input id="popular" class="check" type="checkbox" checked="">
                                                    <label for="popular" class="checktoggle">checkbox</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label class="form-head mb-0">Number of service<span>( Min 6 to Max 20 only )</span></label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-heads">
                                            <h4 class="card-title">How It Works</h4>
                                            <div class="col-auto">
                                                <div class="status-toggle">
                                                    <input id="works" class="check" type="checkbox" checked="">
                                                    <label for="works" class="checktoggle">checkbox</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            
                                            <div class="form-group">
                                                <h6 class="form-heads mb-0">Step 1</h6>
                                            </div>
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <p class="settings-label">Image</p>
                                                <div class="settings-btn">
                                                    <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                    <label for="file" class="upload">
                                                        <i class="fa fa-upload"></i>
                                                    </label>
                                                </div>
                                                <div class="upload-images ">
                                                    <img src="../../../assets/admin_img/break.png" class="my-4 w-auto" alt="Image">
                                                    <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <h6 class="form-heads mb-0">Step 2</h6>
                                            </div>
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <p class="settings-label">Image</p>
                                                <div class="settings-btn">
                                                    <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                    <label for="file" class="upload">
                                                        <i class="fa fa-upload"></i>
                                                    </label>
                                                </div>
                                                <div class="upload-images ">
                                                    <img src="../../../assets/admin_img/break.png" class="my-4 w-auto" alt="Image">
                                                    <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <h6 class="form-heads mb-0">Step 3</h6>
                                            </div>
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <label>Content</label>
                                                <input type="text" class="form-control" >
                                            </div>
                                            <div class="form-group">
                                                <p class="settings-label">Image</p>
                                                <div class="settings-btn">
                                                    <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                    <label for="file" class="upload">
                                                        <i class="fa fa-upload"></i>
                                                    </label>
                                                </div>
                                                <div class="upload-images ">
                                                    <img src="../../../assets/admin_img/break.png" class="my-4 w-auto" alt="Image">
                                                    <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="form-groupbtn">
                                                <a class="btn btn-update me-2">Update</a>
                                                <a class="btn btn-cancel">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-heads">
                                            <h4 class="card-title">Download Section</h4>
                                            <div class="col-auto">
                                                <div class="status-toggle">
                                                    <input id="download" class="check" type="checkbox" checked="">
                                                    <label for="download" class="checktoggle">checkbox</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Title</label>
                                            <input type="text" class="form-control" >
                                        </div>
                                        <div class="form-group">
                                            <label>Content</label>
                                            <input type="text" class="form-control" >
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-12">
                                                <div class="form-group">
                                                    <p class="settings-label">App Store (iOs)</p>
                                                    <div class="settings-btn">
                                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                        <label for="file" class="upload">
                                                            <i class="fa fa-upload"></i>
                                                        </label>
                                                    </div>
                                                    <div class="upload-images ">
                                                        <img src="../../../assets/admin_img/app.png" class="my-4 w-auto" alt="Image">
                                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12">
                                                <div class="form-group">
                                                    <label>App Link</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-12">
                                                <div class="form-group">
                                                    <p class="settings-label">Google Play Store</p>
                                                    <div class="settings-btn">
                                                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                                                        <label for="file" class="upload">
                                                            <i class="fa fa-upload"></i>
                                                        </label>
                                                    </div>
                                                    <div class="upload-images ">
                                                        <img src="../../../assets/admin_img/googlepay.png" class="my-4 w-auto" alt="Image">
                                                        <a href="javascript:void(0);" class="btn-icon logo-hide-btn">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12">
                                                <div class="form-group">
                                                    <label>App Link</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-groupbtn">
                                            <a class="btn btn-update me-2">Update</a>
                                            <a class="btn btn-cancel">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
		</diV>
</template>

<script>
	export default {
		mounted() {

        // radio btn hide show
      $(function() {
        $("input[name='mail_config']").click(function() {
          if ($("#chkYes").is(":checked")) {
            $("#showemail").show();
          } else {
            $("#showemail").hide();
          }
        });
      });
// Range slider
    if(document.getElementById("myRange")!=null){
        var slider = document.getElementById("myRange");
        var output = document.getElementById("currencys");
        output.innerHTML = slider.value;
      
        slider.oninput = function() {
          output.innerHTML = this.value;
        }
    }
    if(document.getElementById("myRange")!=null){
        document.getElementById("myRange").oninput = function() {
            var value = (this.value-this.min)/(this.max-this.min)*100
            this.style.background = 'linear-gradient(to right, rgb(0, 175, 245) 0%, rgb(0, 175, 245) ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'
          };
        }
        
    // Logo Hide Btn

    $(document).on("click",".logo-hide-btn",function () {
        $(this).parent().hide();
    });
// add Formset
$(document).on("click",".addlinks",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-form").append(experiencecontent);
    return false;
});

$(".settings-form").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add Formset
                                                
$(document).on("click",".addnew",function () {
    var experiencecontent = '<div class="form-group links-conts">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settingset").append(experiencecontent);
    return false;
});

$(".settingset").on('click','.delete_review_comment', function () {
    $(this).closest('.links-conts').remove();
    return false;
});
$(document).on("click",".addlinknew",function () {
    var experiencecontent = '<div class="form-group links-cont">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-3 col-12">' +
    '<input type="text" class="form-control" placeholder="Label">' +
    '</div>' +
    '<div class="col-lg-8 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div>' +
    '</div>' ;
    
    $(".settings-forms").append(experiencecontent);
    return false;
});

$(".settings-forms").on('click','.delete_review_comment', function () {
    $(this).closest('.links-cont').remove();
    return false;
});
// add social links Formset
$(document).on("click",".addsocail",function () {
    var experiencecontent = '<div class="form-group countset">' +
    '<div class="row align-items-center">' +
    '<div class="col-lg-2 col-12">' +
    '<div class="socail-links-set">' +
    '<ul>' +
    '<li class=" dropdown has-arrow main-drop">' +
    '<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">' +
    '<span class="user-img">' +
    '<i class="fab fa-github me-2"></i>' +
    '</span>' +
    '</a>' +
    '<div class="dropdown-menu">' +
    '<a class="dropdown-item" href="#"><i class="fab fa-facebook-f me-2"></i>Facebook</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-twitter me-2"></i>twitter</a>' +
    '<a class="dropdown-item" href="#"><i class="fab fa-youtube me-2"></i> Youtube</a>' +
    '</div>' +
    '</li>' +
    '</ul>' +
    '</div>' +
    '</div>' +
    '<div class="col-lg-9 col-12">' +
    '<input type="text" class="form-control" placeholder="Link with http:// Or https://">' +
    '</div>' +
    '<div class="col-lg-1 col-12">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ' +
    '</div> ';
    
    $(".setings").append(experiencecontent);
    return false;
});

$(".setings").on('click','.delete_review_comment', function () {
    $(this).closest('.countset').remove();
    return false;
});
// add Faq
                                                
$(document).on("click",".addfaq",function () {
    var experiencecontent = '<div class="row counts-list">' +
    '<div class="col-md-11">' +
    '<div class="cards">' +
    '<div class="form-group">' +
    '<label>Title</label>' +
    '<input type="text" class="form-control" >' +
    '</div>' +
    '<div class="form-group mb-0">' +
    '<label>Content</label>' +
    '<textarea class="form-control"></textarea>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="col-md-1">' +
    '<a href="#" class="btn btn-sm bg-danger-light  delete_review_comment">' +
    '<i class="far fa-trash-alt "></i> ' +
    '</a>' +
    '</div>' +
    '</div> ';
    
    $(".faq").append(experiencecontent);
    return false;
});

$(".faq").on('click','.delete_review_comment', function () {
    $(this).closest('.counts-list').remove();
    return false;
});

		},
	}
</script>