<template>
	<div class="main-wrapper">
		<!-- <layout-headerservice></layout-headerservice> -->
        <div class="content">
			<div class="container">
				<div class="row">
					<div class="col-xl-3 col-md-4 theiaStickySidebar">
                        <Dashboard />
					</div>
					<div class="col-xl-9 col-md-8">
						<h4 class="widget-title  text-end">Mes Trajets</h4>
						<NavTrajet :actif="tab" />
						<div class="container">
							<div class="row">
								
								<div v-for="trajet in trajets" :key="trajet.id" class="col-lg-4 col-md-6">
									<CardTrajet :trajet="trajet"></CardTrajet>
								</div>
								<div v-if="trajets.length == 0">
									<img src="../../assets/img/nothing-found.png" class="nothing" alt="">
								</div>
							
								<!-- Pagination Links --> 
								<div v-if="trajets.length >= 30" class="pagination">
									<ul>
										<li v-if="firstPageUrl" :class=" firstPageUrl ?'arrow active' : 'arrow'" @click="getTrajet(this.firstPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-left"></i></a></li>
										<li v-if="prevPageUrl" :class=" prevPageUrl ?'arrow active' : 'arrow'" @click="getTrajet(this.prevPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-left"></i></a></li>
										<li class="active"><a href="javascript:void(0);">{{page}}</a></li>
										<li v-if="nextPageUrl" :class=" nextPageUrl ?'arrow active' : 'arrow'" @click="getTrajet(this.nextPageUrl)"><a href="javascript:void(0);"><i class="fas fa-angle-right"></i></a></li>
										<li v-if="lastPageUrl" :class=" lastPageUrl ?'arrow active' : 'arrow'" @click="getTrajet(this.lastPageUrl)"><a href="javascript:void(0);"><i class="fas fa-arrow-right"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
    import Dashboard from './../provider/Dashboard.vue'
  	import CardTrajet from './CardTrajet.vue'
	import NavTrajet from './NavTrajet.vue'
	export default {
        components: {
		Dashboard,
		CardTrajet,
		NavTrajet
	},
		data () {
			return {
				trajets: [],
				tab: 'tab1',
				itemsPerPage: null,
				page: null,
				numberOfPages: null,
				firstPageUrl: null,
				lastPageUrl: null,
				from: null,
				to: null,
				total: null,
				nextPageUrl: null,
				prevPageUrl: null,
				path: 'my-trajet/actif',
			}
		},
		mounted() {
			$(".membership-info").on('click','.trash', function () {
				$(this).closest('.membership-cont').remove();
				return false;
			});

			$(".add-membership").on('click', function () {
				var membershipcontent = '<div class="row form-row membership-cont">' +
				'<div class="col-12 col-md-10 col-lg-6">' +
				'<div class="form-group">' +
				'<input type="text" class="form-control" name="service_offered[]" id="field1">' +
				'</div>' +
				'</div>' +
				'<div class="col-12 col-md-2 col-lg-2">' +
				'<a href="#" class="btn btn-danger trash"><i class="far fa-times-circle"></i></a>' +
				'</div>' +
				'</div>';
				$(".membership-info").append(membershipcontent);
				return false;
			});
		},
		created () {
			if (localStorage.getItem('coliturageToken') === '') {
				this.$router.push('/')
			}
			this.getTrajet(this.path)
		},
		methods: {
			getTrajet: function (path) {
				axios.get(path)
				.then((response) => {
					this.trajets = response.data.data
					this.itemsPerPage = response.data.per_page
					this.page = response.data.current_page
					this.numberOfPages = response.data.last_page
					this.firstPageUrl = response.data.first_page_url
					this.lastPageUrl = response.data.last_page_url
					this.from = response.data.from
					this.to = response.data.to
					this.total = response.data.total
					this.nextPageUrl = response.data.next_page_url
					this.prevPageUrl = response.data.prev_page_url
				})
				.catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>