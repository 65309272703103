<template>
    <router-link to="/search-colis">
        <div class="cate-widget">
            <img src="../../assets/img/category/category-01.jpg" alt="">
            <div class="cate-title">
                <h3><span><i class="fas fa-circle"></i> {{categorie.nom}}</span></h3>
            </div>
            <div class="cate-count">
                <i class="fas fa-clone"></i> 21
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      categorie: Object,
    },
}
</script>

<style>

</style>