<template>
    <div class="service-widget animate__animated animate__fadeIn">
        <div class="service-img">
            <router-link @click.native="reload()" to="#">
              <div style="height: 220px; text-align: center; padding-top: auto;">
                <span style="display: inline-block; height: 100%;vertical-align: middle;"></span><img class="img-fluid" alt="Service Image" :src="colis.photo2" style="vertical-align: middle;">
              </div>
            </router-link>
            <!-- <div class="fav-btn">
                <a href="javascript:void(0)" class="fav-icon">
                    <i class="fas fa-heart"></i>
                </a>
            </div> -->
            <div class="item-info">
                <div class="service-user">
                    <!-- <a href="javascript:void(0);">
                        <img src="../../assets/img/customer/user-01.jpg" alt="">
                    </a>	 -->
                    <span class="service-price ms-1">€ {{colis.prix}}</span>
                </div>
                <div class="cate-list">
                    <router-link class="bg-yellow" to="#">{{colis.taille}}</router-link>
                </div>
            </div>
        </div>
        <div class="service-content">
            <h3 class="title">
                <router-link @click.native="reload()" to="#">{{MaskCharacter(colis.nom, 25)}}</router-link>
            </h3>
            <div class="row d-xs-none">	
                <span class="col-6 d-inline-block average-rating"></span>
            </div>
            <div class="user-info">
                <div class="">	
                    <span class="col-auto ser-contact"><i class="fas fa-location-arrow me-1"></i> 
                        <span style="font-size: 14px">{{MaskCharacter(colis.depart, 25)}}</span>
                    </span>
                    <span class="col ser-location">
                        <span style="font-size: 14px">{{MaskCharacter(colis.arrivee, 25)}}</span>
                        <i class="fas fa-flag ms-1"></i>
                    </span>
                </div>
            </div>
            <div class="row mt-1 d-xs-none">	
                <span class="col-12 d-inline-block average-rating text-right  d-xs-none" style="font-size: 12px">Publié le <span class="text-info">{{colis.date_publication}}</span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      colis: Object,
    },
    methods: {
        MaskCharacter(str, n) {
            // Slice the string and replace with
            // mask then add remaining string
            if (str.length > n){
                return ('' + str).slice(0, n) + '...';
            } else {
                return str
            }
        },
        reload () {
            if (this.$route.name === 'service-details') {
                sessionStorage.setItem('urltransition', '/service-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id)
                this.$router.push('/transition')
            } else {
                this.$router.push('/service-details/' + this.colis.id + '/' + this.colis.nom + '/' + this.colis.user_id)
            }
        }
    }
}
</script>

<style lang="scss">
.colis-card {
    @media (min-width: 0px) and (max-width: 575px){
      width: 45%;
      height: 150PX;
      margin: auto;
    }

    /* @media (min-width: 376px) and (max-width: 425px){
      width: 400PX;
    }

    @media (min-width: 426px) and (max-width: 768px){
      width: 500PX;
    }

    @media (min-width: 769px) and (max-width: 1024px){
      width: 800PX;
    } */
  }
  .d-xs-none {
    @media (min-width: 0px) and (max-width: 575px){
      display: none !important;
    }
  }
  .noth {
    height: 70PX;
     @media (min-width: 0px) and (max-width: 575px){
      height: 70PX;
    }

    /*@media (min-width: 376px) and (max-width: 425px){
      width: 400PX;
    }

    @media (min-width: 426px) and (max-width: 768px){
      width: 500PX;
    } */

    @media (min-width: 426px){
      width:100%;
      height:220px;
    }
  }
.containerC {
  height: 200px;
  position: relative;
}

.centerC {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>